import { Box, Button } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { ListItemContent } from "./ListItem";
import { useListItemStyles } from "./listItemStyles";
import { Fake } from "../skeleton/Fake";
import classnames from "classnames";
import { JerseyAvatar } from "../heat/JerseyAvatar";
import { AvatarOrInitials } from "../avatar/AvatarOrInitials";

const useStyles = makeStyles((theme, { isSelected, disabled }) => ({
    button: {
        opacity: disabled && !isSelected && 0.6,
        outline: isSelected && `2px solid ${theme.palette.border.selected}`,
        borderRadius: isSelected && 10,
        pointerEvents: disabled && "none",
        justifyContent: "start",
        display: "block",
        padding: 0,
        width: "100%",
        "&:hover": {
            backgroundColor: theme.palette.background.low,
        },
        "&:focus-visible": {
            outline: `3px solid ${theme.palette.border.focus}`,
            borderRadius: 5,
            outlineOffset: 2,
            backgroundColor: theme.palette.background.paper,
        },
        "&.Mui-selected": {
            outline: `3px solid ${theme.palette.border.focus}`,
            borderRadius: 5,
            outlineOffset: 2,
            backgroundColor: theme.palette.background.paper,
        }
    },
    content: {
        alignItems: "center",
        textAlign: "left",
    }
}));

export const ListItemSelectable = ({ title, subtitle, description, content, aside, icon, isSelected, onSelect, hasError, disabled }) => {
    const classes = { ...useListItemStyles({ hasError }), ...useStyles({ isSelected, disabled }) };

    return (<Button tabIndex={0} className={classes.button} onClick={onSelect} aria-pressed={isSelected}>
        <ListItemContent title={title} subtitle={subtitle} description={description} content={content} aside={aside} icon={icon} classes={classes} />
    </Button>);
};

export const ListItemSelectableWithIcon = ({ icon, ...props }) => <ListItemSelectable {...props} icon={<Box component={AvatarOrInitials} size="xl" backgroundColor="secondary.light" color="secondary.contrast">{icon}</Box>} />;

export const ListItemSelectableSkeleton = () => {
    const classes = { ...useListItemStyles(), ...useStyles() };

    return (
        <Button tabIndex={0} className={classnames(classes.button, classes.content, "skeleton")} role="status" aria-label="loading">
            <ListItemContent title={<Fake size={50} />} subtitle={<Fake size={30} />} description={<Fake size={90} />} icon={<JerseyAvatar />} classes={classes} />
        </Button>);
};
