import { Stack, Typography } from "@mui/material";
import { EmojiEvents, EventAvailable, ListAlt, LocalActivitySharp, Stop } from "@mui/icons-material";
import { LiveIndicator } from "components/layout/organisation/LiveIndicator";
import { EVENT_STATUSES } from "../../layout/event/EVENT_STATUSES";

const STATUS_MAPS = {
    upcoming: { textColour: "status.upcomingText", iconColour: "status.upcomingText", icon: <LocalActivitySharp fontSize="sm"/> },
    published: { textColour: "status.upcomingText", iconColour: "status.upcomingText", icon: <ListAlt fontSize="sm"/> },
    on: { textColour: "status.onText", iconColour: "status.livePulse", icon: <LiveIndicator /> },
    on_hold: { textColour: "error.dark", iconColour: "error.dark", icon: <Stop fontSize="sm"/> },
    finished: { textColour: "text.secondary", iconColour: "text.secondary", icon: <EventAvailable fontSize="sm"/> },
    results_published: { textColour: "text.primary", iconColour: "text.primary", icon: <EmojiEvents fontSize="sm"/> },
};
export const EventStatus = ({ status }) => {
    const statusMaps = STATUS_MAPS[status] || {};

    return (
        <Stack direction="row" spacing={.5} alignItems="center">
            <Typography variant="label2" color={statusMaps.iconColour} display="flex">{statusMaps.icon}</Typography>
            <Typography variant="label2" color={statusMaps.textColour} textTransform="uppercase">{EVENT_STATUSES[status]}</Typography>
        </Stack>
    );
};
