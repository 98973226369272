import { Stack } from "@mui/material";
import { FakeFormField } from "components/layout/forms/FakeFormField";
import { MaxWidthPageWithHeading } from "components/layout/page/MaxWidthPageWithHeading";
import { Fake } from "components/layout/skeleton/Fake";

export const EditEventPageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <MaxWidthPageWithHeading title={<Fake size={35}/>} align="left">
            <Stack spacing={2} marginTop={4} direction="row">
                <Fake size={30}/>
                <Fake size={30}/>
                <Fake size={30}/>
                <Fake size={30}/>
                <Fake size={30}/>
                <Fake size={30}/>
            </Stack>
            <Stack marginTop={4}>
                <FakeFormField label={30} field={260}/>
                <FakeFormField label={30} field={260}/>
                <FakeFormField label={30} field={260}/>
                <Stack direction="row" spacing={5} marginBottom={5}>
                    <FakeFormField label={30} field={128}/>
                    <FakeFormField label={30} field={128}/>
                </Stack>
                <FakeFormField label={30} field={260}/>
                <FakeFormField label={30} field={260}/>
            </Stack>
        </MaxWidthPageWithHeading>
    </div>
);
