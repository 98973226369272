import { Component } from "react";
import { connect } from "react-redux";
import { Tabs, Tab } from "@mui/material";

import { DnDEditHeat } from "../heats/editHeat";
import { Heading } from "../../layout/page/Heading";
import { T } from "../../util/t";
import { JudgeCardTableSkeleton } from "./judgeCard";
import { currentHeatsSelector, eventSelector, heatsLoadedSelector, schedulePositionSelector } from "../selectors";
import { Icon } from "../../../styleguide/icons/Icon";
import { EditHeatToolbar } from "./EditHeatToolbar";
import { ShowPrinting, HidePrinting } from "../../util/printing";
import { eventDate } from "../eventDate";
import { HeaderLogo } from "../../layout/print/HeaderLogo";
import { Meta } from "components/util/Meta";
import * as EventActions from "actions/event";

export const EventHeadJudge = connect((state, props) => ({
    event: eventSelector(state, props),
    heats: currentHeatsSelector(state, props),
    heatsLoaded: heatsLoadedSelector(state, props) && heatsLoadedSelector(state, props)
        .map(heatId => state.events.getIn(["heats", props.match.params.id, heatId]))
        .filter(h => h)
        .every(h => h.get("result")),
    schedulePosition: schedulePositionSelector(state, props)

}))(
    class EventHeadJudge extends Component {
        state = {
            selectedTab: 0
        };

        componentDidMount = () => {
            this.props.dispatch(EventActions.getCurrentHeats(this.props.match.params.id));
        };

        changeTab = (e, tab) => this.setState({ selectedTab: tab });

        render = () => {
            const { event, heats, heatsLoaded, schedulePosition, match: { params: { id } } } = this.props,
                { selectedTab } = this.state,
                filteredHeats = heats.filter(h => h),
                heat = filteredHeats.size > 1 ? heats.get(selectedTab) : filteredHeats.first();

            const isFinished = schedulePosition !== 0 && heats.isEmpty();
            const isUpcoming = schedulePosition === 0 && heats.isEmpty();
            const showEmptyState = !event.isEmpty() && heatsLoaded && (isFinished || isUpcoming);

            return (
                <div className="edit-heats">
                    <Meta title={event.get("name")
                        ? "head_judge_meta_title"
                        : "Head judge | Liveheats"}
                          translationProps={{ eventName: event.get("name") }}
                    />
                    <ShowPrinting>
                        <HeaderLogo/>
                        {event.getIn(["organisation", "name"])} / {event.get("name")} / {eventDate({ date: event.get("date"), window: event.get("days_window") })}
                        <Heading title={heat ? <T heat={heat}>HeadJudgePage_title</T> : <T>HeatHeader_empty_title</T>} />
                    </ShowPrinting>

                    <HidePrinting>
                        <Heading
                            title="Head judge"
                            borderless={showEmptyState}
                            actions={<EditHeatToolbar eventId={id} heat={heat}/>}
                        />
                    </HidePrinting>

                    {(!event.isEmpty() && heatsLoaded) ?
                        <>
                            {filteredHeats.size > 1  &&
                            <Tabs value={selectedTab} onChange={this.changeTab}
                                  variant="fullWidth"
                                  classes={{ root: "tabs-root" }}>
                                {heats.map((heat, i) =>
                                    <Tab key={i}
                                         classes={{ root: "tab-root", wrapper: "tab-wrapper" }}
                                         label={<T selectedHeat={i} heat={heat}>JudgeSheet_heatTitle</T>}/>
                                )}
                            </Tabs>}

                            {heat && <DnDEditHeat
                                        heat={heat}
                                        hideNames={event.get("hide_names_from_judges")}
                                        eventId={id}/>}
                        </> :
                        <JudgeCardTableSkeleton isHeadJudge/>
                    }

                    {showEmptyState &&
                    <div className="empty-state margin-left-right">
                        <div className="main-icon"><Icon icon="HeadJudgeEmpty"/></div>
                        <p>{isFinished
                            ? <T>You nailed it! This event is now complete.</T>
                            : <T>Thanks for stopping by. There's no live heats running at the moment.</T>
                        }</p>
                    </div>}
                </div>
            );
        };
    });
