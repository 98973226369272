import { Typography } from "@mui/material";
import { Field } from "redux-form/immutable";

import { makeStyles } from "components/providers/makeStyles";
import { checkboxInput, creatableSelect, FormField, numberOrEmpty, plainInput, required } from "../../../forms";
import { maxRideScoreSelectOptions } from "../../../forms/options";
import { T } from "../../../util/t";

const useStyles = makeStyles({
    group: {
        ".formField": {
            marginBottom: 0
        }
    }
});

export const EventJudgingForm = () => {
    const classes = useStyles();

    return (
        <>
            <Field name="hide_names_from_judges" component={checkboxInput}
                   label={<T>Hide athlete names from judges</T>}/>

            <Field name="drop_scores" component={checkboxInput}
                   label={<T>Drop lowest and highest scores</T>}/>

            <Field name="requires_head_judge_approvals" component={checkboxInput}
                   label={<T>Head judge must approve scores</T>}/>

            <FormField className={classes.group}>
                <Field name="minimum_judges" type="number" component={plainInput}
                       label={<T>Number of judges on panel (optional):</T>}
                       validate={numberOrEmpty}/>
                <Typography variant="caption">
                    <T>minimum_judge_scores_explainer</T>
                </Typography>
            </FormField>

            <FormField className={classes.group}>
                <Field name="max_ride_score" component={creatableSelect}
                       label={<T>Maximum possible score per ride:</T>}
                       validate={required}
                       selectOptions={maxRideScoreSelectOptions}/>
                <Typography variant="caption">
                    Defines the highest possible score that an athlete can receive in a single ride, most commonly 10 or 100.
                </Typography>
            </FormField>
        </>
    );
};
