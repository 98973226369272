import classnames from "classnames";
import { ArrowBack } from "@mui/icons-material";
import { Link } from "react-router-dom";

import { T } from "../../util/t";
import { IconButton, Stack, Typography } from "@mui/material";
import { makeStyles } from "../../providers/makeStyles";

export const useHeadingStyles = makeStyles((theme, { borderless } : { borderless?: boolean }) => ({
    heading: {
        margin: theme.spacing(1.75, 0, 0),
        padding: theme.spacing(0, 2),
        borderBottom: borderless ? "none" : `1px solid ${theme.palette.border.light}`,
        position: "sticky",
        left: 0,
        maxWidth: "100vw",
        [theme.breakpoints.down("md")]: {
            width: "calc(100vw - (100vw - 100%))",
        },
        ".wrapper &": {
            margin: 0,
            padding: 0,
            borderBottom: 0,
            [theme.breakpoints.down("md")]: {
                width: "auto",
            },
        },
        "@media print": {
            padding: "0 !important",
            marginTop: "0 !important",
        },
    },
    headingNav:{
        marginBottom: theme.spacing(4),
    },
    headingMaxWidth: {
        "&&": {
            maxWidth: theme.shape.pageMaxWidth,
            marginLeft: "auto",
            marginRight: "auto",
        }
    },
    flexBox: {
        display: "flex",
        alignItems: "center"
    },
    headingTitle: {
        justifyContent: "space-between",
        flexWrap: "wrap",
        margin: theme.spacing(0, 0, 2),
    },
    titleArea: {
        gap: theme.spacing(1),
        margin: theme.spacing(1, 1, 0, 0),
    },
    icon: {
        verticalAlign: "middle",
        cursor: "pointer"
    },
    title: {
        gap: theme.spacing(1),
    },
    headingActions: {
        marginTop: theme.spacing(1),
        "@media print": {
            display: "none !important",
        },

        "& .MuiButton-root.more": {
            padding: theme.spacing(0, 1),
            minWidth: "50px",
            ".MuiSvgIcon-root": {
                margin: 0,
            },
        },
    },
}));

interface Props {
    title: React.ReactNode | string;
    subtitle?: React.ReactNode;
    returnTo?: string | (() => void);
    actions?: React.ReactNode;
    borderless?: boolean;
    maxWidth?: boolean;
    className?: string;
}

export const Heading: React.FCWithChildren<Props> = ({ title, subtitle, returnTo, actions, children, borderless, maxWidth, className }) => {
    const classes = useHeadingStyles({ borderless });

    return (
        <header className={classnames(classes.heading, { borderless }, className) }>
            <section className={classnames(classes.flexBox, classes.headingTitle, { [classes.headingMaxWidth]: maxWidth })}>
                <aside className={classnames(classes.flexBox, classes.titleArea)}>
                    {returnTo &&
                    <div className={classes.flexBox}>
                        {typeof returnTo === "string" ?
                            <IconButton component={Link} to={returnTo} size="small"><ArrowBack/></IconButton> :
                            <IconButton onClick={returnTo} size="small"><ArrowBack/></IconButton>
                        }
                    </div>}
                    <div>
                        <Typography variant="h4" component="h1" className={classnames(classes.flexBox, classes.title)}>
                            {typeof title === "string"
                                ? <T>{title}</T>
                                : title
                            }
                        </Typography>
                        {subtitle && <Typography>{subtitle}</Typography>}
                    </div>
                </aside>
                {actions && <Stack direction="row" spacing={1} className={classnames(classes.headingActions, "heading-actions")}>{actions}</Stack>}
            </section>

            {children}
        </header>
    );
};
