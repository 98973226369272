import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button, Divider, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { T } from "../../util/t";
import { useLogout } from "../../../hooks/useLogout";
import { makeStyles } from "../../providers/makeStyles";
import { AvatarOrInitials } from "../avatar/AvatarOrInitials";
import { ArrowOutward, ChevronRight } from "@mui/icons-material";
import { trackClick, trackingCategory } from "utils/tracking";

const useStyles = makeStyles(theme => ({
    login: {
        [theme.breakpoints.down("sm")]: {
            minWidth: theme.spacing(10)
        }
    }
}));
export const UserMenu = ({ currentUserLoaded, currentUser }) => {
    const [menu, setMenu] = useState();
    const openMenu = e => setMenu(e.currentTarget);
    const closeMenu = () => setMenu(null);
    const classes = useStyles();

    if (!currentUserLoaded) return null;
    if (!currentUser) return (
        <Button variant="contained" component={Link} to="/login" className={classes.login}>
            <T>Log in</T>
        </Button>
    );

    return (
        <>
            <UserMenuButton name={currentUser.name} openMenu={openMenu} menuIsOpen={!!menu}/>

            <Menu anchorEl={menu}
                  marginThreshold={4}
                  open={!!menu}
                  onClose={closeMenu}>
                <UserMenuContent user={currentUser} closeMenu={closeMenu}/>
            </Menu>
        </>
    );
};

const useButtonStyles = makeStyles((theme, { menuIsOpen }) => ({
    button: {
        width: theme.spacing(6.75),
        height: theme.spacing(4.75),
        borderRadius: theme.shape.borderRadius * 3,
        backgroundColor: menuIsOpen ? theme.palette.background.high : theme.palette.background.low,
        "&:hover": {
            backgroundColor: theme.palette.background.mid,
        },
        "&:active": {
            backgroundColor: theme.palette.background.high,
        },
        "&:focus-visible": {
            backgroundColor: theme.palette.background.low,
        }
    },
    avatar: {
        backgroundColor: theme.palette.background.dark,
        color: theme.palette.background.paper,
    },
    menuArrow: {
        transform: menuIsOpen ? "rotate(270deg)" : "rotate(90deg)",
        transition: theme.transitions.create("transform"),
    }
}));
export const UserMenuButton = ({ name, openMenu, menuIsOpen }) => {
    const classes = useButtonStyles({ menuIsOpen });

    return (
        <IconButton className={classes.button} size="small" onClick={openMenu}>
            <AvatarOrInitials alt={name} size="md" className={classes.avatar} />
            <ChevronRight className={classes.menuArrow} fontSize="small" />
        </IconButton>
    );
};

const getDisplayName = name => name.length > 30 ? `${name.substring(0, 27)}...` : name;

const useMenuItemStyles = makeStyles(theme => ({
    menuItemIcon: {
        verticalAlign: "middle",
        color: theme.palette.text.lighter,
    }
}));
export const UserMenuContent = ({ user, closeMenu }) => {
    const classes = useMenuItemStyles();
    const history = useHistory();
    const isTeamManager = user.permissions.some(p => p.record_type === "Team" && (p.scopes = ~/entries\/manage/));

    const logout = useLogout(() => {
        closeMenu();
        history.push("/");
    });

    return (
        <>
            <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}>Dashboards</Typography>

            {user.role === "director" &&
                <MenuItem onClick={closeMenu} component={Link} to="/director">
                    <T>Director</T>
                </MenuItem>}

            {(user.role === "judge" || user.role === "director") &&
                <MenuItem onClick={closeMenu} component={Link} to="/dashboard/judge">
                    <T>Judge</T>
                </MenuItem>}

            {isTeamManager &&
                <MenuItem onClick={closeMenu} component={Link} to="/user/team-manager">
                    <T>Team manager</T>
                </MenuItem>}

            {((!isTeamManager && user.role !== "director" && user.role !== "judge") || (user.athletes.length > 0)) &&
                <MenuItem onClick={closeMenu} component={Link} to="/user/athletes">
                    <T>Athlete</T>
                </MenuItem>}

            {user.role === "director" &&
                <>
                    <Divider />
                    <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}>{getDisplayName(user.organisation.name)}</Typography>

                    <MenuItem onClick={closeMenu} component={Link} to="/organisation/edit/profile">
                        <T>Organisation settings</T>
                    </MenuItem>

                    { user.organisation?.stripe_account_type === "standard" &&
                        <MenuItem onClick={closeMenu} component={"a"} href="https://dashboard.stripe.com/payments" target="_blank" rel="noopener noreferrer">
                            <Typography>
                                <T>Log in to Stripe</T> <ArrowOutward className={classes.menuItemIcon} />
                            </Typography>
                        </MenuItem>
                    }

                    <MenuItem onClick={closeMenu} component={Link} to="/director/plan">
                        <T>Plan and billing</T>
                    </MenuItem>
                </>
            }

            <Divider />

            <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}>{getDisplayName(user.name)}</Typography>

            <MenuItem onClick={closeMenu} component={Link} to="/user/settings">
                <T>Personal settings</T>
            </MenuItem>
            {user.role !== "director" && user.role !== "judge" && user.role !== "admin" && <>
                <Divider />
                <MenuItem onClick={trackClick("Organiser site link", trackingCategory.avatarMenu, closeMenu)} component='a' sx={{ flexDirection: "column", alignItems: "flex-start" }} href="https://organiser.liveheats.com/" target="_blank">
                    <Typography display='block'>
                        <T>Want to run your own events?</T>
                    </Typography>
                    <Typography display='block' variant="button" sx={{ textDecoration: "underline" }}>
                        <T>Liveheats for organisers</T><ArrowOutward className={classes.menuItemIcon} />
                    </Typography>
                </MenuItem>
                <Divider />
            </>}
            <MenuItem onClick={logout}>
                <T>Log out</T>
            </MenuItem>
        </>
    );
};
