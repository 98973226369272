import { useCallback, useState } from "react";
import { makeStyles } from "components/providers/makeStyles";

import { useOnScroll } from "../../../hooks/useOnScroll";
import { CloseButton } from "components/layout/heat/HeatModal";

export const useHeatBreakdownStyles = makeStyles((theme, { noMarginRight }) => ({
    container: {
        WebkitOverflowScrolling: "touch",
        overflowY: "auto",
        paddingBottom: theme.spacing(2)
    },
    header: {
        margin: theme.spacing(2, 3, 0, 3)
    },
    mainContent: {
        margin: theme.spacing(2, 1),
        marginRight: noMarginRight && 0
    }
}));

export const HeatLeaderboardBreakdown = ({ heat, compactHeader, fullHeader, closeModal, selector, children, noMarginRight }) => {
    const classes = useHeatBreakdownStyles({ noMarginRight });
    const [renderCompactHeader, setRenderCompactHeader] = useState(false);

    const onScroll = useCallback(e => {
        if (e.target.scrollTop > 10 && !renderCompactHeader) setRenderCompactHeader(true);
        if (e.target.scrollTop <= 10 && renderCompactHeader) setRenderCompactHeader(false);
    }, [renderCompactHeader]);

    const { scrollerRef } = useOnScroll(onScroll);

    return (
        <div ref={scrollerRef} className={classes.container}>
            <section className={classes.header}>
                {renderCompactHeader && compactHeader}
                <CloseButton close={closeModal} />
                {fullHeader}
                {selector}
            </section>

            <section className={classes.mainContent}>
                {children}
            </section>
        </div>
    );
};
