export type TrackingCategory = typeof trackingCategory[keyof typeof trackingCategory];
export const trackingCategory = {
    publicNav: "Public Navigation",
    homepageHero: "Homepage Hero",
    homepageFooter: "Homepage Footer",
    athletesResultShare: "Athletes results share",
    sponsorIntegrationAtEvent: "Sponsor integration - Event level",
    sponsorIntegrationAtDivision: "Sponsor integration - Division level",
    signUpFlow: "Sign-up flow",
    orgnaiserOnboarding: "Organiser onboarding",
    avatarMenu: "Avatar menu",
    organiserBanner: "Organiser banner",
    eventMedia: "Event media",
} as const;

export function trackClick(label: string, category: TrackingCategory): () => void;
export function trackClick<T extends HTMLElement>(label: string, category: TrackingCategory, callback: React.MouseEventHandler<T>): (e: React.MouseEvent<T>) => void;
export function trackClick<T extends HTMLElement>(label: string, category: TrackingCategory, callback?: React.MouseEventHandler<T>): (() => void) | ((e: React.MouseEvent<T>) => void) {
    return (e) => {
        (window as any).dataLayer?.push({
            event: "click_event",
            event_label: label,
            event_category: category,
        });
        callback?.(e);
    };
}
