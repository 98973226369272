import { useCallback } from "react";
import { uploadResults } from "../../../../actions/eventDirector";
import { useDispatch } from "react-redux";
import { useDialogState } from "../../../../hooks/useDialogState";
import { ImportButton } from "../../../routes/events/id/dashboard/ImportButton";

export const ImportResultsButton = ({ closeMenu }) => {
    const dispatch = useDispatch();
    const [dialogOpen, openDialog, closeDialog] = useDialogState({ disableBackdropClick: true });

    const onClose = useCallback(() => {
        closeMenu();
        closeDialog();
    }, []);

    const onImport = (eventId, entries) => () =>
        dispatch(uploadResults(eventId, entries))
            .then(closeDialog)
            .catch(closeDialog);

    return (
        <ImportButton title="Upload results"
                      onImport={onImport}
                      onClose={onClose}
                      closeMenu={closeMenu}
                      openConfirmationDialog={openDialog}
                      confirmationDialogOpen={dialogOpen}
                      confirmationTitle="Upload event results?"
                      confirmationContent="ready_to_upload_result"
                      confirmationButtonTitle="Upload results"
                      badImportContent="cannot_upload_results_bad_divisions"
                      withResults={true} />
    );
};

