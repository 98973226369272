import { Fake } from "../../../../layout/skeleton/Fake";
import { Stack, Typography } from "@mui/material";

export const AthleteInjuriesPageSkeleton = () => (
    <div role="status" aria-label="loading" className="skeleton">
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={2} marginTop={3}>
            <Typography variant="h5"><Fake size={30}/></Typography>
            <Fake size={30}/>
        </Stack>
        <Typography variant="body1"><Fake size={100}/></Typography>
    </div>
);
