import { gql } from "@apollo/client";
import { AthleteWithTagsFragment, AthleteWithUsersFragment } from "./athlete";
import { DivisionFragment } from "./division";
import { SeriesFragment } from "./series";
import { SponsoredContentsFragment } from "../components/layout/sponsors/SponsorContent";

const OrganisationFragment = gql`
  fragment Organisation on Organisation {
    id
    name
    shortName
    useNfc
  }
`;

const OrganisationWithEventsFragment = gql`
  fragment OrganisationWithEvents on Organisation {
    ...Organisation
    events {
      id
      name
      status
      date
      daysWindow
    }
  }
  ${OrganisationFragment}
`;

const PropertyFragment = gql`
  fragment Property on Property {
    uuid
    label
    type
    level
    required
    config {
      options
      docusealTemplateId
      description
    }
    sportType
    organisationId
    disabled
  }
`;

const Refund = gql`
  fragment Refund on Refund {
    id
    amount
    status
  }
`;

const OrganisationWithFederationProperties = gql`
  fragment OrganisationWithFederationProperties on Organisation {
    id
    name
    shortName
    sportType
    customProperties(levels: [user, athlete, registration]) {
      ...Property
    }
    federationProperties(levels: [user, athlete, registration]) {
      ...Property
    }
  }
  ${PropertyFragment}
`;

const OrganisationWithCreateEventOptionsFragment = gql`
  fragment OrganisationWithCreateEventOptions on Organisation {
    id
    sportType
    divisions {
      ...Division
    }
    federationSeries {
      id
      name
      organisation {
        id
        name
      }
      rankingsDivisions {
        id
      }
    }
    federationProperties(levels: [athlete, registration]) {
      ...Property
    }
  }
  ${DivisionFragment}
  ${PropertyFragment}
`;

export const GET_ORGANISATION_PURCHASES = gql`
  query getOrganisationPurchases ($id: ID!) {
    organisation(id: $id) {
      id
      shortName
      docusealEnabled
      activePurchases {
        eventCredits {
          xs
          s
          m
          l
          xl
          unlimited
        }
        accountAddOns {
          embed
          whatsAppSupport
          customTemplates
          documentSigning
        }
        eventAddOns {
          fisExport
          priority
          broadcast
        }
      }
    }
  }
`;

export const GET_ORGANISATION_WITH_EVENTS = gql`
  query getOrganisationWithEvents ($id: ID!) {
    organisation(id: $id) {
      ...OrganisationWithEvents
    }
  }
  ${OrganisationWithEventsFragment}
`;

export const GET_ORGANISATION_WITH_TEMPLATES = gql`
  query getOrganisationWithTemplates ($id: ID!) {
    organisation(id: $id) {
      ...Organisation
      federationTemplates {
        id
        name
        sportType
      }
    }
  }
  ${OrganisationFragment}
`;

export const GET_ORGANISATION_WITH_FEDERATION_EVENT_DIVISION_PROPERTIES = gql`
  query getOrganisationWithFederationEventDivisionProperties ($id: ID!) {
    organisation(id: $id) {
      id
      federationProperties(levels: [event_division]) {
        ...Property
      }
    }
  }
  ${PropertyFragment}
`;

export const GET_ORGANISATION_WITH_FEDERATION_PROPERTIES = gql`
  query getOrganisationWithFederationProperties ($id: ID!) {
    organisation(id: $id) {
      ...OrganisationWithFederationProperties
    }
  }
  ${OrganisationWithFederationProperties}
`;

export const GET_ORGANISATION_WITH_CREATE_EVENT_OPTIONS = gql`
  query getOrganisationWithCreateEventOptions ($id: ID!) {
    organisation(id: $id) {
      ...OrganisationWithCreateEventOptions
    }
  }
  ${OrganisationWithCreateEventOptionsFragment}
`;

export const GET_ORGANISATION_WITH_COPY_EVENT_OPTIONS = gql`
  query getOrganisationWithCopyEventOptions ($id: ID!) {
    organisation(id: $id) {
      ...OrganisationWithCreateEventOptions
      events {
        id
        name
      }
    }
  }
  ${OrganisationWithCreateEventOptionsFragment}
`;

export const GET_FEDERATION_ATHLETES = gql`
  query getFederationAthletes ($id: ID!, $page: Int!, $per: Int!, $search: String) {
    federationAthletes(id: $id, page: $page, per: $per, search: $search) {
      athletes {
        id
        name
      }
      totalCount
    }
  }
`;

export const GET_FEDERATION_ATHLETES_WITH_TAGS = gql`
  query getFederationAthletesWithTags ($id: ID!, $page: Int!, $per: Int!, $search: String, $athleteId: ID) {
    federationAthletes(id: $id, page: $page, per: $per, search: $search, athleteId: $athleteId) {
        athletes {
            ...AthleteWithTags
        }
        totalCount
    }
  }
  ${AthleteWithTagsFragment}
`;

export const GET_ORGANISATION_PAYMENTS_RECEIVED = gql`
  query getOrganisationPaymentsReceived ($id: ID!, $payableId: ID, $payableType: String, $page: Int!, $per: Int!) {
    organisation(id: $id) {
      paymentsReceived(payableId: $payableId, payableType: $payableType, page: $page, per: $per) {
          totalCount
          payments {
              id
              createdAt
              user {
                id
                name
                email
              }
              payable {
                ...on Event {
                    id
                    name
                }
                ...on Series {
                    id
                    name
                }
              }
              amount
              fee
              intentId
              status
              refunds {
                ...Refund
              }
              currency
          }
      }
    }
  }
  ${Refund}
`;

export const SHORT_NAME_AVAILABLE = gql`
  query shortNameAvailable($shortName: String, $organisationId: ID) {
    shortNameAvailable(shortName: $shortName, organisationId: $organisationId)
  }
`;

export const CREATE_ORGANISATION = gql`
  mutation createOrganisation($organisation: OrganisationInput!) {
    createOrganisation(input: {organisation: $organisation}) {
      organisation {
        ...OrganisationWithEvents
      }
    }
  }
  ${OrganisationWithEventsFragment}
`;

export const UPDATE_ORGANISATION = gql`
  mutation updateOrganisation($organisation: EditOrganisationInput!) {
    updateOrganisation(input: {organisation: $organisation}) {
      organisation {
        ...OrganisationWithEvents
      }
    }
  }
  ${OrganisationWithEventsFragment}
`;

export const CREATE_ORGANISATION_PROPERTY = gql`
  mutation createOrganisationProperty($id: ID!, $property: PropertyInput!) {
    createOrganisationProperty(input: {id: $id, property: $property}) {
      property {
        ...Property
      }
    }
  }
  ${PropertyFragment}
`;

export const ARCHIVE_ORGANISATION_PROPERTY = gql`
  mutation archiveOrganisationProperty($id: ID!, $uuid: String!) {
    archiveOrganisationProperty(input: {id: $id, uuid: $uuid}) {
      property {
        ...Property
      }
    }
  }
  ${PropertyFragment}
`;

export const GET_ORGANISATION_BY_SHORT_NAME = gql`
  query getOrganisationByShortName ($shortName: String) {
    organisationByShortName(shortName: $shortName) {
      id
      name
      shortName
      logo
      instagram
      facebook
      events {
        id
        name
        status
        date
        daysWindow
        hideFinals
        series {
          id
          name
          organisation {
            id
            shortName
          }
        }
        currentScheduleIndex
        ...SponsoredContents
      }
      series {
        ...Series
        rankingsDisplayProperty
        rankingOptions {
          filters {
            property {
              label
              value
            }
          }
          cutLines {
            divisionId
            position
            description
          }
        }
        childSeries {
          ...Series
          parentSeries {
            id
          }
        }
      }
      federatedOrganisations {
        id
        name
        shortName
        logo
      }
      federatedOrganisationTerm
      contactEmail
      sportType
    }
  }
  ${DivisionFragment}
  ${SeriesFragment}
  ${SponsoredContentsFragment}
`;

export const GET_ORGANISATION_USERS = gql`
  query getOrganisationUsers($id: ID!) {
    organisationUsers(id: $id) {
      id
      name
      email
      role
      phone
    }
  }
`;

export const GET_ORGANISATION_ATHLETES = gql`
  query getOrganisationAthletes($id: ID!, $search: String, $page: Int!, $per: Int!) {
    organisationAthletes(id: $id, search: $search, page: $page, per: $per) {
      athletes {
        ...AthleteWithUsers
      }
      totalCount
    }
  }
  ${AthleteWithUsersFragment}
`;

export const GET_FEDERATION_TEAMS = gql`
  query getFederationTeams($id: ID!, $search: String, $page: Int!, $per: Int!) {
    federationTeams(id: $id, search: $search, page: $page, per: $per) {
      teams{
        name
        id
      }
      totalCount
    }
  }
`;

export const GET_ORGANISATION_WITH_POINT_ALLOCATIONS = gql`
  query getOrganisationWithPointAllocations ($id: ID!) {
    organisation(id: $id) {
      id
      federationPointAllocations {
        id
        name
      }
    }
  }
`;
