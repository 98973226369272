import { makeStyles } from "components/providers/makeStyles";
import { Heading } from "../../layout/page/Heading";
import { Fake } from "../../layout/skeleton/Fake";
import { FakeFormField } from "../../layout/forms/FakeFormField";

const useStyles = makeStyles(theme => ({
    form: {
        marginTop: theme.spacing(3),
        overflowX: "hidden",
        "& .fake": {
            maxWidth: "calc(100vw - 40px)"
        }
    },
}));
export const SeriesSettingsPageSkeleton = () => {
    const classes = useStyles();

    return (
        <div className="wrapper skeleton" role="status" aria-label="loading">
            <Heading title={<Fake size={35}/>}/>

            <form className={classes.form}>
                <FakeFormField label={30} field={180}/>
                <FakeFormField label={60} field={30}/>
                <FakeFormField label={60} field={180}/>
                <FakeFormField label={50} field={180}/>
            </form>
        </div>
    );
};
