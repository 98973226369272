import { string } from "yup";
import { forwardRef } from "react";
import { CreatableSearchSelect } from "./Autocomplete/CreatableSearchSelect";
import { SearchSelect } from "./Autocomplete/SearchSelect";
import { formatValueToOption, parseOptionToValue } from "components/forms/Field";
import { useLocalizationContext, useTranslate } from "components/providers/LocalizationProvider";
import { PaperComponent } from "./Autocomplete/SearchSelect";
import { Box, Typography } from "@mui/material";
import { T } from "components/util/t";
import { useGetAthletes } from "../../athletes/useGetAthletes";
import { filterList } from "hooks/useListFilter";
import { useGetTeams } from "components/layout/athletes/useGetTeams";

export const ListboxComponent = forwardRef(({ children, totalCount, resultsPerPage, options, loading, ...rest }, ref) => (
    <ul {...rest} ref={ref}>
        {children}
        {!loading && totalCount - options.length > 0 && (
            <Box component="li" paddingX={2} marginTop={1}>
                <Typography color="text.secondary">
                    <T x={resultsPerPage}>showing_x_results_per_page</T>
                </Typography>
            </Box>
        )}
    </ul>
));

export const useSelectorField = ({ loadingMsg, placeholderText, label, options, headingText, autocompleteProps, ...rest }) => {
    const loadingText = useTranslate(loadingMsg || "Loading…");
    const { translate } = useLocalizationContext();
    return {
        parse: parseOptionToValue,
        format: formatValueToOption,
        placeholder: placeholderText,
        inputProps: { autoFocus: true },
        validation: string().required("Required").nullable(),
        options,
        createLabel: value => translate(label || "+ Add item", { value }),
        autocompleteProps: {
            loadingText,
            slotProps: {
                paper: { headingText },
            },
            ...autocompleteProps
        },
        ...rest
    };
};

const mapOptions = alreadyAdded => a => {
    const isAlreadyAdded = alreadyAdded?.indexOf(parseInt(a.id)) > -1;
    return ({ label: a.name, value: parseInt(a.id), disabled: isAlreadyAdded, message: isAlreadyAdded ? "Already added" : undefined });
};

const noop = v => v;
const matchOption = getOptionLabel => (athlete, searchRegex) => getOptionLabel(athlete).match(searchRegex);
const filterOptions = (options, state) => filterList(options, state.inputValue, matchOption(state.getOptionLabel), true);

export const useAsyncSelectorField = (queryData, { alreadyAdded, autocompleteProps, headingText, ...rest }) => {
    const { items, loading, search, totalCount, resultsPerPage } = queryData;
    const options = items.map(mapOptions(alreadyAdded));

    return useSelectorField({
        ...rest,
        options,
        loading,
        filterOptions: loading ? filterOptions : noop,
        autocompleteProps: {
            onInputChange:  (e, value) => {
                value && search(value);
            },
            slotProps: {
                paper: { headingText },
                listbox: { component: ListboxComponent, totalCount, resultsPerPage, options, loading }
            },
            ...autocompleteProps
        }
    });
};

export const useAsyncAthleteField = ({ isTeams, ...rest }) => {
    const athleteData = useGetAthletes();
    const placeholderText = isTeams ? "Enter team name..." : "Enter athlete name...";
    return useAsyncSelectorField(athleteData, { loadingMsg: "Loading profiles…", placeholderText: placeholderText, label: "add_value_as_new_profile", headingText: "Select profile", ...rest } );
};

export const AsyncAthleteSelectorField = ({ onChange, value, ...rest }) => {
    const field = useAsyncAthleteField(rest);
    return <SelectorFieldComponent field={field} input={{ onChange, value }} />;
};

export const AthleteSelectorField = ({ isTeams, ...rest }) => {
    const placeholderText = isTeams ? "Enter team name..." : "Enter athlete name...";
    return <SelectorField loadingMsg={"Loading profiles…"} placeholderText={placeholderText} label={"add_value_as_new_profile"} headingText={"Select profile"} {...rest} />;
};

export const useAsyncTeamField = (props) => {
    const teamData = useGetTeams();
    return useAsyncSelectorField(teamData, { loadingMsg: "Loading teams…", placeholderText: "Enter team", label: "add_value_as_new_team", headingText: "Select team", ...props });
};

export const AsyncTeamSelectorField = ({ onChange, value, ...rest }) => {
    const field = useAsyncTeamField(rest);
    return <SelectorFieldComponent field={field} input={{ onChange, value }} />;
};

export const TeamSelectorField = (props) => {
    return <SelectorField loadingMsg={"Loading teams…"} placeholderText={"Enter team"} label={"add_value_as_new_team"} headingText={"Select team"} {...props} />;
};

export const SelectorField = ({ onChange, value, ...rest }) => {
    const field = useSelectorField(rest);
    return <SelectorFieldComponent field={field} input={{ onChange, value }} />;
};

const SelectorFieldComponent = ({ field, input }) => {
    const Component = field.disableCreate ? SearchSelect : CreatableSearchSelect;
    return <Component field={field} input={input} />;
};
