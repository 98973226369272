import { MaxWidthPageWithHeading } from "../../layout/page/MaxWidthPageWithHeading";
import { EventLinkRow } from "../../layout/event/EventLink";
import { Fake } from "../../layout/skeleton/Fake";
import { makeStyles } from "../../providers/makeStyles";

const useStyles = makeStyles(theme => ({
    events: {
        marginBottom: theme.spacing(5),
    }
}));
export const DirectorPageSkeleton = () => {
    const classes = useStyles();

    return(
        <MaxWidthPageWithHeading className="skeleton" title="My events">
            <div className={classes.events} role="status" aria-label="loading">
                <EventLinkRow link="#" name={<Fake size={20}/>} date={<Fake size={40}/>} status={"upcoming"}/>
                <EventLinkRow link="#" name={<Fake size={23}/>} date={<Fake size={47}/>} status={"on"}/>
                <EventLinkRow link="#" name={<Fake size={17}/>} date={<Fake size={43}/>} status={"finished"}/>
            </div>
        </MaxWidthPageWithHeading>
    );
};
