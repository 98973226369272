import { EventStatus } from "../../../graphql/generated/types";

export const EVENT_STATUSES = {
    scheduled: "Scheduled",
    upcoming: "Entry open",
    drawn: "Entry closed",
    published: "Draw published",
    on: "Live",
    on_hold: "On hold",
    finished: "Complete",
    results_published: "Results published",
    cancelled: "Cancelled"
} as const satisfies Record<EventStatus, string>;