import { Control, FormProvider, SubmitHandler, UseFormGetValues, UseFormSetValue, UseFormTrigger, UseFormWatch } from "react-hook-form";
import { BaseFieldProps, Field, FieldProps, FieldWithOptionsProps } from "./Field";
import { FormWrapper } from "../layout/forms/FormWrapper";
import { DialogFormWrapper } from "../layout/forms/DialogFormWrapper";
import { Box, Stack } from "@mui/material";
import { Fieldset } from "./Fieldset";
import { useFormKit, UseFormKitProps } from "./useFormKit";

export interface FieldComponentsProps {
    fields: (FieldProps | FieldProps[])[];
    control: Control;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
    trigger: UseFormTrigger<any>;
    watch: UseFormWatch<any>;
}

export interface FieldComponentProps extends Omit<FieldComponentsProps, "fields"> {
    field: FieldProps
}

const FieldComponent: React.FCWithChildren<FieldComponentProps> = ({ field, control, getValues, setValue, trigger, watch }) =>
    field.type === "fieldset" && field.fields ?
        <Fieldset field={field} watch={watch}>
            <FieldComponents fields={field.fields} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch} />
        </Fieldset> :
        field.component ?
            <field.component field={field} control={control} getValues={getValues} setValue={setValue} trigger={trigger}/> :
            <Field field={field as (BaseFieldProps | FieldWithOptionsProps)} getValues={getValues} setValue={setValue} control={control} trigger={trigger}/>;

export const FieldComponents: React.FC<FieldComponentsProps> = ({ fields, control, getValues, setValue, trigger, watch }) =>
    fields?.filter(f => f && (Array.isArray(f) || !f.renderIf || f.renderIf(watch))).map((field, i) =>
        Array.isArray(field) ?
            <Stack key={i} spacing={[2, 4]} direction="row" flexBasis={1}>
                {field.filter(f => f).map(innerField =>
                    <Box key={innerField.name || i} flex={1}>
                        <FieldComponent field={innerField} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch}/>
                    </Box>
                )}
            </Stack> :
            <FieldComponent key={field.name || i} field={field} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch}/>
    );

interface FormKitProps extends UseFormKitProps {
    onSubmit: (values: any, e: React.SyntheticEvent) => Promise<any>;
    fullWidthSubmitButton?: boolean;
    enableOnPristine?: boolean;
    onCancel?: (e: React.SyntheticEvent, reason: "backdropClick" | "escapeKeyDown") => void;
    buttonLabel: string;
    buttonEndIcon?: React.ReactNode;
    classNames?: { [key: string]: string };
    secondaryAction?: React.ReactNode;
    formCaption?: string;
    FormWrapperComponent?: React.ComponentType<any>;
    footer?: React.ReactNode;
}

export const FormKit: React.FCWithChildren<FormKitProps> = ({
    fields,
    initialValues,
    onSubmit,
    resetOnSubmit,
    fullWidthSubmitButton,
    enableOnPristine,
    onCancel,
    buttonLabel,
    buttonEndIcon,
    classNames,
    secondaryAction,
    formCaption,
    FormWrapperComponent = FormWrapper,
    footer,
    children
}) => {
    const { handleSubmit, control, components, getValues, setValue, setError, trigger } = useFormKit({ fields, initialValues, resetOnSubmit });

    const submit = (values: { [key: string]: any }, e: React.SyntheticEvent) =>
        onSubmit(values, e).catch((e: Record<string, any>) => {
            if (e?.response?.status === 422) {
                Object.keys(e.response.data?.errors || {}).forEach(field =>
                    setError(field, {
                        type: "manual",
                        message: e.response.data.errors[field]
                    }, { shouldFocus: true })
                );
            } else {
                throw e;
            }
        });

    return (
        // @ts-expect-error RHF expects all form methods
        <FormProvider control={control} trigger={trigger} getValues={getValues} setValue={setValue}>
            <FormWrapperComponent
                control={control}
                fullWidthSubmitButton={fullWidthSubmitButton}
                enableOnPristine={enableOnPristine}
                handleSubmit={handleSubmit(submit as SubmitHandler<any>)}
                buttonLabel={buttonLabel}
                buttonEndIcon={buttonEndIcon}
                formCaption={formCaption}
                onCancel={onCancel}
                classNames={classNames}
                secondaryAction={secondaryAction}
            >
                {children}
                {components}
                {footer}
            </FormWrapperComponent>
        </FormProvider>
    );
};

export const DialogFormKit = (props: FormKitProps) => <FormKit {...props} FormWrapperComponent={DialogFormWrapper}/>;
