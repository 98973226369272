import { useEffect } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import classnames from "classnames";
import { Avatar, Typography } from "@mui/material";

import * as HeatActions from "../../../actions/heat";

import { HeatHeader } from "./header";
import { AthleteNameCell } from "components/layout/tables/AthleteNameCell";
import { Tooltip } from "../../elements/tooltip";
import { T } from "../../util/t";
import { makeStyles } from "components/providers/makeStyles";
import { FormattedTotal } from "../../../models/FormattedTotal";

const AthleteHeatResultSkeleton = () =>
    <tr className="athlete-heat-row">
        <td className="place"></td>
        <td className="avatar"><div className="img fake"/></td>
        <td className="name">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </td>
        <td className="overall">
            <div className="total">&nbsp;</div>
            <div className="win-by-needs">&nbsp;</div>
        </td>
        <td className="all-ride-scores"/>
    </tr>;

export const LiveHeatsCardsSkeleton = () =>
    <div className="live-heats skeleton">
        <div className="heat-card">
            <header className="heat-header">
                <T>HeatHeader_empty_title</T>
            </header>
            <table className="athlete-table"><tbody>
                <AthleteHeatResultSkeleton/>
                <AthleteHeatResultSkeleton/>
                <AthleteHeatResultSkeleton/>
                <AthleteHeatResultSkeleton/>
            </tbody></table>
        </div>
    </div>;

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: "100%",
        borderSpacing: 0,
        borderCollapse: "collapse",
        border: `1px solid ${theme.palette.border.main}`,
        "tr": {
            backgroundColor: "white"
        },
        "td": {
            borderStyle: "solid",
            borderWidth: "1px 0",
            borderColor: theme.palette.border.main
        },
        "tbody": {
            "td": {
                textAlign: "center",
                borderBottomWidth: 2
            },
            "tr:last-child td": {
                borderBottomWidth: 1
            }
        },
        ".place": {
            textAlign: "center"
        },
    }
}));

export const HeatCardWrapper = ({ heat, displayFormat, isNext, children, className = "", displayTimerIcon }) => {
    const classes = useStyles();

    return (
        <div className={classnames("heat-card", className)}>
            <HeatHeader heat={heat}
                        isNext={isNext}
                        displayFormat={displayFormat}
                        displayTimerIcon={displayTimerIcon} />
            <table className={classnames(classes.table, "athlete-table")}>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};

export const resultsByAthletePosition = (heat, minSpots = 0) => {
    const heatSize = Math.max((heat.get("athletes", Immutable.List()).map(a => a.get("position")).max() || 0) + 1, heat.getIn(["config", "heat_size"], 0), heat.getIn(["config", "number_of_lanes"], 0), minSpots);
    return Immutable.List().setSize(heatSize).map((i, position) => {
        const athlete = heat.get("athletes", Immutable.Map()).find(a => a.get("position") === position, this, Immutable.Map()),
            resultIndex = heat.get("result", Immutable.List()).findIndex(r => r.get("athlete_id") === athlete.get("id")),
            result = heat.getIn(["result", resultIndex]);

        return !athlete.isEmpty() && Immutable.Map({ id: athlete.get("athlete_heat_id"), athlete, result, place: result?.get("place") });
    });
};

export const resultsByResultOrder = (heat) =>
    heat.get("result", Immutable.List())
        .map((result, i) => Immutable.fromJS({
            result,
            id: heat.getIn(["athletes", "" + result.get("athlete_id"), "athlete_heat_id"]),
            athlete: heat.getIn(["athletes", "" + result.get("athlete_id")]),
            place: result.get("place")
        }));

export const AthletePlaceAndName = ({ place = "", ...rest }) => (
    <>
        <td className="place">{place}</td>
        <AthleteNameCell {...rest}/>
    </>
);

export const TotalCell = ({ total, winBy, needs, maxRideScore, heatFinished, calculator }) =>
    <td className="overall">
        <div className={classnames("total", { "no-score": !total > 0 })}>
            {FormattedTotal(total, { calculator })}
        </div>
        <div className="win-by-needs">
            {winBy !== undefined ?
                (<T {...{ winBy, heatFinished }}>TotalCell_winBy</T>) :
                (needs && <T {...{ needs, heatFinished, maxRideScore }}>TotalCell_needs</T>)
            }
        </div>
    </td>;

const EmptyRow = ({ jersey, progression, displayScores }) =>
    <tr className={`athlete-heat-row empty-row ${jersey} ${displayScores ? "" : "no-scores"}`}>
        <td className="place"/>
        <td className="avatar"><div className="img fake"/></td>
        <td className="name" colSpan="3">{progression ?
            <T progression={progression}>progression</T>
            :
            <T>No athlete</T>}
        </td>
    </tr>;

export const HeatCard = ({ heat, displayFormat, hideFinal, progression = Immutable.List(), displayTimerIcon, forceResultOrder, isWhiteboard }) => {
    const displayScores = !!heat.get("start_time") && !heat.getIn(["config", "hide_scores"]) && heat.getIn(["config", "calculator"]) !== "placing",
        athleteSpots = (forceResultOrder || displayScores) ?
            resultsByResultOrder(heat) :
            resultsByAthletePosition(heat, progression.size);

    return (
        <HeatCardWrapper heat={heat} displayFormat={displayFormat} displayTimerIcon={displayTimerIcon}>
            {athleteSpots.map((spot, i) =>
                spot ?
                    <AthleteHeatRow key={i}
                                    athlete={spot.get("athlete")}
                                    rides={spot.getIn(["result", "rides"])}
                                    total={spot.getIn(["result", "total"])}
                                    winBy={heat.getIn(["config", "hide_needs"]) ? undefined : spot.getIn(["result", "win_by"])}
                                    needs={!heat.getIn(["config", "hide_needs"]) && spot.getIn(["result", "needs"])}
                                    maxRideScore={heat.getIn(["config", "max_ride_score"])}
                                    categories={heat.getIn(["config", "categories"])}
                                    calculator={heat.getIn(["config", "calculator"])}
                                    heatFinished={!!heat.get("end_time")}
                                    displayScores={displayScores}
                                    place={spot.get("place")}
                                    hasPriority={heat.getIn(["config", "has_priority"])}
                                    isWhiteboard={isWhiteboard} /> :

                    (progression.get(i) &&
                        <EmptyRow key={i}
                                  jersey={heat.getIn(["config", "jersey_order", i])}
                                  progression={progression.get(i)}
                                  displayScores={displayScores}/>)
            )}
        </HeatCardWrapper>
    );
};

export const LiveHeatsCards = ({ heats, hideFinal, displayTimerIcon }) =>
    <div className="live-heats">
        {heats.filter(h => h).map((heat, i) =>
            <HeatCard
                key={i}
                heat={heat}
                hideFinal={hideFinal}
                displayTimerIcon={displayTimerIcon}
            />
        )}
    </div>;

LiveHeatsCards.propTypes = {
    heats: PropTypes.instanceOf(Immutable.List),
    hideFinal: PropTypes.bool,
    displayTimerIcon: PropTypes.bool,
};

const Ride = ({ ride, athleteIndicator, rideIndex, categories, calculator }) => {
    const originalScore = parseFloat(ride.get("total")).toFixed(2),
        modifier = ride.getIn(["modifier", "type"]),
        modifierClass = modifier && modifier.split("::")[1],
        interfere = modifier && modifier.match(/Interference/),
        didNotModifier = modifier && modifier.match(/D(N|S)(S|F|Q|I)/),
        modified = ride.get("total") !== ride.get("modified_total"),
        score = ride.get("modified_total"),
        top = ride.get("scoring_ride"),
        categoryNames = (categories || Immutable.fromJS({ "": {} })).sortBy((config, category) => config.get("order", category)).keySeq(),

        scoreText = FormattedTotal(score, { calculator }),
        text = interfere ? <T>INT</T> : didNotModifier ? <T>{modifierClass}</T> : scoreText;

    return (
        <Tooltip shouldHide={!ride.get("scores")}
                 title={<>
                     <div className="categories">
                         {categoryNames.map(category => (
                             <div key={category} className="category">
                                 {categories && <div className="tooltip-heading">{category}</div>}
                                 {ride.get("scores") &&
                                    <div className="tooltip-body">
                                        {ride.get("scores").valueSeq().map((scores, i) =>
                                            scores.filter ?
                                                scores.filter((score, sCategory) => sCategory === category).valueSeq().map((score, j) => <div key={`category-${j}`}>{parseFloat(score).toFixed(categories.getIn([category, "judging_decimals"], 1))}</div>) :
                                                <div key={i}>{parseFloat(scores).toFixed(1)}</div>
                                        )}
                                    </div>}
                             </div>
                         ))}
                     </div>
                 </>}
                 childrenWrapperClass={`ride ${classnames({ "top": top })} ${classnames({ [modifierClass]: modifier })}`} variableWidth>

            <sup>{rideIndex + 1}</sup>
            {modified && <span className="original-score">{originalScore}</span>}
            <div className="modified-score">{text}</div>

            {athleteIndicator && <sub>{athleteIndicator}</sub>}
        </Tooltip>
    );
};

const useAthleteHeatRowStyles = makeStyles((theme, { isWhiteboard }) => ({
    priorityCell: {
        minWidth: theme.spacing(5),
    },
    priority: {
        margin: theme.spacing(0, 0.5),
        background: !isWhiteboard && theme.palette.text.primary,
        color: !isWhiteboard && "white"
    }
}));

export const AthleteHeatRow = ({ athlete, place, rides = Immutable.Map(), total, winBy, needs, displayScores, heatFinished, maxRideScore, categories, hasPriority, isWhiteboard, calculator }) => {
    const priority = athlete.get("priority");
    const classes = useAthleteHeatRowStyles({ isWhiteboard });
    const withPriority = hasPriority && !heatFinished;
    const teamMembersByOrder = athlete.get("athletes")?.sort(a => a.get("order")) || Immutable.List([athlete]);
    const isScored = total > 0;

    return (
        <tr className={`athlete-heat-row ${classnames({ "no-scores": !displayScores, "with-priority": withPriority })} ${athlete.get("jersey") || "no-jersey"}`}>
            <AthletePlaceAndName {...{
                jersey: athlete.get("jersey"),
                place: displayScores ? isScored ? place : "-" : "",
                name: athlete.get("name"),
                image: athlete.get("image"),
                bib: athlete.get("bib"),
                teamName: athlete.get("team_name")
            }}/>

            {withPriority && <td className={classes.priorityCell}>
                {priority && <Avatar className={classes.priority}><Typography variant="label1">P{priority}</Typography></Avatar>}
            </td>}

            {displayScores && <TotalCell {...{ total, winBy, needs, maxRideScore, heatFinished, calculator }}/>}

            {displayScores &&
            <td className="all-ride-scores">
                <div className="rides-container">
                    {teamMembersByOrder.map((athlete, athleteIndex) =>
                        rides.get("" + athlete.get("id"), Immutable.List()).filter(r => !r.get("pending")).map((ride, i) =>
                            <Ride
                                key={i}
                                ride={ride}
                                rideIndex={i}
                                categories={categories}
                                calculator={calculator}
                                athleteIndicator={rides.keySeq().size > 1 ?  `A${athleteIndex + 1}` : undefined}/>
                        )
                    )}
                </div>
            </td>}
        </tr>
    );
};

export const LoadingHeatCard = ({ dispatch, eventId, hideFinal, heat, isWhiteboard }) => {
    useEffect(() => {
        if (!heat.get("result")) dispatch(HeatActions.get(eventId, heat.get("id")));
    }, [heat]);

    return heat.get("result") ?
        <HeatCard heat={heat} hideFinal={hideFinal} isWhiteboard={isWhiteboard}/> :
        <LiveHeatsCardsSkeleton/>;
};
