import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";

export interface OptimizedImageType extends React.ImgHTMLAttributes<HTMLImageElement> {
    width?: number;
    height?: number;
    component?: React.ElementType;
    fluid?: boolean;
};

interface StyleProps {
    width?: number;
    height?: number;
}

const useStyles = makeStyles<StyleProps>((theme, { width, height }) => ({
    img: {
        maxWidth: width,
        maxHeight: height
    }
}));

export const OptimizedImage: React.FCWithChildren<OptimizedImageType> = ({ src, width, height, alt, component: Component = "img", className, fluid = false, ...rest }) => {
    const classes = useStyles({ width, height });
    const options = [width && `width=${width * 2}`, height && `height=${height * 2}`].filter(Boolean).join("&");
    const source = src?.slice(-5) === ".webp" && src.includes("images/") && (width || height) ? `${src}!${options}` : src;
    return <Component src={source} alt={alt} className={classnames(!fluid && classes.img, className)} {...rest} />;
};
