import { Component } from "react";
import PropTypes from "prop-types";
import Immutable from "immutable";
import classnames from "classnames";
import { connect } from "react-redux";
import { Field, formValueSelector, reduxForm } from "redux-form/immutable";
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Add, Check, Delete } from "@mui/icons-material";

import * as HeatActions from "../../../actions/heat";
import { chipsSelectInput, decimalOrEmpty, plainInput, radioInput, required } from "../../forms";

import { LoadingButton } from "../../actions/loadingButton";
import { ResponsiveDialog } from "../../modal";
import { toFixedOrDash } from "./editHeat";
import { judgeScoreNormalize } from "../../../models/judgeScoreNormalize";
import { getModifiers, modifierItemProps } from "../judging/ModifierMenu";
import { T } from "../../util/t";
import { JudgingCompetitorIdentifier } from "../../layout/judging/JudgingAthleteModalHeader";
import { useVisualViewportHeight } from "hooks/useVisualViewportHeight";

export const EditRideDialog = ({ open, ...props }) => {
    const viewportHeight = useVisualViewportHeight();

    return (
        <ResponsiveDialog className="edit-ride-dialog lh-dialog"
                          open={open}
                          fullWidth={true}
                          maxWidth="md"
                          aria-labelledby="edit-ride-dialog-title"
                          style={{ maxHeight: viewportHeight }}
                          onClose={props.handleClose}>
            <EditRideForm {...props}/>
        </ResponsiveDialog>
    );
};


const selector = formValueSelector("editRide");
const requireCategory = value => required(value) ? "We need the score category 😉" : undefined;

const EditRideForm = connect((state, props) => ({
    currentUser: state.users.get("current"),
    judgeAccounts: state.users.getIn(["current", "organisation", "judges"]),
    initialValues: props.ride
        .set("modifier", props.ride.getIn(["modifier", "type"], null))
        .update("scores", scores => scores
            ? scores.map(judgeScores => judgeScores.filter ? judgeScores.filter((s, key) => key !== "total") : judgeScores)
            : Immutable.Map()),
    total: selector(state, "total"),
    scores: selector(state, "scores"),
    modifier: selector(state, "modifier")
}))(reduxForm({ enableReinitialize: true, keepDirtyOnReinitialize: true, touchOnChange: true, form: "editRide" })(
    class EditRideForm extends Component {
        static propTypes = {
            heatId: PropTypes.number.isRequired,
            dispatchHeatId: PropTypes.number.isRequired,
            ride: PropTypes.instanceOf(Immutable.Map).isRequired,
            categoryJudges: PropTypes.instanceOf(Immutable.Map).isRequired,
            heatConfig: PropTypes.instanceOf(Immutable.Map).isRequired,
            judgeAccounts: PropTypes.instanceOf(Immutable.List),
            athleteName: PropTypes.string,
            rideNumber: PropTypes.number,
            jersey: PropTypes.string,
            athleteId: PropTypes.string
        };

        constructor(props) {
            super(props);

            const { heatConfig } = this.props,
                categories = heatConfig.get("categories", Immutable.fromJS({ "": { judging_scale: heatConfig.get("max_ride_score") } })),
                inputGroups = heatConfig.get("judge_categories_total") ? Immutable.Map({ "":"" }) : categories;
            this.normalizes = categories.map(category => judgeScoreNormalize(category.get("judging_scale"), category.get("judging_decimals")));
            this.state = { numberOfInputsPerCategory: inputGroups.map(() => 0) };
        }

        deleteRide = () => {
            const { dispatch, handleClose, eventId: event_id, dispatchHeatId: heat_id, heatId, athleteId: athlete_id, dispatchRideIndex: ride } = this.props;
            return dispatch(HeatActions.deleteRide({ event_id, heat_id, athlete_id, ride }, heatId)).then(handleClose);
        };

        calculateTotalScore = (scores, totaling) => {
            const dropScores = this.props.heatConfig.get("drop_scores"),
                minimumJudges = this.props.heatConfig.get("minimum_judges"),
                flatScores = scores &&
                    scores.valueSeq().map(s => s.map ?
                        s.map((score, category) => Immutable.Map({ category, score })).valueSeq() :
                        Immutable.Map({ score: s, category: "" })),
                filteredScores = scores && (this.props.heatConfig.get("categories") ? flatScores.flatten(1) : flatScores)
                    .filter(s => s.get("score"));

            if (!scores || filteredScores.count() < minimumJudges) {
                return null;
            }

            const scoresToUse = dropScores ? filteredScores.sortBy(s => s.get("score")).toList().shift().pop() : filteredScores;
            if (scoresToUse.isEmpty()) {
                return null;
            }
            return totaling(scoresToUse);
        };

        calculateAverage = (scores) =>
            this.calculateTotalScore(scores, scoresToUse =>
                scoresToUse.reduce((result, score) => result + parseFloat(score.get("score") || 0), 0) / scoresToUse.size);

        calculateAverageWithDouble = (scores) => {
            const total = this.calculateAverage(scores);
            return total ? (total.toFixed(2) * 2) : null;
        };

        calculateSum = (scores) =>
            this.calculateTotalScore(scores, scoresToUse =>
                scoresToUse
                    .groupBy(s => s.get("category"))
                    .reduce((sum, categoryScores, category) =>
                        sum +
                        categoryScores.reduce((result, score) => result + parseFloat(score.get("score") || 0), 0) *
                        (this.props.heatConfig.getIn(["categories", category, "minimum_scores"], categoryScores.count()) / categoryScores.count())
                    , 0)
            );

        scoresMap = {
            avg: {
                "Modifiers::AbbPenalty": this.calculateAverage,
                "": this.calculateAverage,
                null: this.calculateAverage,
                "Modifiers::Double": this.calculateAverageWithDouble
            },
            sum: {
                "": this.calculateSum,
                null: this.calculateSum
            }
        };

        nullScore = () => null;
        scoresFor = modifier => this.scoresMap[this.props.heatConfig.get("ride_total", "avg")][modifier] || this.nullScore;

        updateTotal = (judgeId, category, newValue) => {
            const { change, scores, modifier, heatConfig } = this.props,
                scorePath = Immutable.List(["" + judgeId, category]).filter(p => p).toArray(),
                newScores = (scores || Immutable.Map()).setIn(scorePath, newValue),
                scoresToUse = heatConfig.get("judge_categories_total") ? this.calculateJudgeTotals(newScores) : newScores;

            change("total", this.scoresFor(modifier)(scoresToUse));
        };

        updatedModifier = (e, modifier) => {
            const { change, scores, heatConfig } = this.props,
                scoresToUse = heatConfig.get("judge_categories_total") ? this.calculateJudgeTotals(scores) : scores;

            change("total", this.scoresFor(modifier)(scoresToUse));
        };

        calculateJudgeTotals = scores => {
            const { heatConfig } = this.props;
            return scores && scores.map(judgeScores => {
                const total = judgeScores.reduce((result, score, category) =>
                    result + (parseFloat(score || 0) * (heatConfig.getIn(["categories", category, "weight"], 1)))
                , 0);
                return Immutable.Map({ total });
            });
        };

        submit = (values) => {
            const { dispatch, eventId: event_id, dispatchHeatId: heat_id, heatId, athleteHeatId: athlete_heat_id, teamPosition: team_position, athleteId: athlete_id, dispatchRideIndex: ride_number, handleClose } = this.props;
            return dispatch(HeatActions.updateRide({
                event_id,
                heat_id,
                athlete_heat_id,
                team_position,
                athlete_id,
                ride_number,
                ride: values
            }, heatId)).then(handleClose);
        };

        getNumberOfInputs = (category) =>
            Immutable.List([
                1,
                this.props.categoryJudges.get(category, Immutable.List()).count(),
                (this.props.heatConfig.get("minimum_judges") || 0) / (this.props.heatConfig.get("judge_categories_total") ? 1 : this.props.heatConfig.get("categories", Immutable.Map({ "":"" })).keySeq().count()),
                this.state.numberOfInputsPerCategory.get(category)
            ]).max();

        addAnotherScore = (category) =>
            this.setState({ autoFocus: true, numberOfInputsPerCategory: this.state.numberOfInputsPerCategory.set(category, this.getNumberOfInputs(category) + 1) });

        render = () => {
            const { ride, rideNumber, athleteName, teamName, jersey, bib, total, modifier, heatConfig, judgeAccounts, categoryJudges, adding, handleClose, handleSubmit, pristine } = this.props,
                pending = ride.get("pending"),
                categories = heatConfig.get("categories", Immutable.fromJS({ "": {} })),
                usedJudgeAccounts = categoryJudges.valueSeq().flatten(1).toOrderedSet(),
                inputGroups = heatConfig.get("judge_categories_total") ? Immutable.Map({ "":"" }) : categories,
                judgeAccountsById = Immutable.Map(judgeAccounts.map(j => ["" + j.get("id"), j])),
                modifierClass = (modifier || "").split("::")[1],
                interfere = modifier && modifier.match(/Interference/),
                didNotModifier = modifier && modifier.match(/D(N|S)(S|F|Q)/);
            let remainingJudges = judgeAccounts.map(j => "" + j.get("id")).toOrderedSet().subtract(usedJudgeAccounts);
            const judgesPerGroup = inputGroups.map((c, category) => {
                const totalInputs = this.getNumberOfInputs(category);
                let usedJudgeAccounts = categoryJudges.get(category, Immutable.List()).map(id => judgeAccountsById.get(id) || this.props.currentUser).toList();
                const remainingJudgesToUse = totalInputs - usedJudgeAccounts.size;
                remainingJudges.slice(0, remainingJudgesToUse).forEach(id => usedJudgeAccounts = usedJudgeAccounts.push(judgeAccountsById.get(id)));
                remainingJudges = remainingJudges.slice(remainingJudgesToUse);
                return usedJudgeAccounts;
            });

            return <>
                <div className="scrollable-area">
                    <DialogTitle id="edit-ride-dialog-title" className="sticky-dialog-title">
                        {pending ?
                            <T value={rideNumber + 1}>EditRideForm_title_pending</T> :
                            adding ?  <T value={rideNumber + 1}>EditRideForm_title_adding</T> : <T value={rideNumber + 1}>EditRideForm_title_editing</T>
                        }
                    </DialogTitle>

                    <DialogContent className="sticky-dialog-content">
                        <header className="identifier-and-total">
                            <JudgingCompetitorIdentifier name={athleteName} team={teamName} bib={bib} jersey={jersey}/>

                            <div className="total-field">
                                <Typography variant="label1" component="div"><T>Total</T></Typography>
                                <Typography variant="h5" className={classnames("total", "ride", { [modifierClass]: modifierClass, modified: modifierClass })}>
                                    {interfere ? <T>INT</T> : didNotModifier ? <T>{modifierClass}</T> : toFixedOrDash(total, 2)}
                                </Typography>
                            </div>
                        </header>

                        <form onSubmit={handleSubmit(this.submit)}>
                            {heatConfig.get("category_groups") &&
                            <Field name="category" component={chipsSelectInput}
                                   label={<T>Category</T>}
                                   selectOptions={
                                       heatConfig.getIn(["category_groups", "categories"])
                                           .map(category => ({ label: category, value: category }))
                                           .toJS()
                                   }
                                   validate={(this.props.scores?.size > 0 ? this.props.scores : null) && requireCategory}
                            />}

                            <CategoryJudgeList
                                categories={categories.sortBy((config, category) => config.get("order", category)).keySeq()}
                                judgesPerCategory={judgesPerGroup}
                                remainingJudges={remainingJudges}
                                normalizes={this.normalizes}
                                updateTotal={this.updateTotal}
                                addAnotherScore={this.addAnotherScore}
                                autoFocus={this.state.autoFocus}
                                judgeCategoriesTotal={heatConfig.get("judge_categories_total")}
                            />

                            {heatConfig.get("judge_categories_total") && !remainingJudges.isEmpty() &&
                            <Button className="add-score" onClick={() => this.addAnotherScore("")} startIcon={<Add/>}>
                                <T>Add another score</T>
                            </Button>}

                            <section className="modifiers">
                                <Field name="modifier" component={radioInput}
                                       label={<T>Modifiers</T>}
                                       onChange={this.updatedModifier}
                                       radioOptions={
                                           getModifiers(heatConfig)
                                               .map(modifier =>
                                                   ({ label: <T>{modifierItemProps[modifier].name}</T>, value: `Modifiers::${modifier}` }))
                                               .push({ label: <T>None</T>, value: "" }).toArray()
                                       }/>
                            </section>
                        </form>
                    </DialogContent>
                </div>

                <DialogActions className="sticky-dialog-actions">
                    <LoadingButton className="delete-ride left" action={this.deleteRide} startIcon={<Delete/>}>
                        <T>Delete</T>
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleClose}>
                        <T>Cancel</T>
                    </Button>

                    <LoadingButton variant="contained" action={handleSubmit(this.submit)} type="submit"
                                   disabled={!pending && pristine} color="primary" startIcon={pending && <Check/>}>
                        {pending
                            ? <T>Approve</T>
                            : <T>Save</T>}
                    </LoadingButton>
                </DialogActions>
            </>;
        };
    }));

const CategoryJudgeList = ({ categories, judgesPerCategory, remainingJudges, normalizes, updateTotal, addAnotherScore, autoFocus, judgeCategoriesTotal }) =>
    categories.map((category, c) => {
        const orderedJudges = judgesPerCategory.get(judgeCategoriesTotal ? "" : category);

        return (
            <div key={category} className="category">
                {category && <Typography variant="h7" component="p" className="category-title">{category}</Typography>}

                <section>
                    {orderedJudges.map((judge, i) =>
                        <Field key={`${judge.get("id")}-${i}`} name={`scores.${judge.get("id")}${category ? `.${category}` : ""}`}
                               component={plainInput}
                               type="number" pattern="[0-9]*" step="any" autoComplete="off"
                               autoFocus={autoFocus && i === orderedJudges.size - 1}
                               label={judge.get("name")} validate={decimalOrEmpty}
                               onChange={(e, value) => updateTotal(judge.get("id"), category, value)}
                               normalize={normalizes.get(category)}/>
                    )}
                </section>

                {!judgeCategoriesTotal && !remainingJudges.isEmpty() &&
                <div>
                    <Button className="add-score" onClick={() => addAnotherScore(category)} startIcon={<Add/>}>
                        <T>Add another score</T>
                    </Button>
                </div>}
            </div>
        );
    });
