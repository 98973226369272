import { forwardRef, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import { DateRange } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";

import { useBaseInputStyles } from "./baseInputStyles";
import { ClearButton } from "./ClearButton";
import classnames from "classnames";
import { IconButton } from "@mui/material";
import { useIsMobile } from "hooks/useIsMobile";
import { useMergeRefs } from "../../../../hooks/useMergeRefs";

const useDateStyles = makeStyles(theme => ({
    wrapper: {
        position: "relative",
        "& .react-datepicker-wrapper, & .react-datepicker__input-container, & .react-datepicker__input-container input ": {
            width: "100%"
        },
        "& .react-datepicker-popper": {
            zIndex: 10
        },
        "& .react-datepicker select": {
            WebkitAppearance: "menulist-button",
            width: "100%",
            minHeight: theme.spacing(5),
            height: theme.spacing(5),
            marginTop: theme.spacing(0.5),
            padding: theme.spacing(0, 0.5),
            fontSize: theme.typography.body1.fontSize,
            borderRadius: theme.shape.borderRadius,
            borderColor: theme.palette.input.border
        },
        "& .react-datepicker__view-calendar-icon input": {
            padding: theme.spacing(1)
        },
        "& .react-datepicker__input-container .react-datepicker__calendar-icon": {
            padding: 0,
            width: "auto",
            height: "auto",
            top: 4,
            right: 4,
            bottom: 4
        },
        "& .react-datepicker": {
            border: 0,
            boxShadow: theme.shadows[8],
            borderRadius: theme.shape.borderRadius * 1.5,
            overflow: "hidden",
            [theme.breakpoints.down("sm")]: {
                display: "flex",
                flexDirection: "column",
                maxWidth: theme.spacing(42)
            }
        },
        "& .react-datepicker__month-container": {
            padding: theme.spacing(1.5),
        },
        "& .react-datepicker__month": {
            backgroundColor: theme.palette.background.low,
            borderRadius: theme.shape.borderRadius,
            padding: theme.spacing(0.75, 0.5, 0.5, 0.5)
        },
        "& .react-datepicker__header.react-datepicker__header--time": {
            [theme.breakpoints.up("sm")]: {
                padding: theme.spacing(3.5, 1.5, 1.5, 1.5),
            }
        },
        "& .react-datepicker__time-container": {
            width: "fit-content",
            borderColor: theme.palette.border.light,
            [theme.breakpoints.down("sm")]: {
                border: 0,
                width: "100%",
            },
            "& .react-datepicker__time .react-datepicker__time-box": {
                width: "auto",
                display: "flex"
            }
        },
        "& .react-datepicker-time__header": {
            [theme.breakpoints.down("sm")]: {
                marginBottom: theme.spacing(1)
            }
        },
        "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list": {
            display: "flex",
            flexDirection: "column",
            [theme.breakpoints.down("sm")]: {
                margin: theme.spacing(0, 2),
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr 1fr", // fallback for browsers incompatible with minmax
                // eslint-disable-next-line no-dupe-keys
                gridTemplateColumns: "repeat(auto-fit, minmax(70px, 1fr))", // this automates the number of columns, less when time is formatted with AM and PM and more when it's only digits
                height: `${theme.spacing(23)} !important`,
                marginBottom: theme.spacing(1),
                maskImage: "linear-gradient(to bottom, transparent, black 5%, black 95%, transparent)",
            }
        },
        "& .react-datepicker-popper .react-datepicker__triangle": {
            stroke: "none",
        },
        "& .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle": {
            fill: theme.palette.background.paper,
            color: theme.palette.background.paper
        },
        "& .react-datepicker__header": {
            backgroundColor: theme.palette.background.paper,
            borderBottom: 0,
            padding: 0,
        },
        "& .react-datepicker__current-month": {
            display: "none"
        },
        "& .react-datepicker__header__dropdown": {
            marginBottom: theme.spacing(0.5)
        },
        "& .react-datepicker__navigation": {
            top: theme.spacing(3)
        },
        "& .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button)": {
            right: "auto",
            left: 278, // inline with edge of calendar
            [theme.breakpoints.down("sm")]: {
                right: "auto",
                left: 292, // inline with edge of calendar
            },
        },
        "& .react-datepicker__navigation-icon::before": {
            borderColor: theme.palette.text.secondary
        },
        "& .react-datepicker__month-dropdown-container--select, & .react-datepicker__year-dropdown-container--select": {
            margin: theme.spacing(0, 1)
        },
        "& .react-datepicker__month-select": {
            maxWidth: theme.spacing(18),
        },
        "& .react-datepicker__day.react-datepicker__day": {
            "&, &:not([aria-disabled=true])": {
                borderRadius: "50%",
            },
            width: theme.spacing(4),
            lineHeight: theme.spacing(4),
            fontSize: theme.typography.body2.fontSize,
            margin: theme.spacing(0.25, 0.5),
            "&--outside-month": {
                color: theme.palette.text.secondary,
                opacity: 0.6
            }
        },
        "& .react-datepicker__day-name": {
            color: theme.palette.text.secondary,
            width: theme.spacing(4),
            lineHeight: theme.spacing(4),
            fontSize: theme.typography.body2.fontSize,
            margin: theme.spacing(0.25, 0.5),
        },
        "& .react-datepicker__time-list-item": {
            borderRadius: theme.spacing(12.5),
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: theme.typography.body1.fontSize,
            [theme.breakpoints.down("sm")]: {
                margin: theme.spacing(1),
                fontSize: theme.typography.body2.fontSize,
            }
        },
        "& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item": {
            padding: theme.spacing(1, 1.5),
            margin: theme.spacing(0.75, 1),
            height: "auto"
        },
        "& .react-datepicker__day, & .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list .react-datepicker__time-list-item": {
            "&:hover": {
                backgroundColor: theme.palette.state.action
            },
            "&--keyboard-selected": {
                background: "none",
            },
            "&:focus-visible": {
                backgroundColor: theme.palette.background.high,
                outline: `3px solid ${theme.palette.input.focus}`,
                outlineOffset: "2px",
            },
            "&--today": {
                background: "none",
            },
            "&--selected, &--selected:not([aria-disabled=true])": {
                fontWeight: "inherit",
                "&, &:hover, &:focus-visible": {
                    color: theme.palette.primary.contrastText,
                    backgroundColor: theme.palette.primary.main,
                }
            },
            "&--selected[aria-disabled=true]": {
                color: theme.palette.background.paper
            },
            "&--disabled": {
                opacity: 0.6,
                color: theme.palette.text.disabled,
                pointerEvents: "none"
            }
        }
    }
}));

export const CustomDateInputField = forwardRef(({ onClick, inputRef, ...rest }, ref) => {
    const combinedRef = useMergeRefs(ref, inputRef);
    return <input {...rest} readOnly onClick={onClick} ref={combinedRef}/>;
});

export const DateField = ({ field, input: { ref, ...input }, hasError }) => {
    const baseClasses = useBaseInputStyles();

    return (
        <DatePicker
            id={field.inputProps.id}
            className={classnames(baseClasses.baseInput, { error: hasError })}
            {...input}
            {...field.inputProps}
            selected={input.value}
            popperPlacement="top-start"
            customInput={<CustomDateInputField inputRef={field.inputRef} className={field.inputProps?.className} />}
            hideClearButton={field.hideClearButton}
            input={input}
        />
    );
};

export const DatePicker = ({ wrapperClassName, hideClearButton, input, ...rest }) => {
    const classes = useDateStyles();
    const ref = useRef();
    const isMobile = useIsMobile();
    const dateFormat = rest.showTimeSelect ? "d MMM yyyy | HH:mm" : "d MMM yyyy";

    useEffect(() => {
        if (rest.showTimeSelect && isMobile) {
            // hacky hack to move selected time into view, needed when forcing different height on time selector
            setTimeout(() => {
                ref.current?.getElementsByClassName("react-datepicker__time-list-item--selected")?.[0]?.scrollIntoView?.({ block: "center", inline: "nearest", behavior: "instant" });
            }, 0);
        }
    }, []);

    return (
        <div className={classnames(classes.wrapper, wrapperClassName)} ref={ref}>
            <ReactDatePicker
                icon={<div><IconButton size="small"><DateRange /></IconButton></div>}
                fixedHeight
                showMonthDropdown
                showYearDropdown
                showIcon
                toggleCalendarOnIconClick
                dropdownMode="select"
                dateFormat={dateFormat}
                {...rest} />
            {!hideClearButton && <ClearButton input={input}/>}
        </div>
    );
};
