import { Field } from "redux-form/immutable";

import { checkboxInput, creatableSelect, radioInput, FormField } from "../../../forms";
import { jerseySelectOptions } from "../../../forms/options";
import { T } from "../../../util/t";
import { useRef } from "react";
import { Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles({
    eventLayoutType: {
        ".formField": {
            marginBottom: 0
        }
    },
    group: {
        ".formField": {
            marginBottom: 0
        }
    }
});


export const EventAdvancedForm = ({ localization, event, formState, location }) => {
    const jerseyOptions = useRef(jerseySelectOptions(localization));
    const classes = useStyles();

    const canRunMultiCourseSchedule = event.getIn(["organisation", "multi_course_schedule_enabled"]);
    const hideScheduledTime = <Field name="hide_scheduled_time" component={checkboxInput} label={<T>Hide schedule from public event page</T>}/>;

    return (
        <>
            {canRunMultiCourseSchedule && <>
                <FormField className={classes.eventLayoutType}>
                    <Field name="config.disable_schedule" component={radioInput} yesNo
                           label={<T>Event layout type:</T>}
                           parse={v => !!parseInt(v)}
                           radioOptions={[
                               { label: <T>Multi course schedule</T>, value: 1 },
                               { label: <T>Single course schedule</T>, value: 0 }
                           ]}/>
                    <Typography variant="caption">
                        <T>event_layout_type</T>
                    </Typography>
                </FormField>

                {!formState.getIn(["config", "disable_schedule"]) && hideScheduledTime}
            </>}

            <Field name="jersey_order" component={creatableSelect} className="small-multi"
                   label={<T>Jersey order:</T>}
                   selectOptions={jerseyOptions.current}/>

            <Field name="hide_finals" component={checkboxInput}
                   label={<T>Hide results for finals</T>}/>

            {!canRunMultiCourseSchedule && hideScheduledTime}

            <Field name="hide_entries" component={checkboxInput}
                   label={<T>Hide entry list and seeds from public</T>}/>
        </>
    );
};
