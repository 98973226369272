import { Stack, Typography } from "@mui/material";
import { PaddedPage } from "components/layout/page/PaddedPage";
import { Fake } from "components/layout/skeleton/Fake";

export const EventMediaPageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <PaddedPage>
            <Typography variant="h4" component="h1" marginTop={1} marginBottom={4} display="flex" alignItems="center">
                <Fake size={40} />
            </Typography>
            <Stack alignItems="center" marginX="auto" textAlign="center" paddingX={4}>
                <Typography maxWidth={600} width="100%" height={[250, 500]} className="fake" />
                <Typography variant="label1" marginTop={4} marginBottom={2}><Fake size={80} /></Typography>
                <Typography variant="body1" marginBottom={4}><Fake size={500} /><br /><Fake size={100} /></Typography>
                <Fake size={40} />
                <Typography variant="body2" color="text.secondary" marginTop={4}><Fake size={120} /></Typography>
            </Stack>
        </PaddedPage>
    </div>
);
