import { ParentSeriesDivisionMapping } from "./ParentSeriesDivisionMapping";

type DivisionParentSeriesMappingProps = {
    index: number;
    parentDivision: any;
    parentSeries: any;
    organisationId: number;
}
export const DivisionParentSeriesMapping: React.FC<DivisionParentSeriesMappingProps> = ({ index, parentDivision, parentSeries, organisationId }) => {
    const mappedDivisionFieldName = `division_to_seasons_attributes[${index}]`;
    const pointsAllocationFieldName = `division_to_seasons_attributes[${index}].point_allocation_id`;

    return (
        <ParentSeriesDivisionMapping labelSuffix="" mappedDivisionFieldName={mappedDivisionFieldName} pointsAllocationFieldName={pointsAllocationFieldName} parentDivision={parentDivision} parentSeries={parentSeries} organisationId={organisationId} />
    );
};