import { T } from "../../util/t";
import { Field } from "redux-form/immutable";
import { FormField, selectInput } from "../../forms";
import { useTranslate } from "../../providers/LocalizationProvider";
import { useQuery } from "@apollo/client";
import { GET_ORGANISATION_WITH_POINT_ALLOCATIONS } from "../../../graphql/organisation";
import Immutable from "immutable";

const parseDivisionAllocation = eventDivisionPointAllocation => value => Immutable.fromJS({
    id: eventDivisionPointAllocation.get("id"),
    season_id: eventDivisionPointAllocation.get("season_id"),
    event_division_id: eventDivisionPointAllocation.get("event_division_id"),
    point_allocation_id: value,
    _destroy: !value
});
const formatDivisionAllocation = value => value && value.get("point_allocation_id");

export const EventDivisionPointAllocationField = ({ eventDivisionPointAllocation, series, index }) => {
    const { data } = useQuery(GET_ORGANISATION_WITH_POINT_ALLOCATIONS, { variables: { id: series.get("organisation_id") } }),
        pointAllocations = data?.organisation?.federationPointAllocations || [];

    const seriesPointAllocation = pointAllocations.find(({ id }) => `${id}` === `${series?.get("point_allocation_id")}`),
        liveHeatsPointAllocationDefault = useTranslate("Liveheats default"),
        seriesPointAllocationDefault = useTranslate("series default"),
        pointAllocationDefault = seriesPointAllocation ? `${seriesPointAllocation?.name} (${seriesPointAllocationDefault})` : liveHeatsPointAllocationDefault;

    const options = [
        { value: "", label: pointAllocationDefault },
        ...pointAllocations.map(({ id, name }) => ({ value: id, label: name }))
    ];

    // eslint-disable-next-line eqeqeq
    if (eventDivisionPointAllocation?.get("point_allocation_id") && !options.find(({ value }) => value == eventDivisionPointAllocation.get("point_allocation_id")))
        options.push({ value: eventDivisionPointAllocation.get("point_allocation_id"), label: eventDivisionPointAllocation.get("point_allocation_name") });

    return <FormField>
        <label><T>{series.get("name")}</T></label>
        <br/>

        <T>Points allocation:</T>
        <Field name={`event_division_point_allocations_attributes[${index}]`}
               parse={parseDivisionAllocation(eventDivisionPointAllocation)}
               format={formatDivisionAllocation}
               component={selectInput}
               selectOptions={options} />
    </FormField>;
};
