import { useMeta } from "hooks/useMeta";
import { T } from "../../util/t";

export const PrivacyPolicy = () => {
    useMeta({
        title: "Liveheats Privacy Policy",
        description: "Liveheats is a leading action sport event management platform used by local clubs, regional, state, and national federations around the world."
    });
  
    return <div id="privacy_policy" className="wrapper">
        <T>PrivacyPolicy_LocalizedComponent</T>
    </div>;
};
