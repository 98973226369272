import { ParentSeriesDivisionMapping } from "./ParentSeriesDivisionMapping";

type ChildDivisionParentSeriesMappingProps = {
    index: number;
    eventDivisionIndex: number;
    eventDivision: any;
    parentDivision: any;
    parentSeries: any;
    organisationId: number;
}
export const ChildDivisionParentSeriesMapping: React.FC<ChildDivisionParentSeriesMappingProps> = ({ index, eventDivisionIndex,  eventDivision, parentDivision, parentSeries, organisationId }) => {
    const mappedDivisionFieldName = `event_divisions_attributes[${eventDivisionIndex}].division_to_seasons_attributes[${index}]`;
    const pointsAllocationFieldName = `event_divisions_attributes[${eventDivisionIndex}].division_to_seasons_attributes[${index}].point_allocation_id`;
    const labelSuffix = ` - ${eventDivision.getIn(["division", "name"])}`;

    return (
        <ParentSeriesDivisionMapping labelSuffix={labelSuffix}
                                     mappedDivisionFieldName={mappedDivisionFieldName}
                                     pointsAllocationFieldName={pointsAllocationFieldName}
                                     parentDivision={parentDivision}
                                     parentSeries={parentSeries}
                                     organisationId={organisationId} />
    );
};