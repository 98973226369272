import { useCallback, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { IconButton, Link, LinkProps } from "@mui/material";

import { CloseDialog, useDialogState } from "../../../hooks/useDialogState";
import { GET_HEAT } from "../../../graphql/heat";
import { useRefocusEffect } from "../../../hooks/useRefocusEffect";
import { HeatBreakdownSkeleton } from "./HeatBreakdownSkeleton";
import { HeatBreakdown } from "./HeatBreakdown";
import { LoadingLeaderboardModal } from "../leaderboard/LeaderboardModal";
import { DesktopOrMobileModal } from "../modal/DesktopOrMobileModal";
import { Heat } from "graphql/generated/types";
import { makeStyles } from "components/providers/makeStyles";
import { Close } from "@mui/icons-material";

export type HeatModalProps = {
    heat?: Heat;
    heatsInRound?: number;
    open: boolean;
    closeModal: CloseDialog;
}

export const HeatModalWithTrigger: React.FCWithChildren<Pick<HeatModalProps, "heat" | "heatsInRound">> = ({ heat, heatsInRound, children }) => {
    const [open, openModal, closeModal] = useDialogState();

    return (
        <>
            <Link underline="none" color="inherit" variant="inherit" onClick={openModal}>{children}</Link>
            <HeatModal open={open} heat={heat} heatsInRound={heatsInRound} closeModal={closeModal}/>
        </>
    );
};

export const LoadingModalWithTrigger: React.FCWithChildren<{
    heat: Heat
    linkVariant?: LinkProps["variant"]
    isLeaderboard?: boolean
    onClose?: () => void
}> = ({ heat, linkVariant = "inherit", isLeaderboard, onClose, children }) => {
    const [open, openModal, closeModal] = useDialogState();
    const ModalComponent = (heat.config?.runBased || isLeaderboard) ? LoadingLeaderboardModal : LoadingHeatModal;

    const handleClose = useCallback<CloseDialog>((e, reason) => {
        onClose?.();
        closeModal(e, reason);
    }, [onClose, closeModal]);

    return (
        <>
            <Link underline="none" color="inherit" variant={linkVariant} onClick={openModal}>{children}</Link>
            <ModalComponent open={open} heat={heat} closeModal={handleClose}/>
        </>
    );
};

export const LoadingHeatModal: React.FC<Omit<HeatModalProps, "heatsInRound">> = ({ heat, open, closeModal }) => {
    const [getHeat, { data, refetch }] = useLazyQuery(GET_HEAT, { fetchPolicy: "cache-and-network", nextFetchPolicy: "cache-first" });
    const heatId = heat?.id;

    useRefocusEffect(refetch, open);

    useEffect(() => {
        if (heatId && open) {
            getHeat({ variables: { id: heatId } });
        }
    }, [open, heatId, getHeat]);

    return <HeatModal open={open} heat={data?.heat} heatsInRound={2} closeModal={closeModal}/>;
};

export const HeatModal: React.FC<HeatModalProps> = ({ open, closeModal, heat, heatsInRound }) =>
    <DesktopOrMobileModal open={open} closeModal={closeModal}>
        {heat
            ? <HeatBreakdown heat={heat} heatsInRound={heatsInRound} closeModal={closeModal}/>
            : <HeatBreakdownSkeleton/>}
    </DesktopOrMobileModal>;

const useButtonStyles = makeStyles(theme => ({
    closeButton: {
        zIndex: 11,
        padding: 0,
        marginLeft: "auto",
        "&:hover": {
            backgroundColor: "transparent",
        },
        position: "absolute",
        top: theme.spacing(1.5),
        right: theme.spacing(2),
        borderRadius: 0,
    }
}));
    
export const CloseButton: React.FC<{ close: CloseDialog }> = ({ close }) => {
    const classes = useButtonStyles();
    
    return (
        <IconButton color="inherit" className={classes.closeButton} onClick={e => close(e, "escapeKeyDown")} aria-label="close">
            <Close/>
        </IconButton>
    );
};
