import { useCurrentUser } from "components/providers/CurrentUserProvider";
import { useEffect } from "react";

const useUserGuidingContainer = () => {
    const { currentUser } = useCurrentUser();
    const isUserRoleDirector = currentUser?.role === "director";
    const isUserGuidingLoaded = !!window.userGuiding;

    useEffect(() => {
        if (!isUserRoleDirector) return;

        if(!isUserGuidingLoaded){
            const script = document.createElement("script");
            script.innerHTML =
              `(function(g,u,i,d,e,s){
                g[e]=g[e]||[];
                var f=u.getElementsByTagName(i)[0];
                var k=u.createElement(i);
                k.async=true;
                k.src="https://static.userguiding.com/media/user-guiding-"+s+"-embedded.js";
                f.parentNode.insertBefore(k,f);
                if(g[d]) return;
                var ug=g[d]={q:[]};
                ug.c=function(n){ return function(){ ug.q.push([n,arguments]) }; };
                var m=["previewGuide","finishPreview","track","identify","hideChecklist","launchChecklist"];
                for(var j=0;j<m.length;j+=1){ ug[m[j]]=ug.c(m[j]); }
              })(window,document,"script","userGuiding","userGuidingLayer","PHA98632E31ID");
            `;
            const head = document.getElementsByTagName("head")[0];
            head.appendChild(script);
        }

        window.userGuiding.identify(currentUser.id, {
            created_at: currentUser.created_at,
        });
    }, [isUserRoleDirector]);
};

export default useUserGuidingContainer;
