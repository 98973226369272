import { Stack, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { gql } from "@apollo/client";
import { OptimizedImage } from "components/elements/OptimizedImage";
import { SponsorConfig } from "../../../graphql/generated/types";

export const SponsoredContentFragment = gql`
    fragment SponsoredContent on SponsoredContent {
      id
      sponsor {
        id
        config {
          name
          text
          image
          url
        }
      }
    }
`;

export const SponsoredContentsFragment = gql`
    fragment SponsoredContents on Event {
        id
        sponsoredContents {
            ...SponsoredContent    
        }
    }
    ${SponsoredContentFragment}
`;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: "fit-content",
        flexWrap: "wrap",
        alignItems: "center",
        gap: theme.spacing(0.5),
        wordBreak: "break-word",
        padding: theme.spacing(0.5),
    },
    image: {
        maxWidth: "110px",
        maxHeight: "32px",
        objectFit: "contain",
        objectPosition: "left",
    }
}));

export const SponsorContent: React.FCWithChildren<Pick<SponsorConfig, "text" | "image">> = ({
    text,
    image,
    children
}) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography variant="body2" color="text.secondary">
                {text}
            </Typography>
            <OptimizedImage src={image} height={32} className={classes.image} alt="sponsor"/>
            {children}
        </div>
    );
};

export type SponsorsContainerProps = {
    direction?: React.ComponentProps<typeof Stack>["direction"];
};

export const SponsorsContainer: React.FCWithChildren<SponsorsContainerProps> = ({ direction="column", children }) => (
    <Stack direction={direction} gap={1} paddingRight={1} marginTop={1} flexWrap="wrap">
        {children}
    </Stack>
);
