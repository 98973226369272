import React, { createContext, useContext, useState, useCallback } from "react";
import dayjs from "dayjs";
import translations from "../../reducers/localization/translations";
import { hostToLanguage } from "../../reducers/localization/languageUtil";
import "dayjs/locale/ja";
import "dayjs/locale/pt";
import "dayjs/locale/es";
import "dayjs/locale/fr";

const language = hostToLanguage();
dayjs.locale(language);

export interface LocalizationContext {
    translate?: (text: string, props?: Record<string, any>) => string;
    setLanguage?: (value: string) => void;
}

const LocalizationContext = createContext<LocalizationContext>({});
LocalizationContext.displayName = "Translation";

export const useLocalizationContext = () => useContext(LocalizationContext);

export const useTranslate = (text: any, props?: Record<string, any>) => {
    const { translate } = useLocalizationContext();
    return translate ? translate(text, props) : text;
};

export const LocalizationProvider: React.FCWithChildren = ({ children }) => {
    const [selectedLanguage, setLanguage] = useState(language);

    const translateText = useCallback((text: string, props?: Record<string, any>) => {
        const textTranslations: Record<string, string | ((props: Record<string, any>) => string)> =
            (text && translations[text as keyof typeof translations]) || {};
        const translatedText = textTranslations[selectedLanguage] || textTranslations["en"];

        return translatedText != null ? (typeof translatedText === "function" ? translatedText(props || {}) : translatedText) : text;
    }, [selectedLanguage]);

    return (
        <LocalizationContext.Provider value={{ translate: translateText, setLanguage }}>
            {children}
        </LocalizationContext.Provider>
    );
};
