import { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form/lib/immutable";

import * as UserActions from "../../actions/user";
import * as NotificationActions from "../../actions/notification";

import { plainInput } from "../forms";
import { LoadingButton } from "../actions/loadingButton";
import { AccountFormPage } from "./common";
import { T } from "../util/t";
import { Notification } from "../layout/notifications/Notification";
import { useMeta } from "hooks/useMeta";

export const RecoverPasswordForm = connect()(reduxForm({ form: "recoverPassword", touchOnChange: true })(
    class RecoverPasswordForm extends Component {
        static propTypes = {
            location: PropTypes.object,
            history: PropTypes.object,
            dispatch: PropTypes.func.isRequired,
            handleSubmit: PropTypes.func.isRequired,
            pristine: PropTypes.bool.isRequired,
        };

        state = {};

        submit = (values) => {
            const { dispatch, location, history } = this.props,
                match = location.search.match(/reset_password_token=([^&]+)/),
                token = match && match[1];

            if (values.get("password") !== values.get("password_confirmation")) {
                const error = "These passwords don't match";
                throw new SubmissionError({ password: error, password_confirmation: error });
            }

            return dispatch(UserActions.resetPassword(values.get("password"), values.get("password_confirmation"), token))
                .then(() => {
                    dispatch(NotificationActions.success("Your password has been successfully reset, you can now login with your new password."));
                    return history.push("/login");
                })
                .catch(e =>
                    this.setState({
                        error: (e.response && e.response.status === 401 && "invalid_token") || "Oops, something went wrong!"
                    }));
        };

        render = () =>
            <>
                <form onSubmit={this.props.handleSubmit(this.submit)}>
                    {this.state.error && <Notification type="error" className="margin-bottom" text={this.state.error}/>}

                    <Field name="password"
                           label={<T>Password:</T>}
                           type="password"
                           autoComplete="new-password"
                           component={plainInput}/>

                    <Field name="password_confirmation"
                           label={<T>Password confirmation:</T>}
                           type="password"
                           autoComplete="new-password"
                           component={plainInput}/>

                    <div className="actions">
                        <LoadingButton variant="contained" action={this.props.handleSubmit(this.submit)}
                                       disabled={this.props.pristine}
                                       type="submit"
                                       color="primary">
                            <T>Reset password</T>
                        </LoadingButton>
                    </div>
                </form>
            </>;
    }));

export const RecoverPasswordPage = (props) => {
    useMeta({ 
        title: "Reset your Liveheats password",
        description: "Liveheats is a leading action sport event management platform used by local clubs, regional, state, and national federations around the world.",
    });

    return <AccountFormPage title="Reset your password">
        <RecoverPasswordForm {...props} />
    </AccountFormPage>;
};
