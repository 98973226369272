import { PureComponent } from "react";
import Immutable from "immutable";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import classnames from "classnames";

import * as JudgeActions from "../../../actions/judge";

import { resultsByAthletePosition } from "../heats/card";
import { T } from "../../util/t";
import { Timer } from "../../timer";
import { MultiCategoryDialog } from "./multiCategoryDialog";
import { JudgeCardTable } from "./judgeCard";
import { ModifierMenu } from "./ModifierMenu";
import { ScoreRenderer } from "./ScoreRenderer";
import { CategoryScoreDrawer } from "./CategoryScoreDrawer";
import { NotStartedHeatMessage } from "components/layout/judging/NotStartedHeatMessage";
import { ScoreForm } from "./ScoreForm";
import { Add } from "@mui/icons-material";


export class Ride extends PureComponent {
    state = {
        edit: false,
        currentlyDebouncingSubmit: null,
    };

    onClick = () => {
        const { onClick, athleteHeatId, teamPosition, parentAthleteId, athleteId, rideIndex, ride } = this.props;
        !onClick(athleteHeatId, teamPosition, parentAthleteId, athleteId, rideIndex, ride) && this.setState({ edit: true });
    };

    closeEdit = () => this.setState({ edit: false });
    setDebouncedSubmit = (fn) => this.setState({ currentlyDebouncingSubmit: fn });

    render = () => {
        const { className = "", colSpan, ride, category, judgeId, judgingScale, judgingDecimals, eventId, heatId, dispatchHeatId, athleteHeatId, teamPosition, parentAthleteId, athleteId, athleteName, teamName, jersey, bib, rideIndex, dispatchRideIndex, heatConfig } = this.props,
            { edit } = this.state,
            scorePath = ["scores", "" + judgeId],
            score = ride.getIn(category ? scorePath.concat(category) : scorePath),
            missingScore = !(score != null) && !(ride.get("scores") || Immutable.Map()).delete("" + judgeId).isEmpty(),
            modifier = ride.getIn(["modifier", "type"], "").split("::")[1],
            isEditLocked = heatConfig.get("requires_approval") && (score || modifier) && !ride.get("pending"),
            editMode = edit && !isEditLocked;

        return (
            <td className={classnames(className, modifier && `modified ${modifier}`, isEditLocked && "edit-locked")} onClick={isEditLocked ? undefined : this.onClick} colSpan={colSpan || 1}>
                {!editMode && (missingScore && <div className="missing-score"><T>missing score</T></div>)}
                {heatConfig.get("judge_categories_total") ?
                    <MultiCategoryDialog
                        open={editMode}
                        eventId={eventId}
                        heatId={heatId}
                        dispatchHeatId={dispatchHeatId}
                        rideIndex={rideIndex}
                        dispatchRideIndex={dispatchRideIndex}
                        judgeId={judgeId}
                        athleteHeatId={athleteHeatId}
                        teamPosition={teamPosition}
                        athleteId={athleteId}
                        parentAthleteId={parentAthleteId}
                        athleteName={athleteName}
                        teamName={teamName}
                        jersey={jersey}
                        bib={bib}
                        scores={ride.getIn(scorePath)}
                        modifier={modifier}
                        heatConfig={heatConfig}
                        handleClose={this.closeEdit}
                        isEditLocked={isEditLocked}
                    /> :
                    heatConfig.get("category_groups") ?
                        <CategoryScoreDrawer
                            open={editMode}
                            eventId={eventId}
                            heatId={heatId}
                            dispatchHeatId={dispatchHeatId}
                            rideIndex={rideIndex}
                            dispatchRideIndex={dispatchRideIndex}
                            judgeId={judgeId}
                            athleteHeatId={athleteHeatId}
                            teamPosition={teamPosition}
                            athleteId={athleteId}
                            parentAthleteId={parentAthleteId}
                            athleteName={athleteName}
                            teamName={teamName}
                            jersey={jersey}
                            bib={bib}
                            score={score}
                            modifier={modifier}
                            rideCategory={ride.get("category")}
                            heatConfig={heatConfig}
                            handleClose={this.closeEdit}
                            setDebouncedSubmit={this.setDebouncedSubmit}
                            currentlyDebouncingSubmit={this.state.currentlyDebouncingSubmit}
                            isEditLocked={isEditLocked}
                        /> :
                        <>
                            {!editMode && ((score != null || modifier) ?
                                <ScoreRenderer isEditLocked={isEditLocked} score={score} /> :
                                <IconButton>
                                    <Add />
                                </IconButton>
                            )}
                            {editMode && <ScoreForm postSubmit={this.closeEdit}
                                                    score={score}
                                                    category={category}
                                                    judgingScale={judgingScale}
                                                    judgingDecimals={judgingDecimals}
                                                    judgeId={judgeId}
                                                    eventId={eventId}
                                                    heatId={heatId}
                                                    dispatchHeatId={dispatchHeatId}
                                                    athleteHeatId={athleteHeatId}
                                                    teamPosition={teamPosition}
                                                    parentAthleteId={parentAthleteId}
                                                    athleteId={athleteId}
                                                    rideIndex={rideIndex}
                                                    dispatchRideIndex={dispatchRideIndex}/>}
                        </>
                }
            </td>
        );
    };
}

export class DumbJudgeSheet extends PureComponent {
    MISSING_RIDE_DELAY = 30000;
    state = { missingRides: Immutable.Map(), };

    addModifier = (modifier) => this.setState({ modifier });

    clickRide = (athleteHeatId, teamPosition, parentAthleteId, athleteId, rideIndex, ride) => {
        const { dispatch, eventId, heat } = this.props,
            runs = heat.get("runs", Immutable.List()),
            rideModifier = ride.get("modifier"),
            heatId = heat.get("id"),
            dispatchHeatId = ride.get("heat_id", runs.get(rideIndex, heatId)),
            dispatchRideIndex = ride.get("ride_index", runs.isEmpty() ? rideIndex : 0),
            { modifier } = this.state;

        this.setState({ modifier: null });
        return modifier && (modifier === "delete" ?
            (rideModifier && dispatch(JudgeActions.removeModifier(eventId, heat.get("id"), dispatchHeatId, parentAthleteId, athleteId, rideIndex, rideModifier.get("id"), rideModifier.get("type").split("::")[1]))) :
            (!rideModifier && dispatch(JudgeActions.addModifier(eventId, heat.get("id"), dispatchHeatId, athleteHeatId, teamPosition, parentAthleteId, athleteId, rideIndex, dispatchRideIndex, modifier))));
    };

    removeMissingRide = (athleteId, ride) =>
        this.setState({ missingRides: this.state.missingRides.setIn([athleteId, ride], false) });

    getMissingRides = (missingRides, getMissingValue) => {
        const { heat, judgeId } = this.props;

        heat.get("result").forEach(athleteResult => athleteResult.get("rides").forEach((rides, athleteId) => rides.forEach((ride, i) => {
            if (!(missingRides.getIn([athleteId, i]) != null) && !(ride.get("scores") || Immutable.Map()).isEmpty() && !(ride.getIn(["scores", "" + judgeId]) != null)) {
                missingRides = missingRides.setIn([athleteId, i], i === rides.size - 1 ? getMissingValue(athleteId, i) : false);
            }
            if (ride.get("modifier")) {
                missingRides = missingRides
                    .setIn([athleteId, i], false)
                    .update(athleteId, athleteMissingRides => athleteMissingRides.map((ride, rideIndex) => {
                        if (rideIndex <= i && ride) {
                            clearTimeout(ride);
                            return false;
                        }
                        return ride;
                    }));
            }
        })));

        return missingRides;
    };

    resetState = () =>
        this.setState({
            modifier: null,
            missingRides: this.getMissingRides(Immutable.Map(), () => false),
        });

    componentDidMount = () => this.resetState();

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { heat, judgeId } =  this.props;
        if (prevProps.heat !== heat) {
            if (prevProps.heat.get("id") !== heat.get("id")) {
                this.resetState();
            } else {
                let { missingRides } = this.state;

                missingRides = missingRides.mapEntries(([athleteId, athleteRides]) =>
                    [athleteId, athleteRides.filter((v, ride) => !heat.get("result").some(r => r.getIn(["rides", athleteId, ride, "scores", "" + judgeId]) != null))]);

                this.setState({
                    missingRides: this.getMissingRides(missingRides,
                        (athleteId, ride) => setTimeout(() => this.removeMissingRide(athleteId, ride), this.MISSING_RIDE_DELAY))
                });
            }
        }
    }

    componentWillUnmount() {
        this.state.missingRides.forEach(athleteRides => athleteRides.forEach(timeout => clearTimeout(timeout)));
    }

    jerseyCellRenderer = () => <th className="jersey fixed">#</th>;

    athleteCellNameRenderer = ({ spot }) => !this.props.hideNames &&
        <th className="name notranslate">{spot && spot.getIn(["athlete", "name"])}</th>;

    rideRenderer = ({ className, colSpan, athleteHeatId, teamPosition, athleteId, parentAthleteId, index, ride = Immutable.Map(), heat,  jersey, athlete }) => {
        const runs = heat.get("runs", Immutable.List()),
            heatConfig = heat.get("config"),
            judgingScale = heatConfig.getIn(["categories", this.props.category, "judging_scale"], heatConfig.get("max_ride_score")),
            judgingDecimals = heatConfig.getIn(["categories", this.props.category, "judging_decimals"]);

        return <Ride
            className={className}
            colSpan={colSpan}
            onClick={this.clickRide}
            eventId={this.props.eventId}
            ride={ride}
            judgingScale={judgingScale}
            judgingDecimals={judgingDecimals}
            category={this.props.category}
            judgeId={this.props.judgeId}
            heatId={heat.get("id")}
            dispatchHeatId={ride.get("heat_id", runs.get(index, heat.get("id")))}
            athleteHeatId={athleteHeatId}
            teamPosition={teamPosition}
            parentAthleteId={parentAthleteId}
            athleteId={athleteId}
            athleteName={athlete.get("name")}
            teamName={athlete.get("team_name")}
            jersey={jersey}
            bib={athlete.get("bib")}
            rideIndex={index}
            dispatchRideIndex={ride.get("ride_index", runs.isEmpty() ? index : 0)}
            heatConfig={heatConfig}/>;
    };

    render = () => {
        const { heat, hideNames } = this.props,
            { modifier, missingRides } = this.state,
            startTime = heat.get("start_time"),
            duration = heat.get("heat_duration_minutes"),
            heatConfig = heat.get("config"),
            isTeams = heat.getIn(["config", "is_teams"]),
            appraiseTeamMembers = isTeams && heat.getIn(["config", "team_config", "appraisal_level"]) === "individual",
            runs = heat.get("runs", Immutable.List()),
            athleteRidesLimit = runs.size || heatConfig.get("athlete_rides_limit"),
            spots = resultsByAthletePosition(heat),
            maxRidesPerAthletePerTeam = spots.map(spot =>
                spot && (appraiseTeamMembers ? spot.getIn(["athlete", "athletes"]) : Immutable.fromJS([{ id: spot.getIn(["athlete", "id"]) }])).map(athlete =>
                    spot.getIn(["result", "rides", "" + athlete.get("id")])
                        .filter((ride, rideIndex) => !missingRides.getIn(["" + athlete.get("id"), rideIndex])).count()
                )
            );

        return <>
            <NotStartedHeatMessage startTime={startTime} runBased={heat.get("type") === "run"} />

            <div className="toolbar">
                {startTime ?
                    heat.getIn(["config", "hide_timer"]) ?
                        <div className="timer-wrapper"><T>LIVE</T></div> :
                        <Timer start={startTime} duration={duration}/> :
                    !heatConfig.get("hide_timer") && <div className="timer-wrapper">{`${duration}:00`}</div>
                }

                <ModifierMenu
                    modifier={modifier}
                    heatConfig={heatConfig}
                    addModifier={this.addModifier}
                    buttonProps={{ variant: "contained", color: "primary" }}
                />
            </div>

            <JudgeCardTable
                            className={classnames("judge-card", hideNames && "hide-names")}
                            JerseyCellRenderer={this.jerseyCellRenderer}
                            hideNames={hideNames}

                            heat={heat}
                            spots={spots}
                            AthleteCellNameRenderer={this.athleteCellNameRenderer}
                            RideRenderer={this.rideRenderer}
                            heatStarted={!!startTime}
                            athleteRidesLimit={athleteRidesLimit}
                            maxRidesPerAthletePerTeam={maxRidesPerAthletePerTeam}

                            missingRides={missingRides}
            />
        </>;
    };
}

export const JudgeSheet = connect()(DumbJudgeSheet);
