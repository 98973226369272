import { Button } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "components/util/t";

const useStyles = makeStyles((theme) => ({
    badge: {
        marginLeft: theme.spacing(1),
        padding: theme.spacing(0.5, 1),
        verticalAlign: "text-bottom",
        minWidth: "auto",
        minHeight: "auto",
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.secondary.contrastText,
        "&:hover": {
            backgroundColor: theme.palette.secondary.light,
        }
    }
}));

export const CompactBadge = ({ text }) => {
    const classes = useStyles();

    return <Button variant="contained" component="div" size="small" className={classes.badge}><T>{text}</T></Button>;
};
