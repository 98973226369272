import classnames from "classnames";
import { NavLink } from "./NavLink";
import { T } from "../../util/t";
import { RunEventMenu } from "../menus/RunEventMenu";
import { Link } from "@mui/material";

export const NavMenuLinks = ({ user = {}, className, anchorEl, openMenu, closeMenu, clickLink, isMobile }: {
    user: any;
    className?: string;
    anchorEl: HTMLElement | null;
    openMenu: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    closeMenu: () => void;
    clickLink: () => void;
    isMobile: boolean;
}) => (
    <div className={classnames(className)}>
        {(!user.role) && <>
            <RunEventMenu isMobile={isMobile} anchorEl={anchorEl} openMenu={openMenu} closeMenu={closeMenu} />
            <Link href="/sign-up" variant="subtitle2" underline="none"><T>Sign up</T></Link>
        </>
        }

        {user.role === "judge" &&
        <NavLink to="/dashboard/judge" onClick={clickLink}><T>Events</T></NavLink>}

        {user.role === "director" &&
        <>
            <NavLink to="/director" onClick={clickLink}><T>Events</T></NavLink>
            <NavLink to="/director/rankings" onClick={clickLink}><T>Series</T></NavLink>
            <NavLink to="/director/athletes-teams" onClick={clickLink}><T>Athletes & teams</T></NavLink>

            {user.organisation?.stripe_account_type === "express" && <NavLink to="/director/payments" onClick={clickLink}><T>Payments (legacy)</T></NavLink>}
        </>}
    </div>
);
