import { useCallback, useState } from "react";
import classnames from "classnames";
import { Tooltip as MUITooltip } from "@mui/material";
import { ClickAwayListener } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";

// @ts-expect-error don't know how to fix this
const useStyles = makeStyles((theme, { shouldHide }) => ({
    tooltipWrapper: {
        cursor: !shouldHide && "pointer"
    },
    tooltipPopper: {
        opacity: 0.9,
        pointerEvents: "auto !important",
        "& .tooltip": {
            position: "relative",
            backgroundColor: theme.palette.background.dark,
            color: theme.palette.text.contrastText,
            fontSize: theme.typography.body2.fontSize,
            lineHeight: theme.spacing(3),
            borderRadius: theme.spacing(1),
            boxShadow: theme.shadows.custom[3],
            "&.variable-width": {
                maxWidth: "100vw"
            },
            "& .tooltip-heading": {
                borderBottom: `1px solid ${theme.palette.border.main}`
            },
            "& a, & a:visited": {
                color: theme.palette.text.contrastText,
                textDecoration: "underline"
            },
            "& .categories": {
                display: "flex",
                overflowX: "auto",

                "& .category": {
                    display: "flex",
                    flexDirection: "column",

                    "& .tooltip-heading": {
                        height: "100%"
                    },

                    "& .tooltip-heading, & .tooltip-body": {
                        paddingLeft: theme.spacing(0.5),
                        paddingRight: theme.spacing(0.5),
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center"
                    }
                }
            }
        },
        "& .MuiTooltip-arrow": {
            color: theme.palette.background.dark,
        }
    }
}));

interface TooltipProps {
    shouldHide?: boolean;
    title: React.ReactNode;
    childrenWrapperClass?: string;
    className?: string;
    variableWidth?: boolean;
}

export const Tooltip: React.FCWithChildren<TooltipProps> = ({ shouldHide, title, childrenWrapperClass, children, className, variableWidth }) => {
    // @ts-expect-error suppress error
    const classes = useStyles({ shouldHide });
    const [open, setOpen] = useState(false);
    const closeTooltip = useCallback(() => setOpen(false), []);
    const openTooltip = useCallback((e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!shouldHide) {
            setOpen(true);
        }
    }, [shouldHide]);

    return (
        <ClickAwayListener onClickAway={closeTooltip}>
            <div className={classnames(classes.tooltipWrapper, childrenWrapperClass)}>
                <MUITooltip
                    placement="top"
                    classes={{ popper: classnames(classes.tooltipPopper, className), tooltip: classnames("tooltip", { "variable-width": variableWidth }) }}
                    onClose={closeTooltip}
                    open={open}
                    arrow
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={title}
                >
                    <div onClick={openTooltip}>
                        {children}
                    </div>
                </MUITooltip>
            </div>
        </ClickAwayListener>
    );
};
