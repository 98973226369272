import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useSimpleListStyles } from "./useSimpleListStyles";

interface SimpleListItemBaseProps {
    subtitle?: string;
    description?: string | React.ReactNode;
    aside?: string | React.ReactNode;
    borderBottom?: number;
}

interface SimpleListItemMediumProps extends SimpleListItemBaseProps {
    title?: string;
    limitDescription?: boolean;
    limitTitle?: boolean;
}

const SimpleListItemMedium: React.FC<SimpleListItemMediumProps> = ({ title, subtitle, description, aside, borderBottom = 1, limitDescription = false, limitTitle = false }) => {
    const classes = useSimpleListStyles({ borderBottom });

    return (
        <li className={classes.item}>
            <div className={classes.content}>
                <Typography variant="button" component="div" className={classnames(limitTitle && classes.limitContent)}>{title}</Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                {description && <Typography color="textSecondary" marginTop={1} className={classnames(limitDescription && classes.limitContent)}>{description}</Typography>}
            </div>
            <Typography className={classes.aside} component="div">{aside}</Typography>
        </li>
    );
};

interface SimpleListItemSmallProps extends SimpleListItemBaseProps {
    onClick?: () => void;
    highlightOnHover?: boolean;
}

const SimpleListItemSmall: React.FC<SimpleListItemSmallProps> = ({ subtitle, description, aside, onClick, borderBottom = 0, highlightOnHover }) => {
    const classes = useSimpleListStyles({ borderBottom, padding: 0.5, onClick, highlightOnHover });

    return (
        <li className={classes.item} onClick={onClick}>
            <div className={classes.content}>
                <Typography component="div">{subtitle}</Typography>
                <Typography color="textSecondary" variant="caption">{description}</Typography>
            </div>
            <div className={classes.aside}>{aside}</div>
        </li>
    );
};

const VARIANTS = {
    medium: SimpleListItemMedium,
    small: SimpleListItemSmall
};

interface SimpleListProps {
    hideLastBorder: boolean;
    variant: keyof typeof VARIANTS;
    children: any[];
}

export const SimpleList: React.FC<SimpleListProps> = ({ children, hideLastBorder, variant = "medium" }) => (
    <Stack component="ul" margin={0} padding={0}>
        {children.map((item, i) =>
            item.to ?
                <SimpleListItemLink {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} /> :
                <SimpleListItem {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} />
        )}
    </Stack>
);

interface SimpleListItemLinkProps {
    to: string;
    target?: string;
    rel?: string;
    [key: string]: any;
}

export const SimpleListItemLink: React.FC<SimpleListItemLinkProps> = ({ to, target, rel, ...rest }) => (
    <Link to={to} target={target} rel={rel}>
        <SimpleListItem {...rest} aside={rest.aside || <ChevronRight />} />
    </Link>
);

interface SimpleListItemProps extends SimpleListItemMediumProps, SimpleListItemSmallProps {
    variant?: keyof typeof VARIANTS;
    [key: string]: any;
}

export const SimpleListItem: React.FC<SimpleListItemProps> = ({ variant = "medium", ...rest }) => {
    const ListComponent = VARIANTS[variant];

    return <ListComponent {...rest} />;
};
