import { Box, Button, Typography } from "@mui/material";
import { T } from "../../util/t";
import { Section } from "./HomePage";
import { trackClick, trackingCategory } from "utils/tracking";

export const OrganiserPromoCard = () => {
    const track = trackClick("Organiser site button", trackingCategory.organiserBanner);
    
    return (
        <Section paddingX={2}>
            <Box bgcolor="background.low" borderRadius={1} padding={2}>
                <Typography variant="h3" pb={2}><T>org_promo_title</T></Typography>
                <Typography variant="subtitle2" pb={1.5}><T>Liveheats provides an easy to use platform for registrations, draws, schedules, live scoring and rankings</T></Typography>
                <Button
             onClick={track}
             component="a"
             href="https://organiser.liveheats.com"
             target="_blank"
             variant="contained"
             color="primary">
                    <T>Liveheats for organisers</T>
                </Button>
            </Box>
        </Section>
    );
};
