import classnames from "classnames";
import { useId } from "react";
import { Typography } from "@mui/material";

import { DateField } from "./fields/DateField";
import { Radio } from "./fields/Radio";
import { Select } from "./fields/Select";
import { Input } from "./fields/Input";
import { Checkbox } from "./fields/Checkbox";
import { T } from "../../util/t";
import { ChipSelect } from "./fields/ChipSelect";
import { SearchSelect } from "./fields/Autocomplete/SearchSelect";
import { CreatableSearchSelect } from "./fields/Autocomplete/CreatableSearchSelect";
import { Textarea } from "./fields/Textarea";
import { HelpTooltip } from "../../elements/helpTooltip";
import { Tooltip } from "../../elements/tooltip";
import { Lock } from "@mui/icons-material";
import { FieldProps } from "components/forms/Field";
import { FieldError } from "react-hook-form";
import { ToggleButton } from "./fields/ToggleButton";
import { useFormFieldStyles, useMessageStyles } from "components/layout/forms/formFieldStyles";

interface FormFieldWrapperProps {
    label: string;
    helpTooltip?: string;
}

export const FormFieldWrapper: React.FCWithChildren<FormFieldWrapperProps> = ({ label, helpTooltip, children }) => {
    const classes = useFormFieldStyles();

    return (
        <div className={classes.field}>
            <Typography variant="label1" component="label" className={classes.label}>
                <T>{label}</T>
                {helpTooltip && <HelpTooltip><T>{helpTooltip}</T></HelpTooltip>}
            </Typography>
            {children}
        </div>
    );
};

const fieldByType = {
    date: DateField,
    radio: Radio,
    checkbox: Checkbox,
    select: Select,
    searchSelect: SearchSelect,
    creatableSearchSelect: CreatableSearchSelect,
    chipSelect: ChipSelect,
    textarea: Textarea,
    toggleButton: ToggleButton
};

export type FieldType = keyof typeof fieldByType;

const getField = (type: FieldType) => fieldByType[type] || Input;

export interface FormFieldProps {
    field: FieldProps;
    input: {
        value: any;
        onChange: (value: { value: any }) => void;
    };
    error?: { message: string } | FieldError | undefined;
    validating?: boolean;
    isDirty?: boolean;
}

export const FormField: React.FCWithChildren<FormFieldProps> = ({ field, input, error, validating, isDirty, children }) => {
    const classes = useFormFieldStyles({ width: field.width });
    const id = useId();
    const Component = getField(field.type as FieldType);
    const displayErrorMessage = field.displayErrorMessage ?? true;
    field.inputProps = { ...field.inputProps, id };

    return (
        <div className={classes.field}>
            <div className={classes.inputWrapper}>
                {field.label && field.type !== "checkbox" &&
                <Typography variant="label1" className={classes.label} component="label" htmlFor={id}>
                    <T>{field.label}</T>
                    {field.helpTooltip && <HelpTooltip><T>{field.helpTooltip}</T></HelpTooltip>}
                </Typography>}

                {field.help && <Typography variant="caption" component="p" className={classes.help}><T>{field.help}</T></Typography>}

                <Component field={field as any} input={input as any} hasError={!!error}/>
                {field.label && field.type === "checkbox" &&
                <Typography className={`${classes.label} ${classes.checkboxLabel}`} component="label" htmlFor={id}>
                    <T>{field.label}</T>
                    {field.helpTooltip && <HelpTooltip><T>{field.helpTooltip}</T></HelpTooltip>}
                </Typography>}

                {field.restricted && <div className={classes.locked}>
                    <Tooltip title={<T fieldName={field.label} contactEmail={field.contactEmail}>restricted_field</T>}>
                        <Typography variant="inherit">
                            <Lock />
                        </Typography>
                    </Tooltip>
                </div>}
            </div>
            {children}
            {displayErrorMessage && <FieldMessage
                isDirty={isDirty}
                errorMessage={error?.message}
                checkingMessage={field.checkingMessage}
                validMessage={field.validMessage}
                validating={validating}
                infoMessage={field.infoMessage}
            />}
        </div>
    );
};

interface FieldMessageProps {
    isDirty?: boolean;
    errorMessage?: any;
    validMessage?: string;
    checkingMessage?: string;
    validating?: boolean;
    infoMessage?: string;
}

export const FieldMessage: React.FC<FieldMessageProps> = ({ isDirty, errorMessage, validMessage, checkingMessage, validating, infoMessage }) => {
    const classes = useMessageStyles();

    if (validating && checkingMessage)
        return <div className={classes.message}><T>{checkingMessage}</T></div>;

    if (!errorMessage && infoMessage) {
        return <div className={classes.message}><T>{infoMessage}</T></div>;
    }

    if (isDirty && !errorMessage && validMessage)
        return <div className={classnames(classes.message, classes.successMessage)}><T>{validMessage}</T></div>;

    return <div className={classnames(classes.message, classes.errorMessage)}>{errorMessage && <T>{errorMessage}</T>}</div>;
};
