import { useMeta } from "hooks/useMeta";
import { T } from "../../util/t";

export const TermsAndConditions = () => {
    useMeta({
        title: "Liveheats User Terms & Conditions",
        description: "Liveheats is a leading action sport event management platform used by local clubs, regional, state, and national federations around the world.",
    });
    
    return <div id="terms-and-conditions" className="wrapper">
        <T>TermsAndConditions_LocalizedComponent</T>
    </div>;
};
