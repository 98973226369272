import { Box, Typography } from "@mui/material";
import { useFormFieldStyles } from "components/layout/forms/formFieldStyles";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "components/util/t";
import { UseFormWatch } from "react-hook-form";
import { FieldProps } from "./Field";

const useStyles = makeStyles({
    fieldset: {
        "&[disabled]": {
            opacity: 0.6,
            "&, & *": {
                cursor: "not-allowed"
            }
        }
    }
});

export interface FieldsetProps {
    field: FieldProps;
    watch: UseFormWatch<any>;
}

export const Fieldset: React.FCWithChildren<FieldsetProps> = ({ field: { label, help, disabled }, children, watch }) => {
    const classes = { ...useFormFieldStyles(), ...useStyles() };

    return (
        <Box component="fieldset" margin={0} padding={0} border={0} disabled={typeof disabled === "function" ? disabled({ watch }) : disabled} className={classes.fieldset}>
            {label && <Typography variant="label1" className={classes.label} component="label">
                <T>{label}</T>
            </Typography>}
            {help && <Typography variant="caption" component="p" className={classes.help}><T>{help}</T></Typography>}
            <Box marginTop={label || help ? 2 : 0}>
                {children}
            </Box>
        </Box>
    );
};
