import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { T } from "../../../../util/t";

export const DetailsDialog = ({ title, content, open, onClose,  }) =>
    <Dialog open={open} aria-labelledby={"details-dialog-title"} onClose={onClose} >
        <DetailsDialogDialogContent title={title} content={content} onClose={onClose} />
    </Dialog>;

const DetailsDialogDialogContent = ({ title, content, onClose }) =>
    <>
        <DialogTitle id="details-dialog-title"><T>{title}</T></DialogTitle>

        <DialogContent>
            <Typography>{content}</Typography>
        </DialogContent>

        <DialogActions>
            <Button variant="contained" onClick={onClose}>
                <T>Close</T>
            </Button>
        </DialogActions>
    </>;
