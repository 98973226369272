import { useTranslate } from "components/providers/LocalizationProvider";
import { useEffect } from "react";
import { defaultDescription } from "components/routes/root/HomePage";

export type UseMetaProps = {
    title: string,
    description?: string,
    translationProps?: Record<string, string>,
    skip?: boolean,
}

export const getSportTypeLabel = (sportType?: string): string | undefined => {
    if (!sportType) return undefined;

    const specialCases: Record<string, string> = {
        surf: "Surfing",
        skate: "Skateboarding",
        snow: "Ski & Snowboard",
        sls: "Surf Life Saving",
        wake: "Wakeboarding",
        skim: "Skimboarding",
        bodyboard: "Bodyboarding",
        bmx: "BMX",
        scooter: "Scooter",
        paddle: "Stand Up Paddle (SUP)",
        freeride: "Freeride Ski & Snowboard",
        fmb: "Freeride Mountain Biking",
        windsurf: "Windsurfing",
    };
    return specialCases[sportType];
};

export const useMeta = ({ title, description, translationProps, skip }: UseMetaProps) => {
    const updatedTranslationProps = {
        ...translationProps,
        sportType: getSportTypeLabel(translationProps?.sportType)
    };
    const translatedTitle = useTranslate(title, updatedTranslationProps);
    const translatedDescription = useTranslate(description, updatedTranslationProps);


    useEffect(() => {
        if (skip) return;
        ensureMetaTagExists("title", translatedTitle || "");
        ensureMetaTagExists("og:title", translatedTitle || "");
        ensureMetaTagExists("twitter:title", translatedTitle || "");
        ensureMetaTagExists("description", translatedDescription || "");
        ensureMetaTagExists("og:description", translatedDescription || "");
        ensureMetaTagExists("twitter:description", translatedDescription || "");
    }, [skip]);

    useEffect(() => {
        if (skip) return;

        document.title = translatedTitle;
        document.querySelectorAll("meta[name$=\"title\"]").forEach(tag =>
            tag.setAttribute("content", translatedTitle)
        );

        const descriptionContent = translatedDescription || defaultDescription;
        document.querySelectorAll("meta[name$=\"description\"]").forEach(tag =>
            tag.setAttribute("content", descriptionContent)
        );
    }, [translatedTitle, translatedDescription, skip]);
};

function ensureMetaTagExists(name: string, content: string) {
    if (!document.head.querySelector(`meta[name="${name}"]`)) {
        const metaTag = document.createElement("meta");
        metaTag.setAttribute("name", name);
        metaTag.setAttribute("content", content);
        document.head.appendChild(metaTag);
    }
}
