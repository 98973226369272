import { makeStyles } from "../../providers/makeStyles";

export const useEventRowStyles = makeStyles(theme => ({
    event: {
        display: "flex",
        borderTop: `1px solid ${theme.palette.border.light}`,
        padding: theme.spacing(2,0,2,0),
        "&:last-child": {
            borderBottom: `1px solid ${theme.palette.border.light}`,
        },
    },
    box: {
        backgroundColor: theme.palette.background.mid,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: theme.spacing(1, 0.5),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("md")]:{
            marginRight: theme.spacing(1),
        },
        minWidth: theme.spacing(11),
        height: theme.spacing(7),
    },
    details: {
        width: "100%"
    },
    title: {
        display: "flex",
        flexWrap: "wrap-reverse",
        rowGap: theme.spacing(0.5),
        columnGap: theme.spacing(2),
        marginTop: theme.spacing(0.5),
        "&&": {
            [theme.breakpoints.down("md")]:{
                justifyContent: "space-between",
            },
        }
    },
    pill: {
        "&&": {
            display: "inline-block",
            verticalAlign: "middle",
            whiteSpace: "nowrap",
        }
    },
    chevron: {
        alignSelf: "center"
    }
}));
