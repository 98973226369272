import { Divider, Link, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { ChevronRight } from "@mui/icons-material";

import { useMiniButtonStyles } from "../commonStyles/buttonStyles";
import { T } from "../../util/t";
import { NavLink } from "components/layout/navigation/NavLink";
import { trackClick } from "utils/tracking";

const useRunEventMenuStyles = makeStyles(theme => ({
    chevronDown: {
        transform: "rotate(90deg)",
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1)
    },
    menuItems: {
        "& > a": {
            whiteSpace: "normal",
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
        }
    }
}));

export const RunEventMenu = ({ isMobile,  anchorEl, openMenu, closeMenu }: {
    isMobile: boolean;
    anchorEl: HTMLElement | null;
    openMenu: (event: React.MouseEvent<HTMLAnchorElement>) => void;
    closeMenu: () => void;
}) => {
    const classes = { ...useMiniButtonStyles(), ...useRunEventMenuStyles() };

    const items = (
        <div className={classes.menuItems}>
            {isMobile && <>
                <MenuItem 
                    onClick={trackClick("sign-up", "Public Navigation", closeMenu)} 
                    component={NavLink}
                    to="/sign-up"
                >
                    <T>Sign up</T>
                </MenuItem>

                <Divider />
                
                <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}><T>For event organisers</T></Typography>
            </>}

            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com/" target="_blank">
                <T>Liveheats for organisers</T>
            </MenuItem>
            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com/pricing/" target="_blank">
                <T>Organiser pricing</T>
            </MenuItem>
            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com/contact/?_gl=1*18jx4nz*_ga*MjA5NjY0ODgwMC4xNzEzNTAwOTQz*_ga_ZHZ05GLC7Y*MTcxNjI5NTQ5NC4xMTguMS4xNzE2Mjk1NzEyLjAuMC4w" target="_blank">
                <T>New organiser enquiry</T>
            </MenuItem>
        </div>
    );

    return (
        <>
            <>
                {!isMobile && <Link variant="subtitle2" underline="none" onClick={openMenu}>
                    <T>For event organisers</T><ChevronRight className={classes.chevronDown} />
                </Link>}
                <Menu
                        anchorEl={anchorEl}
                        marginThreshold={4}
                        open={!!anchorEl}
                        onClose={closeMenu}
                >
                    {items}
                </Menu>
            </>
        </>
    );
};
