import { makeStyles } from "../../../../providers/makeStyles";

export const useDetailsSectionStyles = makeStyles(theme => ({
    header: {
        aside: {
            alignItems: "center",
        }
    },
    detailsSection: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
        },
        "& > *": {
            padding: theme.spacing(1),
            width: "100%",
            [theme.breakpoints.up("md")]: {
                width: "50%"
            },
            "& h5": {
                marginBottom: theme.spacing(4),
            }
        }
    }
}));
