import { startTransition, useEffect } from "react";

export const useRefocusEffect = (callback, condition, transition) =>
    useEffect(() => {
        if (condition) {
            const listener = transition ? () => startTransition(callback) : callback;
            window.addEventListener("focus", listener);
            return () => window.removeEventListener("focus", listener);
        }
    }, [callback, condition]);
