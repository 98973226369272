import classnames from "classnames";
import { makeStyles } from "components/providers/makeStyles";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2),
        "@media print": {
            padding: theme.spacing(2, 0),
        },
    }
}));
export const PaddedPage:React.FCWithChildren<{ className?: string }> = ({ className, children }) => {
    const classes = useStyles();

    return (
        <section className={classnames(classes.page, className)}>
            {children}
        </section>
    );
};

