import { Accordion, AccordionDetails, AccordionProps, AccordionSummary, AccordionSummaryProps } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { palette } from "../../providers/theme/palette";

export type AccordionItemProps = {
    id: string;
    variant: "primary" | "secondary";
    expanded: AccordionProps["expanded"];
    onChange: AccordionProps["onChange"];
    summary: React.ReactNode;
    expandIcon: AccordionSummaryProps["expandIcon"];
    expandIconLocation?: "left" | "right";
    disableRotation: boolean;
    slimline?: boolean;
}

const useStyles = makeStyles((theme, { disableRotation, variant, expandIconLocation, slimline }: Pick<AccordionItemProps, "disableRotation" | "variant" | "expandIconLocation" | "slimline">) => {
    const variants = {
        primary: {
            backgroundColor: theme.palette.background.paper,
            hoverBackgroundColor: theme.palette.background.low,
        },
        secondary: {
            backgroundColor: theme.palette.background.low,
            hoverBackgroundColor: theme.palette.background.mid,
        }
    };

    const variantStyles = variants[variant] || variants.primary;

    return {
        accordionRoot: {
            border: 1,
            borderStyle: "solid",
            borderColor: theme.palette.border.light,
            borderRadius: theme.shape.borderRadius,
            marginBottom: theme.spacing(2),
            backgroundColor: variantStyles.backgroundColor,
            transition: theme.transitions.create(["background-color", "border-color"]),
            "&::before": {
                display: "none"
            },
            "&:has(:focus-visible)": {
                outline: `3px solid ${palette.input.focus}`,
                outlineOffset: "2px"
            },
        },
        accordionSummaryRoot: {
            flexDirection: expandIconLocation === "left" ? "row-reverse" : "row",
            gap: theme.spacing(0.5),
            borderRadius: theme.shape.borderRadius,
            backgroundColor: variantStyles.backgroundColor,
            "&:hover": {
                backgroundColor: variantStyles.hoverBackgroundColor,
            },
            "&.Mui-expanded": {
                borderRadius: theme.spacing(1,1,0,0),
                minHeight: theme.spacing(6)
            },
            "&.disabled": {
                opacity: 0.6
            },
            "&.Mui-focusVisible": {
                backgroundColor: "inherit"
            }
        },
        accordionSummaryContent: {
            minHeight: slimline ? undefined : theme.spacing(9),
        },
        expandIconWrapper: {
            "&.Mui-expanded": {
                transform: disableRotation ? "rotate(0deg)" : undefined,
                color: theme.palette.tertiary.main
            },
        }
    };
});

export const AccordionItem: React.FCWithChildren<AccordionItemProps> = ({ expanded, onChange, id, summary, expandIcon, disableRotation, children, variant, expandIconLocation, slimline }) => {
    const classes = useStyles({ disableRotation, variant, expandIconLocation, slimline });

    return (
        <Accordion
            classes={{ root: classes.accordionRoot }}
            className={expandIconLocation}
            expanded={expanded}
            onChange={onChange}
        >
            <AccordionSummary
                aria-controls={`${id}-content`}
                id={`${id}-header`}
                expandIcon={expandIcon}
                classes={{ 
                    root: classes.accordionSummaryRoot, 
                    content: classes.accordionSummaryContent, 
                    expandIconWrapper: classes.expandIconWrapper
                }}
            >
                {summary}
            </AccordionSummary>

            {children && (
                <AccordionDetails>
                    {children}
                </AccordionDetails>
            )}
        </Accordion>
    );
};
