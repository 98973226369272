import { ChildDivisionParentSeriesMapping } from "./ChildDivisionParentSeriesMapping";

type GroupDivisionParentSeriesDivisionMappingProps = {
    eventDivisions: any[];
    federationSeries: any[];
    organisationId: number;
};

export const GroupDivisionParentSeriesDivisionMapping: React.FC<GroupDivisionParentSeriesDivisionMappingProps> = ({ eventDivisions, federationSeries, organisationId }) => {
    return (<>
        {eventDivisions?.map((ed, eventDivisionIndex: number) =>
            ed.get("division_to_seasons_attributes").map((dts: any, index: number) => {
                const parentSeries = federationSeries.find(series => `${series.get("id")}` === `${dts.get("series_id")}`);
                return <ChildDivisionParentSeriesMapping key={`${eventDivisionIndex}-${index}`}
                                                         index={index}
                                                         eventDivisionIndex={eventDivisionIndex}
                                                         eventDivision={ed}
                                                         parentDivision={dts}
                                                         parentSeries={parentSeries}
                                                         organisationId={organisationId} />;
            })
        )}
    </>);
};
