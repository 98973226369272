import { ResponsiveDialog } from "../../../../modal";
import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { T } from "../../../../util/t";
import { timeFormat } from "../../../../../utils/dates";
import { useState } from "react";
import { dateInput } from "components/forms";
import { Delete } from "@mui/icons-material";

export const DayDialog = ({ open, onClose, dialogTitle, value, dateInputProps, onChange, DateFieldComponent }) =>
    <ResponsiveDialog open={open} onClose={onClose} maxWidth="sm" aria-labelledby="day-dialog-title">
        <DayDialogContent date onClose={onClose} dialogTitle={dialogTitle} value={value} dateInputProps={dateInputProps} onChange={onChange} DateFieldComponent={DateFieldComponent} />
    </ResponsiveDialog>;

const DayDialogContent = ({ onClose, dialogTitle, value, dateInputProps = {}, onChange, DateFieldComponent = dateInput }) => {
    const { initialValue, minDate,  minTime, maxTime, timeIntervals } = dateInputProps;
    const [selected, setSelected] = useState(initialValue);

    const selectDate = destroy => e => {
        onChange(destroy ? null : selected);
        onClose(e);
    };

    const dateFieldProps = {};

    const inputProps = {
        showTimeSelect: true,
        autoFocus: true,
        minDate,
        minTime: minTime?.(selected),
        maxTime: maxTime?.(selected),
        timeIntervals,
        timeFormat: timeFormat(),
        width: "100%",
        inline: true,
    };

    // TODO: remove this condition when we get rid of old event form
    if (DateFieldComponent === dateInput) {
        dateFieldProps.meta = {};
        dateFieldProps.input = { value: selected, onChange: setSelected };
        Object.keys(inputProps).forEach(key => dateFieldProps[key] = inputProps[key]);
    } else {
        dateFieldProps.input = { value: selected, onChange: setSelected };
        dateFieldProps.field = { inputProps, hideClearButton: true };
    }

    return (
        <>
            <DialogTitle id="day-dialog-title" className="sticky-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogContent key="dialog-content" className="sticky-dialog-content">
                <DateFieldComponent {...dateFieldProps} />
            </DialogContent>
            <DialogActions>
                {value && <Box onClick={selectDate(true)} startIcon={<Delete />} component={Button} marginRight="auto">
                    <T>Delete</T>
                </Box>}
                <Button variant="outlined" onClick={onClose}><T>Close</T></Button>
                <Button variant="contained" onClick={selectDate()} disabled={value?.valueOf() === selected?.valueOf()}>
                    {!value
                        ? <T>Add</T>
                        : <T>Update</T>}
                </Button>
            </DialogActions>
        </>
    );
};
