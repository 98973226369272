import classnames from "classnames";

import { Heading } from "./Heading";
import { makeStyles } from "components/providers/makeStyles";
import { ReactElement } from "react";

const useStyles = makeStyles((theme, { align }: { align: string }) => ({
    page: {
        padding: theme.spacing(2),
        margin: align === "center" ? "0 auto" : undefined,
        width: "calc(100vw - (100vw - 100%))",
        maxWidth: `min(100vw, ${theme.shape.pageMaxWidth}px)`,
        "&& header": {
            margin: 0,
            padding: 0,
            borderBottom: 0,
            width: "auto"
        }
    }
}));

interface MaxWidthPageProps {
    className?: string;
    align?: string;
}

export const MaxWidthPage: React.FCWithChildren<MaxWidthPageProps> = ({ className, align = "center", children }) => {
    const classes = useStyles({ align });
    return (
        <main className={classnames(classes.page, className)}>
            {children}
        </main>
    );
};

interface MaxWidthPageWithHeadingProps {
    className?: string;
    title: string | ReactElement;
    returnTo?: string;
    actions?: React.ReactNode;
    align?: string;
}

export const MaxWidthPageWithHeading: React.FCWithChildren<MaxWidthPageWithHeadingProps> = ({ className, title, returnTo, actions, align, children }) => (
    <MaxWidthPage className={className} align={align}>
        <Heading title={title} returnTo={returnTo} actions={actions}/>
        {children}
    </MaxWidthPage>
);
