import { makeStyles } from "components/providers/makeStyles";

export const MENU_WIDTH = 240;
const COLLAPSED_MENU_WIDTH = 40;

export const useSideNavStyles = makeStyles((theme, { isRemoteVisible, isSideNavCollapsed }) => ({
    nav: {
        zIndex: 3,
        background: theme.palette.background.lowest,
        maxWidth: "80vw",
        width: isSideNavCollapsed ? COLLAPSED_MENU_WIDTH : MENU_WIDTH,
        flexShrink: 0,
        transition: "width 225ms cubic-bezier(0, 0, 0.2, 1) 0ms",
        cursor: isSideNavCollapsed && "pointer",
        borderRight: `1px solid ${theme.palette.border.light}`,

        "a": {
            display: "flex",
            padding: theme.spacing(1.5, 3),
            borderLeft: `${theme.spacing(0.5)} solid transparent`,

            "&.active": {
                borderLeftColor: theme.palette.brand.primary
            }
        },

        "@media print": {
            display: "none"
        },
    },
    toggle: {
        position: "fixed",
        left: "10px",
        transform: isSideNavCollapsed ? `translateX(${COLLAPSED_MENU_WIDTH - 50}px) scaleX(-1)` : `translateX(${MENU_WIDTH - parseInt(theme.spacing(1)) - 50}px)`,
        bottom: isRemoteVisible ? parseInt(theme.spacing(1)) + 82 : theme.spacing(1), // 82px is @remote-control-height,
        transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
    }
}));
