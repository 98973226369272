import dayjs from "dayjs";

export const SHORT_DATE = "D MMM YYYY"; // 7 Sep 2021
export const LONG_DATE_AND_TIME = "dddd D MMM YYYY | HH:mm"; // Tuesday 7 Sep 2021 | 12:46
export const ISO_DATE = "YYYY-MM-DD"; // 2021-09-07
export const LONG_DATE_AND_TIME_WITHOUT_YEAR = "D MMMM, HH:mma"; // 7 September, 12:46pm
export const SHORT_DATE_AND_TIME = "ddd D MMM, h:mm a"; // Sat 7 Sep, 12:46pm

export const SHORT_DATE_WITHOUT_YEAR = "D MMM"; // 7 Sep

export const DATE_TIME = "YYYY-MM-DDTHH:mm:ss"; // 2021-09-07T12:46:00

export const LONG_DATE = "ddd, D MMM, YYYY"; // Sat, 07 Sep, 2021

export const FULL_DATE_AND_TIME = "D MMM YYYY, HH:mm:ss"; // 30 Aug 2024, 11:39:27

export const timeFormat = () => {
    const hourCycle = new Intl.DateTimeFormat(
        (navigator.language || navigator.languages[0]),
        { hour: "numeric" }
    ).resolvedOptions().hourCycle;

    return hourCycle === "h11" || hourCycle === "h12" ? "h:mm a" : "HH:mm";
};

export const dateTimeInTimezone = (date, utcOffsetMinutes) => dayjs.utc(date).utcOffset(utcOffsetMinutes).format(DATE_TIME);
