import { PureComponent, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import Immutable from "immutable";
import Transition from "react-transition-group/Transition";
import { connect, useDispatch } from "react-redux";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Add, Close, Delete, DragIndicator, PersonAdd, VisibilityOff } from "@mui/icons-material";
import { getEmptyImage } from "react-dnd-html5-backend";

import * as HeatActions from "../../../actions/heat";
import * as EventDirectorActions from "../../../actions/eventDirector";

import { resultsByAthletePosition, TotalCell } from "./card";
import { LoadingButton, LoadingIconButton } from "../../actions/loadingButton";
import { EditRideDialog } from "./editRideDialog";
import { CustomDragLayer } from "../../DnD/dragLayers";
import { JudgeCardTable, ToggleEditButton } from "../judging/judgeCard";
import { PlaceEditor } from "../judging/PlaceEditor";
import { HeatFilterAndPlace } from "../../layout/heat/edit/HeatFilterAndPlace";
import { T } from "../../util/t";
import { useDialogState } from "../../../hooks/useDialogState";
import { HidePrinting } from "../../util/printing";
import { RideCategory } from "../../layout/judging/RideCategory";
import { ScanResultsWidget } from "../../routes/events/id/head_judge/ScanResultsWidget";
import { AddAthleteForm } from "components/layout/athletes/AddAthleteForm";
import { makeStyles } from "../../providers/makeStyles";
import { useDrag, useDragDropManager, useDrop } from "react-dnd";
import { MultiDnD } from "../../providers/DnD/MultiDnD";

export const toFixedOrDash = (value, decimals) => value != null ? parseFloat(value).toFixed(decimals) : "-";
const scorePath = (judge, category) => [judge, category].filter(p => p);

export const Ride = ({ className, ride, categoryJudges, categories = Immutable.fromJS({ "": {} }), judgeCategoriesTotal, maxRideScore, openDialog }) => {
    const pending = ride.get("pending"),
        rideCategory = ride.get("category"),
        original = parseFloat(ride.get("total")).toFixed(2),
        modifier = ride.getIn(["modifier", "type"], "").split("::")[1],
        interfere = modifier && modifier.match(/Interference/),
        didNotModifier = modifier && modifier.match(/D(N|S)(S|F|Q|I)/),
        modified = ride.get("total") !== ride.get("modified_total"),
        scores = ride.get("scores") || Immutable.Map(),
        judgesPerCategory = judgeCategoriesTotal ? categories.map(c => categoryJudges.get("", Immutable.List())) : categoryJudges,
        categoryJudgesScores = judgesPerCategory.map((judges, category) => judges.map(judge => scores.getIn(scorePath(judge, category)))),
        categoryScoreSpread = categoryJudgesScores.map(judgeScores => judgeScores.map(s => s != null ? s : -maxRideScore)),
        scoreWarning = categoryScoreSpread.some((scoreSpread, category) => (scoreSpread.max() - scoreSpread.min()) > categories.getIn([category, "judging_scale"], maxRideScore) * .15);

    return (
        <td className={classnames(className, { pending, "scoring-ride": ride.get("scoring_ride"), [`modified ${modifier}`]: modifier, "warning": scoreWarning })}
            onClick={openDialog}>

            {pending && <VisibilityOff className="pending-icon" fontSize="small"/>}

            {modified && <div className="original">{original}</div>}
            <div className="ride-total">
                {interfere ? <T>INT</T> : didNotModifier ? <T>{modifier}</T> : toFixedOrDash(ride.get("modified_total"), 2)}
                {rideCategory && <RideCategory category={rideCategory}/>}
            </div>

            {ride.get("scores") &&
                        <div className="categories">
                            {categories.sortBy((config, category) => config.get("order", category)).keySeq().map(category =>
                                <div key={category} className="category">
                                    {categoryJudges.get(judgeCategoriesTotal ? "" : category, Immutable.List()).map(judge =>
                                        <div key={judge} className="score">
                                            {toFixedOrDash(scores.getIn(scorePath(judge, category)), categories.getIn([category,  "judging_decimals"], 1))}
                                        </div>
                                    )}
                                </div>)}
                        </div>}
        </td>
    );
};

const AddRide = ({ className, colSpan, openDialog }) => {
    return (
        <td className={`${className} add-ride`}
            colSpan={colSpan}
            onClick={openDialog}>
            <IconButton>
                <Add />
            </IconButton>
        </td>
    );
};

const RideWrapper = ({
    className,
    colSpan,
    eventId,
    heatId,
    heatConfig,
    athleteHeatId,
    teamPosition,
    athleteId,
    jersey,
    bib,
    athleteName,
    teamName,
    rideIndex,
    ride,
    runs,
    categoryJudges,
    categories = Immutable.fromJS({ "": {} }),
    judgeCategoriesTotal,
    maxRideScore,
    adding
}) => {
    const [dialogOpen, openDialog, closeDialog] = useDialogState();

    return (
        <>
            {adding ?
                <AddRide className={className}
                         colSpan={colSpan}
                         openDialog={openDialog} /> :
                <Ride className={className}
                      ride={ride}
                      categoryJudges={categoryJudges}
                      categories={categories}
                      judgeCategoriesTotal={judgeCategoriesTotal}
                      maxRideScore={maxRideScore}
                      openDialog={openDialog} />
            }
            <EditRideDialog
                adding={adding}
                open={dialogOpen}
                handleClose={closeDialog}
                eventId={eventId}
                heatId={heatId}
                athleteHeatId={athleteHeatId}
                teamPosition={teamPosition}
                athleteId={athleteId}
                jersey={jersey}
                bib={bib}
                athleteName={athleteName}
                teamName={teamName}
                rideNumber={rideIndex}
                ride={adding ? Immutable.Map() : ride}
                heatConfig={heatConfig}
                categoryJudges={categoryJudges}
                dispatchHeatId={adding ?
                    runs.get(rideIndex, heatId) :
                    ride.get("heat_id", runs.get(rideIndex, heatId))}
                dispatchRideIndex={adding ?
                    runs.isEmpty() ? rideIndex : 0 :
                    ride.get("ride_index", runs.isEmpty() ? rideIndex : 0)} />
        </>
    );
};

const useDragTarget = ({ type, name, edit, hasRides, heatStarted, athleteId, position, dispatch, ref }) => useDrag({
    type,
    item: monitor => {
        window.navigator.vibrate && window.navigator.vibrate(100);
        dispatch(EventDirectorActions.beginDrag());

        return { position, name, athleteId, hasRides, heatStarted, width: ref.current?.clientWidth, height: ref.current?.clientHeight };
    },
    end: (item, monitor) => {
        const didDrop = monitor.didDrop(), dropResult = monitor.getDropResult();

        if (!didDrop || !dropResult.dropped) dispatch(EventDirectorActions.cancelDrag());
    },
    canDrag: () => !!edit,
    isDragging: monitor => monitor.getItem()?.athleteId === athleteId,
    collect: monitor => ({
        isDragging: monitor.isDragging(),
        monitorId: monitor.getHandlerId()
    })
});

const useDropTarget = ({ accept, edit, adding, heatId, eventId, athleteId, position, dispatch }) => useDrop({
    accept,
    hover: (item, monitor) => {
        if (!monitor.canDrop()) return;

        const source = { position: item.position }, target = { position };

        if (item.athleteId === athleteId ||
            (item.heatStarted && item.hasRides && !athleteId)) {
            return;
        }

        return dispatch(EventDirectorActions.swap({ eventId, heatId, source, target }));
    },
    canDrop: ({ hasRides, heatStarted }) => (edit && !adding) && !(heatStarted && hasRides && !athleteId),
    drop: item => {
        const source = { heat_id: heatId, position: item.position }, target = { heat_id: heatId, position };

        if (source.position !== target.position) dispatch(EventDirectorActions.saveSwap({ event_id: eventId, source, target }));
        return { dropped: true };
    },
    collect: monitor => ({
        isDragging: !!monitor.getItem(),
        canDrop: monitor.canDrop(),
        monitorId: monitor.getHandlerId()
    }),
});

export const Athlete = props => {
    const { name, teamName, edit, hasRides, heatId, eventId, athleteId, isTeams } = props;
    const ref = useRef();
    const dispatch = useDispatch();

    const [{ isDragging, monitorId: sourceMonitorId }, drag, dragPreview] = useDragTarget({ ...props, dispatch, ref, type: "athlete" });
    const [{ monitorId: targetMonitorId }, drop] = useDropTarget({ ...props, dispatch, accept: "athlete" });

    const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

    useEffect(() => {
	    dragPreview(getEmptyImage(), { captureDraggingState: true });
    }, [dragPreview]);

    const closeModal = () => setConfirmDeleteModalOpen(false);
    const deleteWithConfirm = () => hasRides ? Promise.resolve(setConfirmDeleteModalOpen(true)) : doDelete();
    const doDelete = () => dispatch(HeatActions.removeAthlete(eventId, heatId, athleteId, isTeams));

    drag(drop(ref));

    return (
        <th className={classnames("name", { "dragging": isDragging })} ref={ref} data-sourcemonitorid={sourceMonitorId} data-targetmonitorid={targetMonitorId}>
            {edit &&
            <>
                <LoadingIconButton className="tiny delete" buttonClassName="tiny" action={deleteWithConfirm} title="Remove this athlete from the heat" Icon={Delete}/>
                <DragIndicator className="drag" />
            </>}

            <span>{name}</span>
            {!edit && teamName && <div className="team-name">{teamName}</div>}

            <Dialog className="confirm-modal"
                    open={confirmDeleteModalOpen}
                    onClose={closeModal}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title"><T>Are you sure you want to remove this athlete?</T></DialogTitle>
                <DialogContent id="alert-dialog-description">
                    <T>All the scores for this athlete will be permanently removed.</T>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={closeModal}>
                        <T>Cancel</T>
                    </Button>
                    <LoadingButton className="confirm" variant="contained" action={doDelete} color="primary" autoFocus>
                        <T>Yes, remove</T>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </th>
    );
};

export const Empty = props => {
    const { edit, position, eventId, heatId, isTeams, eventDivisionId, athletes } = props;
    const [adding, setAdding] = useState();
    const dispatch = useDispatch();

    const openAdd = () => !adding && setAdding(true);
    const closeAdd = () => setAdding(false);
    const addAthlete = values => dispatch(HeatActions.addAthlete(eventId, heatId, { ...values, heat_id: heatId, position }, isTeams, values.event_division_id));

    const [{ isDragging, canDrop, monitorId: targetMonitorId }, drop] = useDropTarget({ ...props, adding, dispatch, accept: "athlete" });

    return (
        <th className={classnames("name", { "cannot-drop": isDragging && !canDrop })} onClick={openAdd} ref={drop} data-targetmonitorid={targetMonitorId}>
            {edit &&
            <>
                <IconButton className={adding ? "tiny close-add-athlete" : "tiny add-athlete"}
                            onClick={adding ? closeAdd : openAdd}>
                    {adding ? <Close/> : <PersonAdd />}
                </IconButton>

                {adding && <AddAthleteForm
                    teams={isTeams}
                    onSubmit={addAthlete}
                    eventDivisionId={eventDivisionId}
                    alreadyAdded={athletes?.map(c => c.get("id")).toArray()}
                />}
            </>}
        </th>
    );
};

const useStyles = makeStyles(theme => ({
    paddedContainer: {
        padding: theme.spacing(0, 2),
        maxWidth: "100vw",
        position: "sticky",
        left: 0,
    },
    widgetWithMargin: {
        margin: theme.spacing(1, 0),
    }
}));
export const ScanAndFilterPlaceWidgets = ({ showScanResultsWidget, heat, spots }) => {
    const classes = useStyles();
    return (
        <HidePrinting>
            <div className={classes.paddedContainer}>
                <div className={classes.widgetWithMargin}>
                    {showScanResultsWidget && <ScanResultsWidget heat={heat} spots={spots}/>}
                </div>
                <HeatFilterAndPlace heat={heat} spots={spots}/>
            </div>
        </HidePrinting>
    );
};

const EditHeatContainer = ({ events, heat, hideNames, eventId, edit, jerseyCellRenderer, toggleEdit, athleteCellNameRenderer, placingRenderer, rideRenderer, headJudgeTotalCell }) => {
    const { monitor } = useDragDropManager();
    const dragInProgress = !!monitor.getItem();

    const maximumPosition = (dragInProgress ? events.getIn(["stateBeforeDrag", "heats", eventId, heat.get("id")], Immutable.Map()) : heat)
        .get("athletes", Immutable.List()).map(a => a.get("position")).max() || 0;

    const transitions = { enter: 0, exit: 300 },
        runs = heat.get("runs", Immutable.List()),
        heatStarted = !!heat.get("start_time"),
        judgeCategoriesTotal = heat.getIn(["config", "judge_categories_total"]),
        categories = heat.getIn(["config", "categories"]),
        categoryNames = (categories || Immutable.Map()).sortBy((config, category) => config.get("order", category)).keySeq(),
        rides = heat.get("result").map(r => r.get("rides").valueSeq()),
        isTeams = heat.getIn(["config", "is_teams"]),
        appraiseTeamMembers = isTeams && heat.getIn(["config", "team_config", "appraisal_level"]) === "individual",
        isTiming = heat.getIn(["config", "calculator"]) === "timing",
        isPlacing = heat.getIn(["config", "calculator"]) === "placing" || isTiming,

        heatConfig = heat.get("config"),
        athleteRidesLimit = runs.size || heatConfig.get("athlete_rides_limit"),

        spots = resultsByAthletePosition(heat, maximumPosition + 1),
        maxRidesPerAthletePerTeam = spots.map(spot =>
            spot && (appraiseTeamMembers ? spot.getIn(["athlete", "athletes"]) : Immutable.fromJS([{ id: spot.getIn(["athlete", "id"]) }])).map(athlete =>
                spot.getIn(["result", "rides", "" + athlete.get("id")])
                    .count()
            )
        ),
        categoryJudges = rides
            .flatten(2)
            .map(r => (r.get("scores") || Immutable.Map()))
            .reduce((result, scores) => {
                scores
                    .forEach((scores, judge) =>
                        ((scores && scores.keySeq) ? scores.keySeq() : Immutable.List([""]))
                            .forEach(cat => {
                                result = result.update(judgeCategoriesTotal ? "" : cat, judges => (judges || Immutable.OrderedSet()).add(judge));
                            }));
                return result;
            }, Immutable.Map());

    return (
        <>
            {(heatStarted && isPlacing && !isTiming) && <ScanAndFilterPlaceWidgets showScanResultsWidget={heat.getIn(["config", "use_nfc"])} heat={heat} spots={spots} />}

            <Transition in={edit} timeout={transitions}>
                {transitionState => {
                    const editState = (transitionState === "entered" || transitionState === "exiting"),
                        tableSpots = (editState && spots.count() < maximumPosition + 2) ? spots.push(null) : spots;

                    return (
                        <JudgeCardTable className={classnames("judge-card", { "edit-names": editState, "hide-names": hideNames, "placing": isPlacing }, "transitions", `${transitionState}`)}
                                        isHeadJudge
                                        JerseyCellRenderer={jerseyCellRenderer}
                                        toggleEdit={toggleEdit}
                                        hideNames={hideNames}
                                        categoryNames={categoryNames}

                                        heat={heat}
                                        spots={tableSpots}
                                        AthleteCellNameRenderer={athleteCellNameRenderer}
                                        RideRenderer={isPlacing ? placingRenderer : rideRenderer}
                                        isPlacing={isPlacing}
                                        athleteRidesLimit={athleteRidesLimit}
                                        maxRidesPerAthletePerTeam={maxRidesPerAthletePerTeam}

                                        HeadJudgeTotalCell={headJudgeTotalCell}
                                        editState={editState}
                                        heatStarted={heatStarted}
                                        categoryJudges={categoryJudges}
                        />
                    );
                }}
            </Transition>
            <CustomDragLayer/>
        </>
    );
};

export class EditHeat extends PureComponent {
    state = {
        edit: false,
    };

    toggleEdit = () => this.setState({ edit: !this.state.edit });

    headJudgeTotalCell = ({ spot }) =>
        (
            spot &&
            !!this.props.heat.get("start_time") &&
            this.props.heat.getIn(["config", "calculator"]) !== "timing" &&
            (this.props.heat.getIn(["config", "calculator"]) !== "placing" || (this.props.heat.getIn(["config", "calculator"]) === "placing" && this.props.heat.getIn(["config", "team_config", "appraisal_level"]) === "individual"))
        ) &&
        <TotalCell total={spot.getIn(["result", "total"])}
                   winBy={spot.getIn(["result", "win_by"])}
                   needs={spot.getIn(["result", "needs"])}
                   maxRideScore={this.props.heat.getIn(["config", "max_ride_score"])}
                   heatFinished={!!this.props.heat.get("end_time")}/>;

    jerseyCellRenderer = ({ editState }) =>
        <th className="jersey fixed" onClick={this.toggleEdit}>
            {(!this.props.hideNames || editState) &&
            <span>
                {(this.props.heat.getIn(["config", "calculator"]) === "placing")
                    ? <T>start_order_header</T>
                    : "#"
                }
            </span>}

            {(this.props.hideNames && !editState) && <ToggleEditButton className="tiny" edit={editState}/>}
        </th>;

    athleteCellNameRenderer = ({ spot, editState, totalRides, position, isTeams }) =>
        spot ?
            <Athlete name={spot.getIn(["athlete", "name"])}
                     teamName={spot.getIn(["athlete", "team_name"])}
                     edit={editState}
                     hasRides={totalRides > 0}
                     heatId={this.props.heat.get("id")}
                     heatStarted={!!this.props.heat.get("start_time")}
                     eventId={this.props.eventId}
                     athleteId={spot.getIn(["athlete", "id"])}
                     isTeams={isTeams}
                     position={position}/> :

            <Empty edit={editState}
                   heatId={this.props.heat.get("id")}
                   eventDivisionId={this.props.heat.get("event_division_id")}
                   eventId={this.props.eventId}
                   isTeams={isTeams}
                   athletes={this.props.heat.get("athletes")}
                   position={position}/>;

    placingRenderer = ({ parentAthleteId, athleteId, ride, index }) =>
        <PlaceEditor
            heat={this.props.heat}
            rideIndex={index}
            eventId={this.props.eventId}
            parentAthleteId={parentAthleteId}
            athleteId={athleteId}
            ride={ride}/>;

    rideRenderer = ({ className, colSpan, athleteHeatId, teamPosition, athleteId, index, ride, heat,  jersey, athlete, categoryJudges }) =>
        <RideWrapper className={className}
                     colSpan={colSpan}
                     eventId={this.props.eventId}
                     heatId={this.props.heat.get("id")}
                     heatConfig={this.props.heat.get("config")}
                     athleteHeatId={athleteHeatId}
                     teamPosition={teamPosition}
                     athleteId={athleteId}
                     jersey={jersey}
                     bib={athlete.get("bib")}
                     athleteName={athlete.get("name")}
                     teamName={athlete.get("team_name")}
                     rideIndex={index}
                     ride={ride}
                     runs={this.props.heat.get("runs", Immutable.List())}
                     judgeCategoriesTotal={this.props.heat.getIn(["config", "judge_categories_total"])}
                     categoryJudges={categoryJudges}
                     categories={heat.getIn(["config", "categories"])}
                     maxRideScore={heat.getIn(["config", "max_ride_score"])}
                     adding={!ride} />;

    render = () =>
        <MultiDnD>
            <EditHeatContainer
                events={this.props.events}
                heat={this.props.heat}
                hideNames={this.props.hideNames}
                eventId={this.props.eventId}
                edit={this.state.edit}
                jerseyCellRenderer={this.jerseyCellRenderer}
                toggleEdit={this.toggleEdit}
                athleteCellNameRenderer={this.athleteCellNameRenderer}
                placingRenderer={this.placingRenderer}
                rideRenderer={this.rideRenderer}
                headJudgeTotalCell={this.headJudgeTotalCell}
            />
        </MultiDnD>;
}

export const DnDEditHeat = connect(({ events }, { heat, eventId }) => ({ events }))(EditHeat);
