import { Component } from "react";
import Immutable from "immutable";
import classnames from "classnames";
import { connect } from "react-redux";
import { WindowVirtualizedList } from "components/virtualized-list/WindowVirtualizedList";
import * as OrganisationActions from "../../actions/organisation";

import { Heading } from "../layout/page/Heading";
import { boxListClassName } from "../../styleguide/patterns/boxList";
import { nonWhitespacePattern, normalizeString, SearchRegExp } from "../../utils/utils";
import { NoSearchResults, SearchForm } from "../search/search";
import { T } from "../util/t";
import { ListItem } from "../layout/lists/ListItem";
import { OrganisationAvatar } from "../layout/avatar/OrganisationAvatar";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Meta } from "components/util/Meta";

const getFirstLetter = org => {
    const firstLetter = normalizeString(org.get("name").charAt(0).toUpperCase());
    return firstLetter.match(/[A-Z]/) ? firstLetter : "#";
};

export const OrganisationsList = connect(({ organisations }) => ({
    loaded: !!organisations.get("list"),
    organisations: organisations.get("list", Immutable.fromJS([
        { name: "BBR" }, { name: "NASA" }, { name: "Woonona" }
    ]))
}))(
    class OrganisationsList extends Component {
        state = { search: decodeURIComponent(this.props.location.search.replace("?search=", "")) };

        onSearchChange = search => {
            this.setState({ search });
            this.props.history.replace({ search: `?search=${encodeURIComponent(search)}` });
        };

        componentDidMount = () => this.props.dispatch(OrganisationActions.list());

        getOrgs = () => {
            const orgs = this.props.organisations.sort((a, b) => {
                if (getFirstLetter(a) === "#" && getFirstLetter(b) !== "#") return 1;
                if (getFirstLetter(b) === "#" && getFirstLetter(a) !== "#") return -1;
                return normalizeString(a.get("name").toUpperCase()) > normalizeString(b.get("name").toUpperCase()) ? 1 : -1;
            });

            const words = this.state.search.match(nonWhitespacePattern);
            if (this.props.loaded && words) {
                return words.reduce((orgs, word) => {
                    const searchRegex = SearchRegExp(word);

                    return orgs.filter(org => org.get("name").match(searchRegex));
                }, orgs);
            }

            return orgs.reduce((prev, current, index) => {
                const firstLetter = getFirstLetter(current);
                if (index === 0 || firstLetter !== getFirstLetter(prev.get(prev.size - 1))) return prev.push(Immutable.Map({ firstLetter }), current);
                return prev.push(current);
            }, Immutable.List());
        };

        render = () => {
            const { search } = this.state,
                { loaded } = this.props,
                orgList = this.getOrgs();

            return (
                <div className={classnames("organisations-list", { skeleton: !loaded })}>
                    <Meta title="Browse action sport competition organisers | Liveheats" description="Search for an action sport organisation or event on Liveheats. From local clubs to international federations, for surf, snow, skate, surf life saving and more." />
                    <Heading title="Find by organisation" maxWidth>
                        <SearchForm
                            className="search-component"
                            inHeading
                            search={search}
                            placeholder="Enter organisation name"
                            onChange={this.onSearchChange}
                        />
                    </Heading>

                    <section className={classnames({ "no-results-wrapper": orgList.isEmpty() })}>
                        {orgList.isEmpty() && (
                            <NoSearchResults
                                noResultsText={<T>Sorry, we can't seem to find that organisation 😞</T>}
                                clearResultsText={<T>Clear your search to view all organisations</T>}
                                onClear={() => this.setState({ search: "" })}
                                restrictMaxWidth
                            />
                        )}

                        <VirtualizedOrganisationsList search={search} list={orgList}/>
                    </section>
                </div>
            );
        };
    });

const VirtualizedOrganisationsList = ({ search, list }) => {
    const renderRow = (organisation, ref, index) => {
        const { firstLetter } = organisation;
        if (firstLetter) return <div ref={ref} className="first-letter">{firstLetter}</div>;
        return (
            <div ref={ref} className="organisation-item">
                {search && index === 0 && <div className="first-letter search"><T>Your search results</T></div>}
                <Link to={`/${organisation.short_name}`}>
                    <ListItem
                        title={organisation.name}
                        icon={<OrganisationAvatar alt={organisation.name} src={organisation.logo} size="xl"/>}
                        aside={<ChevronRight />}
                    />
                </Link>
            </div>
        );
    };

    return <WindowVirtualizedList
        items={list.toJS()}
        onLoadMore={() => {}}
        estimatedItemSize={90}
        renderItemComponent={renderRow}
        innerElementType="ul"
        rowElementType="li"
        className={boxListClassName(true)}
    />;
};
