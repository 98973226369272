import classnames from "classnames";
import { ButtonGroup, Button } from "@mui/material";
import { FormFieldProps } from "../FormField";
import { T } from "components/util/t";
import { FieldWithOptionsProps } from "components/forms/Field";

interface ToggleButtonProps {
    field: FieldWithOptionsProps;
    input: FormFieldProps["input"]
}

export const ToggleButton: React.FC<ToggleButtonProps> = ({ field, input }) => (
    <ButtonGroup aria-label={typeof field.label === "string" ? field.label : undefined} role="radiogroup">
        {field.options.map((option, index) => {
            const selected = input.value === option.value;
            return (
                <Button key={index} className={classnames({ selected })} role="radio" aria-checked={selected} onClick={() => input.onChange(option.value)}>
                    <T>{option.label}</T>
                </Button>
            );
        })}
    </ButtonGroup>
);
