import { Fake } from "components/layout/skeleton/Fake";
import { FakeFormField } from "components/layout/forms/FakeFormField";
import { MaxWidthPageWithHeading } from "components/layout/page/MaxWidthPageWithHeading";
import { Stack } from "@mui/material";

export const EditSeriesPageSkeleton = () => (
    <div className="skeleton" role="status" aria-label="loading">
        <MaxWidthPageWithHeading title={<Fake size={35}/>}>
            <Stack spacing={2} marginTop={4} direction="row">
                <Fake size={20}/>
                <Fake size={20}/>
                <Fake size={20}/>
            </Stack>
            <Stack marginTop={4}>
                <FakeFormField label={30} field={220}/>
                <FakeFormField label={30} field={220}/>
                <FakeFormField label={30} field={60}/>
                <FakeFormField label={30} field={220}/>
                <FakeFormField label={30} field={220}/>
            </Stack>
        </MaxWidthPageWithHeading>
    </div>
);
