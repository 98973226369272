import { createMakeStyles, CSSObject } from "tss-react";
import { Theme, useTheme } from "@mui/material";
import type { HasRequiredKeys } from "type-fest";

const { makeStyles: tssMakeStyles } = createMakeStyles({ useTheme });

type UseStylesReturnType<T> = keyof T extends never
    ? Record<string, string> 
    : { [k in keyof T]: string }

type IsPropsRequired<Props> = Props extends object ? HasRequiredKeys<Props> : false

type UseStyles<Props, T> = T extends infer R 
    ? keyof Props extends never
        ? () => UseStylesReturnType<R>
        : IsPropsRequired<Props> extends true
            ? (props: Props) => UseStylesReturnType<R>
            : (props?: Props) => UseStylesReturnType<R>
    : never

export function makeStyles<Props extends object = {}, T extends string = never>(
    styles: Record<T, CSSObject> | ((theme: Theme, props: Props) => Record<T, CSSObject>)
): UseStyles<Props, Record<T, string>> {
    const useStyles = tssMakeStyles<Props>()(styles);
    return ((props = {}) => useStyles(props as Props).classes) as never;
}
