import { Paper, Stack, Typography } from "@mui/material";
import { EventStatus } from "./EventStatus";
import { EventDivisions } from "./EventDivisions";
import { EventSeries } from "./EventSeries";
import { OrganisationAvatar } from "../../layout/avatar/OrganisationAvatar";
import { T } from "../../util/t";
import { Event } from "models/Event";
import { useFeaturedEventsStyles } from "./useFeaturedEventsStyles";
import { Event as RawEvent } from "graphql/generated/types";
import { SponsorTags } from "../../layout/sponsors/SponsorTags";

export const EventCard = ({ event: rawEvent }: {event: RawEvent}) => {
    const classes = useFeaturedEventsStyles();
    const event = Event(rawEvent);
    const sponsoredContents = event.sponsoredContents;

    return(
        <Paper variant="outlined" className={classes.card}>
            <Stack spacing={0.5} direction="column">
                <Stack spacing={1} direction="column" padding={2} className={classes.mainSection}>
                    <Typography variant="label2" textTransform="uppercase" color="text.secondary">
                        {event.eventDate(true)}
                    </Typography>
                    <Typography variant="h5" color="text.primary" className={classes.eventName}>
                        {event.name}
                    </Typography>
                    <EventStatus status={event.status}/>
                    <EventDivisions sportType={event.organisation.sportType} divisions={event.eventDivisions?.map(eventDivision => eventDivision.division)}/>
                </Stack>
                <EventSeries series={event.rankingsSeries()}/>
                <EventDetailSectionWrapper>
                    <Typography variant="body2" color="text.light"><T>By</T></Typography>
                    {/* @ts-expect-error remove when component gets TSified */}
                    <OrganisationAvatar alt={event.organisation.name} src={event.organisation.logo} size="sm" />
                    <Typography variant="body2" color="text.primary">{event.organisation.name}</Typography>
                </EventDetailSectionWrapper>
                {sponsoredContents && sponsoredContents.length > 0 &&
                    <EventDetailSectionWrapper>
                        <SponsorTags sponsoredContents={sponsoredContents}/>
                    </EventDetailSectionWrapper>
                }
            </Stack>
        </Paper>
    );
};

export const EventDetailSectionWrapper = ({ children }: { children: React.ReactNode }) => {
    const classes = useFeaturedEventsStyles();

    return (
        <Stack direction="row" spacing={.5} className={classes.detailSection}>
            {children}
        </Stack>
    );
};
