import classnames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { Container, Drawer, IconButton, Link } from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import { makeStyles } from "components/providers/makeStyles";
import { useDispatch } from "react-redux";
import debounce from "lodash/debounce";

import { addCategoryScore } from "../../../actions/judge";
import { ScoreRenderer } from "./ScoreRenderer";
import { useFormKit } from "../../forms/useFormKit";
import { scoreParser } from "../../forms/parsers/scoreParser";
import { FormWrapper } from "../../layout/forms/FormWrapper";
import { JudgingAthleteModalHeader } from "../../layout/judging/JudgingAthleteModalHeader";
import { RideCategory } from "../../layout/judging/RideCategory";
import { string } from "yup";
import { useFormState } from "react-hook-form";

export const TIMEOUT = 1000;

export const CategoryScoreDrawer = ({ heatConfig, score, modifier, rideCategory, isEditLocked, open, handleClose, currentlyDebouncingSubmit, setDebouncedSubmit, ...rest }) => {
    const dispatch = useDispatch();
    const [error, setError] = useState(false);

    const submit = (values) => {
        const { eventId, dispatchHeatId, heatId, rideIndex, judgeId, parentAthleteId } = rest;
        currentlyDebouncingSubmit && currentlyDebouncingSubmit.cancel();
        debouncedSubmit.cancel();
        return dispatch(addCategoryScore(eventId, heatId, dispatchHeatId, rideIndex, judgeId, parentAthleteId, values))
            .then(
                () => setError(false),
                () => {
                    setError(true);
                    setDebouncedSubmit(debouncedSubmit);
                    debouncedSubmit(values);
                });
    };
    const debouncedSubmit = debounce(submit, TIMEOUT);

    const onSubmit = (values) => {
        handleClose();
        return submit(values);
    };

    const fields = useMemo(() => [
        {
            name: "ride_category",
            label: "Category",
            type: "chipSelect",
            options: heatConfig.getIn(["category_groups", "categories"]).map(c => ({ label: c, value: c })).toArray(),
            validation: string().required("We need the score category 😉")
        },
        {
            name: "score",
            label: "Score",
            type: "number",
            inputProps: { pattern: "[0-9]*", step: "any" },
            parse: scoreParser(heatConfig.get("max_ride_score"))
        }
    ], [heatConfig]);

    const initialValues = {
        athlete_heat_id: rest.athleteHeatId,
        team_position: rest.teamPosition,
        athlete_id: rest.athleteId,
        ride: rest.dispatchRideIndex,
        score: score,
        ride_category: rideCategory
    };

    const { control, handleSubmit, components } = useFormKit({ fields, initialValues });
    const { isSubmitting } = useFormState({ control });

    return (
        <Form
            control={control}
            fieldComponents={components}
            score={score}
            modifier={modifier}
            error={error}
            rideCategory={rideCategory}
            isEditLocked={isEditLocked}
            submitting={isSubmitting}
            handleSubmit={handleSubmit(onSubmit)}
            open={open}
            handleClose={handleClose}
            heatConfig={heatConfig}
            athleteName={rest.athleteName}
            teamName={rest.teamName}
            jersey={rest.jersey}
            bib={rest.bib}
            rideIndex={rest.rideIndex}
        />
    );
};

export const Form = ({
    control,
    fieldComponents,
    score,
    modifier,
    error,
    rideCategory,
    isEditLocked,
    submitting,
    handleSubmit,
    open,
    handleClose,
    heatConfig,
    athleteName,
    teamName,
    jersey,
    bib,
    rideIndex
}) =>
    <>
        {(score || modifier) ?
            <div className={classnames("judge-total", { submitting, error })}>
                <RideCategory category={rideCategory}/>
                <ScoreRenderer isEditLocked={isEditLocked} score={score}/>
            </div> :
            <IconButton>
                <Add/>
            </IconButton>
        }

        <Drawer open={open} anchor="bottom" onClose={handleClose} slotProps={{
            paper: { square: false }
        }}>
            <DrawerContent
                control={control}
                fieldComponents={fieldComponents}
                athleteName={athleteName}
                teamName={teamName}
                jersey={jersey}
                bib={bib}
                rideIndex={rideIndex}
                handleClose={handleClose}
                handleSubmit={handleSubmit}
                heatConfig={heatConfig}
            />
        </Drawer>
    </>;

const useFormStyles = makeStyles(theme => ({
    close: {
        marginLeft: "auto",
    },
    container: {
        "& form > div": {
            marginBottom: theme.spacing(-1),
        }
    },
    header: {
        display: "flex",
        margin: theme.spacing(2, 0, 3),
        alignItems: "flex-start",
    },
    button: {
        width: "100%"
    }
}));
export const DrawerContent = ({ control, fieldComponents, heatConfig, handleClose, athleteName, teamName, jersey, bib, rideIndex, handleSubmit }) => {
    const classes = useFormStyles();
    const onClose = useCallback(e => {
        e?.stopPropagation();
        handleClose();
    }, []);

    return (
        <Container>
            <section className={classes.header}>
                <JudgingAthleteModalHeader name={athleteName} team={teamName} jersey={jersey} bib={bib} ride={rideIndex} runBased={heatConfig.get("run_based")}/>

                <Link className={classes.close} underline="none" onClick={onClose}><Close/></Link>
            </section>

            <FormWrapper
                control={control}
                handleSubmit={handleSubmit}
                classNames={{ button: classes.button }}
                buttonLabel="Save"
            >
                {fieldComponents}
            </FormWrapper>
        </Container>
    );
};
