import { IconButton } from "@mui/material";
import { useTranslate } from "../providers/LocalizationProvider";

export const LHIconButton = ({ title, funcTitleProps, children, ...rest }) => {
    const translatedText = useTranslate(title, funcTitleProps);

    return (
        <IconButton {...rest} title={translatedText}>
            {children}
        </IconButton>
    );
};
