import { gql } from "@apollo/client";

export const MediaFragment = gql`
  fragment Media on Media {
    id
    url
    type
    width
    height
  }
`;
