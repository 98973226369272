import { useCallback } from "react";
import { api } from "../../../../../actions/api";
import { useError, useSuccess } from "hooks/useNotifications";
import { useDialogState } from "../../../../../hooks/useDialogState";
import { ImportButton } from "./ImportButton";

export const ImportAthletesButton = ({ closeMenu }) => {
    const { notifySuccess } = useSuccess();
    const { notifyError } = useError();
    const [dialogOpen, openDialog, closeDialog] = useDialogState({ disableBackdropClick: true });

    const onClose = useCallback(() => {
        closeMenu();
        closeDialog();
    }, []);

    const onImport = (eventId, entries) => () =>
        api.post(`events/${eventId}/entries/import_event_entries`, { entries })
            .then(
                () => {
                    notifySuccess(`Bueno! ${entries.length} entries have been successfully imported.`);
                    onClose();
                },
                e => {
                    notifyError("Oops, something went wrong!");
                    onClose();
                }
            );

    return (
        <ImportButton title="Import entries"
                      onImport={onImport}
                      onClose={onClose}
                      closeMenu={closeMenu}
                      confirmationDialogOpen={dialogOpen}
                      openConfirmationDialog={openDialog}
                      confirmationTitle="Import entries?"
                      confirmationContent="ready_to_import_entries"
                      confirmationButtonTitle="Import entries"
                      badImportContent="cannot_import_entries_bad_divisions"
                      withResults={false} />
    );
};
