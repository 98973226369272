import Immutable from "immutable";
import { useState } from "react";
import { connect } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { Box, Menu, MenuItem, Typography } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { gql, useApolloClient, useQuery } from "@apollo/client";

import { newManageEventPagesPath } from "components/application";
import * as EventDirectorActions from "actions/eventDirector";
import { EventStatusPill } from "components/layout/event/EventStatusPill";
import { T } from "components/util/t";
import { GET_EVENT } from "graphql/event";
import { makeStyles } from "../../providers/makeStyles";
import { EVENT_STATUSES } from "../../layout/event/EVENT_STATUSES";

const useStyles = makeStyles((theme) => ({
    eventName: {
        "&&": {
            display: "inline",
            padding: theme.spacing(2),
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }
    }
}));

export const EventStatus = connect(({ events }, { eventId }) => ({ event: events.getIn(["mini", parseInt(eventId)]) }))(({ event, dispatch, eventId }) => {
    const [menu, setMenu] = useState();
    const openMenu = (e) => setMenu(e.currentTarget);
    const closeMenu = () => setMenu(null);
    const client = useApolloClient();

    const updateStatus = (newStatus) => {
        client.writeFragment({
            id: `Event:${eventId}`,
            fragment: gql`
                fragment CachedEvent on Event {
                    status
                }
            `,
            data: {
                status: newStatus
            }
        });
        dispatch(EventDirectorActions.updateStatus(eventId, newStatus));
        closeMenu();
    };

    const classes = useStyles();

    const { data } = useQuery(GET_EVENT, { variables: { id: eventId }, fetchPolicy: "cache-only" });
    if (useRouteMatch(newManageEventPagesPath) && data?.event) event = Immutable.fromJS(data.event);

    if (!event) return null;

    return (
        <Box display="flex" alignItems="center" pr={2} minWidth={0}>
            <Typography className={classes.eventName} variant="h5" component={Link} to={`/events/${eventId}/dashboard`}>
                {event.get("name")}
            </Typography>
            <EventStatusPill status={event.get("status")} onClick={openMenu}>
                <KeyboardArrowDown />
            </EventStatusPill>

            <Menu anchorEl={menu} open={!!menu} onClose={closeMenu}>
                <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}><T>event status</T></Typography>

                {Object.keys(EVENT_STATUSES).map(key =>
                    <MenuItem key={key} selected={key === event.get("status")} onClick={() => updateStatus(key)}>
                        <EventStatusPill status={key}/>
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
});
