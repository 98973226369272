import { Link } from "react-router-dom";
import { EventCard } from "components/routes/root/EventCard";
import { ScrollWithShadows } from "components/layout/utils/ScrollWithShadows";
import { Box, Stack, useTheme } from "@mui/material";
import { LiveIndicator } from "components/layout/organisation/LiveIndicator";
import { T } from "components/util/t";
import { Section } from "./HomePage";
import { gql, useQuery } from "@apollo/client";
import chunk from "lodash/chunk";
import { FeaturedEventsSkeleton } from "./FeaturedEventsSkeleton";
import { eventCardWidth } from "./useFeaturedEventsStyles";
import { SponsoredContentsFragment } from "../../layout/sponsors/SponsorContent";

export const GET_FEATURED_EVENTS = gql`
  query getFeaturedEvents {
    featuredEvents {
        id
        name
        status
        date
        daysWindow
        eventDivisions {
          id
          division {
            id
            name
          }
        }
        series {
          id
          name
          rankingsDivisions {
            id
          }
        }
        organisation {
          id
          name
          logo
          sportType
        }
        ...SponsoredContents
    }
  }
  ${SponsoredContentsFragment}
`;
export const FeaturedEvents = () => {
    const theme = useTheme();
    const { data } = useQuery(GET_FEATURED_EVENTS),
        eventPairs = chunk(data?.featuredEvents || [], 2);

    if (!data) return <FeaturedEventsSkeleton />;

    const eventCartdWidth = parseInt(eventCardWidth(theme));
    const eventCardPadding = parseInt(theme.spacing(2));
    const eventCardWidthPadding = eventCartdWidth + eventCardPadding;

    return (
        <Section marginTop={4}>
            <Stack direction="row" spacing={1} marginBottom={2} paddingX={2}>
                <Box color="status.livePulse" paddingTop={2}>
                    <LiveIndicator animated={false} size="md" />
                </Box>
                <Stack direction="column" color="text.primary">
                    <T>live_now_heading</T>
                </Stack>
            </Stack>
            <ScrollWithShadows shadowsColor="background.default" showButtons buttonsPositionAbsolute fixedScrollOffset={eventCardWidthPadding}>
                <Stack paddingX={2} spacing={2} direction="row">
                    {eventPairs.map((events, pairIndex) =>
                        <Stack key={pairIndex} direction="column" spacing={2}>
                            {events.map((event, index) =>
                                <Link key={index} to={`/events/${event.id}`}>
                                    <EventCard event={event} />
                                </Link>
                            )}
                        </Stack>
                    )}
                </Stack>
            </ScrollWithShadows>
        </Section>
    );
};
