import { Link } from "react-router-dom";

export const TermsAndConditionsWordsEN = () => (
    <>
        <h2>User Terms and Conditions</h2>

        <p>
            Thank you for using Liveheats. This website is owned and operated by LiveHeats Pty Ltd (ABN
            73612699564). By accessing and/or using this website and related services, you agree to these
            Terms and Conditions, which include our <Link to="/privacy_policy">Privacy Policy</Link> (<b><i>Terms</i></b>).
            You should review our Privacy Policy and these Terms carefully and immediately cease using our
            website if you do not agree to these Terms.
        </p>

        <p>
            In these Terms, 'us', 'we' and 'our' means LiveHeats Pty Ltd
        </p>

        <h3>Registration</h3>

        <p>
            You may need to be a registered member to access certain features of our website, such as
            membership sign on, contest entry, athlete dashboards.
        </p>

        <p>
            When you register and activate your account, you will provide us with personal information such
            as your name, email address, which club you are a member of, and your division. You must ensure
            that this information is accurate and current. We will handle all personal information we
            collect in accordance with our <Link to="/privacy_policy">Privacy Policy</Link>.
        </p>

        <p>
            When you register and activate your account, you will be able to access your account through
            Facebook credentials, or your email address and password. You are responsible for keeping this
            user name and password secure and are responsible for all use and activity carried out under
            this account.
        </p>

        <p>
            If you are under the age of 18, you represent that you have reviewed these Terms with your
            parent or legal guardian to make sure that you and your parent or legal guardian understand
            these Terms.
        </p>

        <p>
            We may ask you to confirm that you have your parent's or guardian's permission and that they
            have agreed to these Terms on your behalf, and, even if we do not do this, we will assume that
            this is the case and will provide access to our website and your account on this basis.
        </p>

        <h3>Collection Notice</h3>

        <p>
            We collect information about you in order to determine your club, and division, and ensure
            scores and results can be matched to your user account, and for purposes otherwise set out in
            our <Link to="/privacy_policy">Privacy Policy</Link>.
        </p>

        <p>
            We may disclose that information to third parties that help us deliver our services (including
            information technology suppliers, communication suppliers and our business partners) or as
            required by law.
        </p>

        <p>
            Our <Link to="/privacy_policy">Privacy Policy</Link> explains: (i) how we store and use, and how
            you may access and correct your personal information; (ii) how you can lodge a complaint
            regarding the handling of your personal information; and (iii) how we will handle any complaint.
            If you would like any further information about our privacy policies or practices, please
            contact us at <a href="mailto:chris@liveheats.com">chris@liveheats.com</a>.
        </p>

        <p>
            By providing your personal information to us, you consent to the collection, use, storage and
            disclosure of that information as described in the Privacy Policy and these Terms.
        </p>

        <h3>Accuracy, completeness and timeliness of information</h3>

        <p>
            While we use all reasonable attempts to ensure the accuracy and completeness of the information
            relating to athletes, club, and competitions, to the extent permitted by law, including the
            Australian Consumer Law, we make no warranty regarding the information on this website. You
            should monitor any changes to the information contained on this website.
        </p>

        <p>
            We are not liable to you or anyone else if interference with or damage to your computer systems
            occurs in connection with the use of this website or a linked website. You must take your own
            precautions to ensure that whatever you select for your use from our website is free of viruses
            or anything else (such as worms or Trojan horses) that may interfere with or damage the
            operations of your computer systems.
        </p>

        <p>
            We may, from time to time and without notice, change or add to the website (including the Terms)
            or the information, products or services described in it. However, we do not undertake to keep
            the website updated. We are not liable to you or anyone else if errors occur in the information
            on the website or if that information is not up-to-date.
        </p>

        <h3>Linked sites</h3>

        <p>
            Our website may contain links to websites operated by third parties. Those links are provided
            for convenience and may not remain current or be maintained. Unless expressly stated otherwise,
            we do not endorse and are not responsible for the content on those linked websites and have no
            control over or rights in those linked websites.
        </p>

        <h3>Intellectual property rights</h3>

        <p>
            Unless otherwise indicated, we own or license from third parties all rights, title and interest
            (including copyright, designs, patents, trademarks and other intellectual property rights) in
            this website and in all of the material (including all text, graphics, logos, audio and
            software) made available on this website (<b><i>Content</i></b>)
        </p>

        <p>
            Your use of this website and use of and access to any Content does not grant or transfer any
            rights, title or interest to you in relation to this website or the Content. However we do grant
            you a licence to access the website and view the Content on the terms and conditions set out in
            this Agreement and, where applicable, as expressly authorised by us and/or our third party
            licensors.
        </p>

        <h3>Warranties and disclaimers</h3>

        <p>
            To the maximum extent permitted by law, including the Australian Consumer Law, we make no
            warranties or representations about this website or the Content, including but not limited to
            warranties or representations that they will be complete, accurate or up-to-date, that access
            will be uninterrupted or error-free or free from viruses, or that this website will be secure.
        </p>

        <p>
            We reserve the right to restrict, suspend or terminate without notice your access to this
            website, any Content, or any feature of this website at any time without notice and we will not
            be responsible for any loss, cost, damage or liability that may arise as a result.
        </p>

        <h3>Liability</h3>

        <p>
            To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall
            we be liable for any direct and indirect loss, damage or expense – irrespective of the manner in
            which it occurs – which may be suffered due to your use of our website and/or the information or
            materials contained on it, or as a result of the inaccessibility of this website and/or the fact
            that certain information or materials contained on it are incorrect, incomplete or not
            up-to-date.
        </p>

        <h3>Jurisdiction and governing law</h3>

        <p>
            Your use of the website and these Terms are governed by the law New South Wales, Australia and
            you submit to the non-exclusive jurisdiction of the courts exercising jurisdiction in New South
            Wales.
        </p>
    </>
);
