import { Avatar } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import classnames from "classnames";
import { firstLetters } from "components/util/firstLetters";
import { LoadingOptimizedImage } from "components/elements/LoadingOptimizedImage";
import { AvatarSize, sizes } from "components/layout/avatar/avatarSizes";

interface StyleProps {
    size: AvatarSize;
}

const useStyles = makeStyles((_theme, { size }: StyleProps) => ({
    avatar: {
        width: sizes[size],
        height: sizes[size]
    }
}));

interface AvatarOrInitialsProps {
    size?: AvatarSize;
    className?: string;
    alt: string;
    src?: string;
}

export const AvatarOrInitials: React.FCWithChildren<AvatarOrInitialsProps> = ({ children, className, alt, src, size = "md" }) => {
    const classes = useStyles({ size });

    return (
        <LoadingOptimizedImage component={Avatar} className={classnames(classes.avatar, className)} alt={alt} src={src} width={sizes[size]} role={src ? undefined : "img"} aria-label="avatar">
            {children || firstLetters(alt)}
        </LoadingOptimizedImage>
    );
};
