import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { Button, darken, Typography } from "@mui/material";
import { Facebook } from "@mui/icons-material";
import { useFacebook } from "components/providers/FacebookProvider";

import * as UserActions from "../actions/user";
import { T } from "./util/t";
import { makeStyles } from "./providers/makeStyles";

export const OrFacebookLogin = props => {
    const FB = useFacebook();

    return FB ? <OrFacebookLoginButton FB={FB} {...props}/> : null;
};

const useStyles = makeStyles(theme => ({
    button: {
        background: theme.palette.random.fb,
        color: theme.palette.text.contrastText,
        "&:hover": {
            background: darken(theme.palette.random.fb, .1)
        }
    }
}));
const OrFacebookLoginButton = ({ label, onClick, FB, onLogin, onError }) => {
    const dispatch = useDispatch();
    const classes = useStyles();

    const wrappedOnClick = useCallback((e) => {
        const errorOccured = onClick(e);
        if (errorOccured) {
            return;
        }

        return new Promise((resolve, reject) => {
            FB.login((response) => {
                if (response.authResponse) {
                    resolve(dispatch(UserActions.registerFacebook()));
                } else {
                    reject(response);
                }
            }, { scope: "public_profile,email" });
        })
            .then(response => {
                if (response && response.data && !response.data.omniauth_callback) {
                    onLogin(response);
                } else {
                    onError(response);
                }
            })
            .catch(onError);
    }, [onClick]);

    return (
        <>
            <div className="hr-text">
                <Typography color="textSecondary" component="span" className="text"><T>or</T></Typography>
            </div>

            <Button className={classes.button} variant="contained" onClick={wrappedOnClick} startIcon={<Facebook/>} fullWidth>
                <T>{label}</T>
            </Button>
        </>
    );
};
