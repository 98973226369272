import { useCallback } from "react";

export const useMergeRefs = (...refs) => useCallback(node => {
    refs.forEach(ref => {
        if (typeof ref === "function") {
            ref(node);
        } else if (ref != null) {
            ref.current = node;
        }
    });
}, [...refs]);
