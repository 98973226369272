import { makeStyles } from "components/providers/makeStyles";


export const useFormFieldStyles = makeStyles((theme, { width }: { width?: number; }) => ({
    field: {
        marginBottom: theme.spacing(2),
    },
    label: {
        display: "block",
        marginBottom: theme.spacing(1),
    },
    checkboxLabel: {
        display: "inline",
        verticalAlign: "super",
        paddingLeft: theme.spacing(1)
    },
    help: {
        marginBottom: theme.spacing(1),
    },
    inputWrapper: {
        ".MuiAutocomplete-root": {
            backgroundColor: theme.palette.background.paper,
        },
        position: "relative",
        width: width,
        "&&": {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        }
    },
    locked: {
        position: "absolute",
        top: 41,
        right: 10,
    }
}));

export const useMessageStyles = makeStyles(theme => ({
    message: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.spacing(3),
        minHeight: theme.spacing(3),
        "&::first-letter": {
            textTransform: "uppercase"
        }
    },
    errorMessage: {
        color: theme.palette.input.error,
    },
    successMessage: {
        color: theme.palette.input.success,
    }
}));
