import { dynamicImportWithRetry } from "@fatso83/retry-dynamic-import";
import { useEffect, useRef, useState } from "react";
import { useError } from "hooks/useNotifications";
import { useCancellableDebounce } from "hooks/useCancellableDebounce";
import GoogleLogo from "../../../../../assets/icons/google_on_white_hdpi.png";
import { PaperComponent } from "components/layout/forms/fields/Autocomplete/SearchSelect";
import { createFilterOptions, Stack } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import dayjs from "dayjs";
import { gql, useLazyQuery } from "@apollo/client";
import { usePrevious } from "hooks/usePrevious";
import { stripTypename } from "@apollo/client/utilities";
import { useFirstRender } from "hooks/useFirstRender";

export const API_KEY = "AIzaSyBQI1wnQnFEYziAp3aSL8-3Wt37YRAUXB8";

const useStyles = makeStyles(theme => ({
    logoContainer: {
        color: theme.palette.text.lighter,
        font: "Roboto",
        fontSize: theme.typography.caption.fontSize,
        fontWeight: 500,
        margin: theme.spacing(0, 1, 0.75),
        alignItems: "center",
        justifyContent: "end",
    },
    logo: {
        height: theme.spacing(2),
        paddingTop: theme.spacing(0.25),
    }
}));

export const GET_TIMEZONE_DETAILS = gql`
    query getTimezone($longitude: Float!, $latitude: Float!, $date: ISO8601DateTime) {
        timezone(longitude: $longitude, latitude: $latitude, date: $date) {
            name
            utcOffsetMinutes
            date
        }
    }
`;

export const useLocationField = ({ value, dateField, setValue, getValue, getInitialValueLabel, name }) => {
    const places = useRef();
    const sessionToken = useRef();
    const pendingSearch = useRef();
    const { notifyError } = useError();
    const [clearOnBlur, setClearOnBlur] = useState(false);
    const previousDate = usePrevious(dateField);
    const firstRender = useFirstRender();
    const [validationPromise, setValidationPromise] = useState(Promise.resolve({}));

    const [getTimezone] = useLazyQuery(GET_TIMEZONE_DETAILS);

    useEffect(() => {
        dynamicImportWithRetry(() => import("@googlemaps/js-api-loader")).then(({ Loader }) => {
            new Loader({ apiKey: API_KEY, retries: 5 }).importLibrary("places").then(library => {
                places.current = library;
                if (pendingSearch.current) search(pendingSearch.current);
            });
        });
    }, []);

    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState(() => value ? [{ label: getInitialValueLabel(value), value }] : []);

    const search = async input => {
        setLoading(true);

        if (!places.current) return pendingSearch.current = input;

        const { AutocompleteSessionToken, AutocompleteSuggestion } = places.current;
        if (!sessionToken.current) sessionToken.current = new AutocompleteSessionToken();
        setOptions([]);

        const request = { input, sessionToken: sessionToken.current };

        return AutocompleteSuggestion.fetchAutocompleteSuggestions(request).then(({ suggestions }) => {
            setLoading(false);
            setOptions(suggestions.map(({ placePrediction }) => ({ label: placePrediction.text.toString(), value: placePrediction })));
        }).catch(() => {
            notifyError("Oops, something went wrong!");
        });
    };
    const debouncedSearch = useCancellableDebounce(search, 400);

    const setValueAndResolvePromise = (value, resolve) => {
        setValue(value);
        resolve({ [name]: value });
    };

    useEffect(() => {
        const isPlacePrediction = typeof value?.toPlace === "function";

        if (isPlacePrediction) {
            setValidationPromise(new Promise(resolve => {
                value.toPlace().fetchFields({
                    fields: ["formattedAddress", "utcOffsetMinutes", "location", "googleMapsURI", "addressComponents"],
                }).then(response => {
                    const json = response.place.toJSON();
                    const { googleMapsURI, utcOffsetMinutes, addressComponents, ...rest } = json;
                    const countryComponent = addressComponents?.find(component => component.types.includes("country"));
                    setLocationAndTimezone({
                        ...rest,
                        googleMapsUri: googleMapsURI,
                        utcOffsetMinutes: utcOffsetMinutes || 0,
                        placePredictionText: value.text.toString(),
                        country: countryComponent?.longText,
                        countryCode: countryComponent?.shortText
                    }).then(resolve);
                    sessionToken.current = null;
                }).catch(() => {
                    notifyError("Oops, something went wrong!");
                    setValueAndResolvePromise(null, resolve);
                });
            }));
        }
    }, [value?.placeId]);

    useEffect(() => {
        const selectedLocation = getValue(value);
        if (selectedLocation && dateField !== previousDate && !firstRender) setValidationPromise(setLocationAndTimezone(selectedLocation));
    }, [dateField, previousDate]);

    const setLocationAndTimezone = selectedLocation => {
        const lat = selectedLocation.location?.lat,
            lng = selectedLocation.location?.lng;

        if (!lat || !lng) return new Promise(resolve => setValueAndResolvePromise(selectedLocation, resolve));

        const date = dateField ? dayjs(dateField) : dayjs();

        return new Promise(resolve => {
            getTimezone({ variables: { longitude: lng, latitude: lat, date: date.toISOString() } })
                .then(({ data: { timezone } }) => setValueAndResolvePromise({ ...selectedLocation, timezone: stripTypename(timezone) }, resolve))
                .catch(() => setValueAndResolvePromise(selectedLocation, resolve));
        });
    };

    const onInputChange = (e, value, reason) => {
        setClearOnBlur(true);
        if (!["selectOption", "reset"].includes(reason)) {
            setValue(null);
            if (value) return debouncedSearch(value);
        }
        if(["selectOption", "reset"].includes(reason)){
            setClearOnBlur(false);
        }
        setOptions([]);
    };

    const classes = useStyles();
    const footer = (
        <Stack direction="row" spacing={0.25} alignItems="center" className={classes.logoContainer}>
            <p>powered by</p>
            <img className={classes.logo} src={GoogleLogo} alt="Google Logo" />
        </Stack>
    );

    const filterOptions = (options, state) => {
        const filtered = createFilterOptions({ trim: true })(options, state);

        if (filtered.length === 0) {
            filtered.push({
                label: "No options",
                disabled: true,
            });
        }

        return filtered;
    };

    return {
        loading,
        options,
        error: "Search for a place or address, and choose from the list.",
        autocompleteProps: { onInputChange, filterOptions, clearOnBlur, freeSolo: true, slots: { paper: (props) => PaperComponent({ footer, ...props }) } },
        validationPromise
    };
};
