import { Info, MoreHoriz } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Notification } from "components/layout/notifications/Notification";
import { DayBox } from "components/routes/events/id/registration/DayBox";
import { T } from "components/util/t";
import dayjs from "dayjs";
import { Event } from "models/Event";
import { dateTimeInTimezone, ISO_DATE, SHORT_DATE_AND_TIME } from "utils/dates";
import { formatTimezone, timezoneDate } from "utils/utils";

const TIME_INTERVALS = 5;
const roundToClosestInterval = date => new Date(Math.ceil(dayjs(date).add(1, "second").valueOf() / (1000 * 60 * TIME_INTERVALS)) * (1000 * 60 * TIME_INTERVALS));
const formatDate = (date, utcOffset) => date && new Date(dateTimeInTimezone(date, utcOffset));

export const EntryDates = ({ location, scheduledEntryOpen, scheduledEntryClose, setValue, DateFieldComponent }) => {
    const onChange = field => time => setValue(field, time ? timezoneDate(time, utcOffset).toISOString() : time, { shouldDirty: true });
    const isLocationSet = !!location;

    const sameTimeOpenClose = scheduledEntryOpen && scheduledEntryClose && scheduledEntryOpen === scheduledEntryClose;
    const closeBeforeOpen = scheduledEntryOpen && scheduledEntryClose && scheduledEntryOpen > scheduledEntryClose;
    const displayWarning = !isLocationSet || sameTimeOpenClose || closeBeforeOpen;

    const eventData = Event({ location }),
        utcOffset = eventData.utcOffset(),
        eventTimezone = eventData.timezone();
    const now = formatDate(new Date(), utcOffset);
    const dateInputProps = {
        minDate: now,
        minTime: selected => dayjs(selected).format(ISO_DATE) === dayjs(now).format(ISO_DATE) && roundToClosestInterval(now),
        maxTime: selected => dayjs(selected).format(ISO_DATE) === dayjs(now).format(ISO_DATE) && dayjs(now).endOf("day").toDate(),
        timeIntervals: TIME_INTERVALS
    };

    return (
        <>
            <Stack direction="row" alignItems="center">
                <DayBox
                    title="Entry opens"
                    dialogTitle={scheduledEntryOpen ? "Edit date for entry open" : "Add date for entry open"}
                    value={formatDate(scheduledEntryOpen, utcOffset)}
                    onChange={onChange("scheduled_entry_open")}
                    disabled={!isLocationSet}
                    dateInputProps={{ ...dateInputProps, initialValue: formatDate(scheduledEntryOpen, utcOffset) || roundToClosestInterval(now) }}
                    DateFieldComponent={DateFieldComponent}
                />

                <Typography color="text.light" display="flex">
                    <MoreHoriz color="inherit" />
                </Typography>

                <DayBox
                    title="Entry closes"
                    dialogTitle={scheduledEntryClose ? "Edit date for entry close" : "Add date for entry close"}
                    value={formatDate(scheduledEntryClose, utcOffset)}
                    onChange={onChange("scheduled_entry_close")}
                    disabled={!isLocationSet}
                    dateInputProps={{ ...dateInputProps, initialValue: formatDate(scheduledEntryClose, utcOffset) || roundToClosestInterval(now) }}
                    DateFieldComponent={DateFieldComponent}
                />
            </Stack>

            <Typography variant="caption" color="text.secondary" marginBottom={displayWarning ? 1 : 0} display="inline-block">
                {isLocationSet ? <T timezone={formatTimezone(utcOffset, eventTimezone)}>event_timezone</T> : <T>event_timezone_required_location</T>}
            </Typography>

            {sameTimeOpenClose && <Notification Icon={Info} type="error" text="Registration can't open and close at the same time. Adjust the dates to allow more time for registration." />}
            {closeBeforeOpen && <Notification Icon={Info} type="warning" text={<T open={dayjs(scheduledEntryOpen).format(SHORT_DATE_AND_TIME).replace(",", "")} close={dayjs(scheduledEntryClose).format(SHORT_DATE_AND_TIME).replace(",", "")}>warning_registration_open_after_close</T>} />}
            {!isLocationSet && <Notification Icon={Info} type="info" text="missing_location_entry_dates_warning" />}
        </>
    );
};
