import { makeStyles } from "components/providers/makeStyles";
import { usePrevious } from "hooks/usePrevious";
import { useLayoutEffect, useState } from "react";
import { OptimizedImageType, OptimizedImage } from "./OptimizedImage";
import { CircularProgress } from "@mui/material";

const useSpinnerStyles = makeStyles(theme => ({
    spinner: {
        color: theme.palette.background.high,
        maxHeight: "100%",
        maxWidth: "100%",
    }
}));

export const Spinner: React.FC<{ size?: number }> = ({ size }) => {
    const classes = useSpinnerStyles();
    return <CircularProgress className={classes.spinner} size={size} />;
};

interface StyleProps {
    loading: boolean;
    width?: number;
    height?: number;
}

const useStyles = makeStyles((_, { loading, width, height }: StyleProps) => ({
    loader: {
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        pointerEvents: "none",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    img: {
        opacity: loading ? 0 : 1, // needed for the spinner to be visible when src is set
    },
    wrapper: {
        position: "relative",
        aspectRatio: width && height && width / height,
        maxWidth: width
    }
}));

interface LoadingOptimizedImageProps extends OptimizedImageType {
    loader?: React.ReactElement;
}

export const LoadingOptimizedImage: React.FCWithChildren<LoadingOptimizedImageProps> = ({ src, loader, ...rest }) => {
    const [loading, setLoading] = useState(!!src);
    const classes = useStyles({ loading, width: rest.width, height: rest.height });
    const previousSrc = usePrevious(src);

    const onLoad = () => setLoading(false);
    const onError = () => setLoading(false);

    useLayoutEffect(() => {
        if (src && previousSrc != src) setLoading(true); // eslint-disable-line eqeqeq
    }, [previousSrc, src]);

    return (
        <div className={classes.wrapper}>
            <OptimizedImage src={src} onLoad={onLoad} onError={onError} {...rest} />

            {loading && <div className={classes.loader}>
                {loader || <Spinner size={rest.width || rest.height} />}
            </div>}
        </div>
    );
};
