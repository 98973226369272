import { SponsoredContent } from "../../../graphql/generated/types";
import { SponsorContent, SponsorsContainer } from "./SponsorContent";
import { Stack } from "@mui/material";

export type SponsorTagsProps = {
    sponsoredContents: SponsoredContent[];
    direction?: React.ComponentProps<typeof Stack>["direction"];
};

export const SponsorTags: React.FC<SponsorTagsProps> = ({ sponsoredContents, direction }) => (
    <SponsorsContainer direction={direction}>
        {sponsoredContents?.map((sponsoredContent) =>
            <SponsorContent
            key={sponsoredContent.id}
            text={sponsoredContent.sponsor.config.text}
            image={sponsoredContent.sponsor.config.image}
            />
        )}
    </SponsorsContainer>
);
