import { Accordion } from "../../../styleguide/patterns/expandable";
import { EventDivisionPointAllocationField } from "./EventDivisionPointAllocationField";
import { GroupDivisionParentSeriesDivisionMapping } from "./GroupDivisionParentSeriesDivisionMapping";
import { DivisionParentSeriesMapping } from "./DivisionParentSeriesMapping";

export const EventDivisionSeriesPointAllocationsSelectors = ({ eventDivisions, parentDivisions, eventDivisionPointAllocations, organisationId, federationSeries }) => {

    return (eventDivisions?.size > 0 || parentDivisions?.size > 0 || eventDivisionPointAllocations?.size > 0) &&
        <Accordion label="Rankings">
            {parentDivisions?.map((parentDivision, i) => {
                const parentSeries = federationSeries.find(series => `${series.get("id")}` === `${parentDivision.get("series_id")}`);
                return <DivisionParentSeriesMapping key={i}
                                                    index={i}
                                                    parentDivision={parentDivision}
                                                    parentSeries={parentSeries}
                                                    organisationId={organisationId} />;
            })}
            {eventDivisions?.size > 0 && <GroupDivisionParentSeriesDivisionMapping eventDivisions={eventDivisions} organisationId={organisationId} federationSeries={federationSeries}/>}
            {eventDivisionPointAllocations?.map((eventDivisionPointAllocation, i ) => {
                const series = federationSeries.find(series => `${series.get("id")}` === `${eventDivisionPointAllocation.get("season_id")}`);
                return <EventDivisionPointAllocationField index={i} key={i} eventDivisionPointAllocation={eventDivisionPointAllocation} series={series} />;
            })}
        </Accordion>;
};
