import dayjs from "dayjs";
import { Dialog, Typography } from "@mui/material";
import { SearchTakeoverDialog, SearchTakeoverDialogContent, } from "../forms/SearchTakeoverDialog";
import { T } from "components/util/t";
import { useCancellableLazyQuery } from "hooks/useCancellableLazyQuery";
import { useRef } from "react";
import { useVisualViewportHeight } from "hooks/useVisualViewportHeight";
import { gql } from "@apollo/client";
import { SimpleList } from "../lists/SimpleList";
import { SearchEventAndOrganisationResultsSkeleton } from "./SearchEventAndOrganisationResultsSkeleton";
import { useDialogState } from "hooks/useDialogState";
import { makeStyles } from "components/providers/makeStyles";
import { EVENT_STATUSES } from "../event/EVENT_STATUSES";

export const GET_EVENTS_BY_NAME = gql`
  query getEventsByName($search: String!, $limit: Int, $eventLive: Boolean) {
    eventsByName(search: $search, limit: $limit, eventLive: $eventLive) {
        id
        name
        status
        date
        daysWindow
        organisationId
    }
  }
`;

export const GET_ORGANISATIONS_BY_NAME = gql`
  query getOrganisationsByName($search: String!, $limit: Int) {
    organisationsByName(search: $search, limit: $limit) {
        id
        name
        shortName
    }
  }
`;

const useButtonStyles = makeStyles({
    button: {
        cursor: "pointer",
    }
});

export const SearchEventAndOrganisationButton = ({ children }) => {
    const classes = useButtonStyles();
    const [open, openDialog, closeDialog] = useDialogState();

    return (
        <>
            <div onClick={openDialog} role="button" className={classes.button}>{children}</div>
            <Dialog fullScreen open={open} onClose={closeDialog} aria-labelledby="event-organisation-search-dialog">
                <SearchEventAndOrganisationDialog closeDialog={closeDialog}/>
            </Dialog>
        </>
    );
};

const useInputStyles = makeStyles(theme => ({
    input: {
        "&&": {
            marginBottom: 0,
            border: 0,
            padding: theme.spacing(1, 1.5),
            opacity: 0.7
        }
    }
}));

export const SearchEventAndOrganisationInput = () => {
    const classes = useInputStyles();

    return (
        <SearchTakeoverDialog
            DialogComponent={SearchEventAndOrganisationDialog}
            inputPlaceholder="Search events and organisations"
            dialogTitleId="event-organisation-search-dialog"
            className={classes.input}
            showSearchIcon={false}
        />
    );
};

export const SearchEventAndOrganisationDialog = ({ closeDialog }) => {
    const searchRef = useRef();
    const [eventLiveLazyQuery, { data: eventLiveData, loading: eventLiveLoading, variables: { search: eventLiveSearch } }] = useCancellableLazyQuery(GET_EVENTS_BY_NAME, { variables: { search: "", limit: 100, eventLive: true }, fetchPolicy: "cache-and-network" }, true),
        eventsLive = eventLiveData && eventLiveData.eventsByName;

    const [organisationLazyQuery, { data: orgData, loading: orgLoading, variables: { search: orgSearch } }] = useCancellableLazyQuery(GET_ORGANISATIONS_BY_NAME, { variables: { search: "", limit: 100 }, fetchPolicy: "cache-and-network" }, true),
        organisations = orgData && orgData.organisationsByName;

    const [eventLazyQuery, { data, loading: eventLoading, variables: { search: eventSearch } }] = useCancellableLazyQuery(GET_EVENTS_BY_NAME, { variables: { search: "", limit: 100, eventLive: false }, fetchPolicy: "cache-and-network" }, true),
        events = data && data.eventsByName;

    const loading = eventLoading || orgLoading || eventLiveLoading;

    const containerHeight = useVisualViewportHeight();
    const onSearchEventsLive = search => eventLiveLazyQuery({ variables: { search } });
    const onSearchEvents = search => eventLazyQuery({ variables: { search } });
    const onSearchOrganisations = search => organisationLazyQuery({ variables: { search } });

    const onSearch = search => {
        onSearchEventsLive(search);
        onSearchOrganisations(search);
        onSearchEvents(search);
    };

    const eventsLiveLength = eventsLive?.length || 0;
    let eventsLimit = 0;
    if (eventsLiveLength < 100) {
        const eventsLength = events?.length || 0;
        if (eventsLength)
            eventsLimit = 100 - eventsLiveLength;
    }

    const eventsLiveFound = eventsLive?.length ? eventsLive.map((e) => ({ title: e.name, to: `/events/${e.id}`, description: `${dayjs(e.date).format("D MMM YYYY")} | ${EVENT_STATUSES[e.status]}` })) : [];
    const organisationsFound = organisations?.length ? organisations.map((o) => ({ title: `${o.name}`, description: "Organisation", to: `/${o.shortName}` })) : [];
    const eventsFound = events?.length
        ? eventsLimit !== 0 ?
            events.slice(0, eventsLimit).map((e) => ({ title: e.name, to: `/events/${e.id}`, description: `${dayjs(e.date).format("D MMM YYYY")} | ${EVENT_STATUSES[e.status]}` }))
            : []
        : [];

    const eventsAndOrgsFound = [
        ...eventsLiveFound,
        ...organisationsFound,
        ...eventsFound];

    return (
        <div style={{ maxHeight: containerHeight }}>
            <SearchTakeoverDialogContent
                dialogTitleId="event-organisation-search-dialog"
                searchRef={searchRef}
                placeholder="Search events and organisations"
                onSearch={onSearch}
                loading={loading}
                closeDialog={closeDialog}
            >
                {(!eventSearch.length && !orgSearch.length && !eventLiveSearch.length) ?
                    null :
                    (loading) ?
                        <SearchEventAndOrganisationResultsSkeleton /> :
                        (!!events?.length || !!organisations?.length || !!eventsLive?.length)
                            ?
                            <>
                                <SimpleList>
                                    {eventsAndOrgsFound}
                                </SimpleList>
                                <Typography mt={3} mb={6}>
                                    <T>browse_all_organisations</T>
                                </Typography>
                            </>
                            :
                            <T search={orgSearch}>no_search_results_found</T>
                }
            </SearchTakeoverDialogContent>
        </div>
    );
};
