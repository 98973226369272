import { Button, Stack, Typography } from "@mui/material";
import image from "./emptyStateImage-sponsors.png";
import { T } from "../../../../util/t";

export const Sponsors = () => (
    <Stack alignItems="center" marginX="auto" textAlign="center" paddingX={4}>
        <Typography maxWidth={450}>
            <img src={image} alt="sponsors" width="100%"/>
        </Typography>
        <Typography variant="label1" marginTop={4} marginBottom={2}><T>Unlock premium sponsor exposure and revenue</T></Typography>
        <Typography variant="body1"><T>Give your sponsors consistent, high-impact visibility across your event, from scheduling to final results.</T></Typography>
        <Typography variant="body1" marginBottom={4}><T>Offer event-wide sponsorships or targeted division-specific placements.</T></Typography>
        <Typography variant="body1"><T>Ready to boost your sponsorship revenue?</T></Typography>
        <Typography variant="body1" marginBottom={4}><T>activate_or_learn_more</T></Typography>
        <Button variant="contained" component="a" href="https://buy.stripe.com/4gw17S3C6fyo4004gg" target="_blank"><T>Activate for this event</T></Button>
    </Stack>
);
