import reactLazyWithRetry from "@fatso83/retry-dynamic-import/react-lazy";
import { Suspense } from "react";
const ReactSpreadsheetImport = reactLazyWithRetry(() =>
    import("react-spreadsheet-import").then(({ ReactSpreadsheetImport }) => ({ default: ReactSpreadsheetImport }))
);

export const SpreadsheetImporter = props =>
    <Suspense fallback={null}>
        <ReactSpreadsheetImport {...props}/>
    </Suspense>;
